import api, { query } from '@/helpers/api';

export async function filterEmpty (dimensions, qi = {}) {
    const counts = await Promise.all(dimensions.map(async dimension => {
        const q = Object.assign({}, qi);
        q.dimensions = dimension._id;
        return (await api.templates.getCount(query(q))).data;
    }));

    return dimensions.filter((dimension, index) => {
        return counts[index] > 0;
    });
}
