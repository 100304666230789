<template>
    <div class='tile'>
        <router-link class="overlay" :to="`/template/${template._id}/edit`">
            <span>Select</span>
        </router-link>
        <img :src="template.thumbUrl">
        <div class="icon-container" :class="favorited ? 'favorite' : ''">
            <button class="smallBtn" @click="checkFavorite">
                <inline-svg :src="require('@/assets/img/Favorite.svg')"/>
            </button>
        </div>
        <span v-if="template.product && template.product.price && userRole !== 'proUser'" class="price">
            Pro
        </span>     
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'CatalogTile',
    props: [ 'template', 'favorites' ],
    components: {
        InlineSvg,
    },
    
    data () {
        return {
            favorited: false,
        };
    },
    mounted () {
        this.favorited = this.isFavorite();
    },
    methods: {
        isFavorite(){
            if(this.favorites.includes(this.template._id)){
                return true;
            }
            return false;
        },
        async checkFavorite(){
            this.favorited = !this.favorited;
            this.favorited ? 
                await this.$emit('add-favorite', this.template._id) : 
                await this.$emit('remove-favorite', this.template._id);
        },
    },
    watch: {
        async favorites(){
            this.favorited = this.isFavorite();
            await this.$nextTick();
        },
    },
};

</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/colors';

.tile {
    display: flex;
    position: relative;
    margin: 0 0 16px 16px;
    cursor: pointer;
    box-sizing:border-box;

    @include max-width($width-large){
        left:-8px;
        margin-bottom: 16px;
        box-sizing:border-box;
    }
    @include max-width($width-small){
        margin: 0 auto;
        margin-bottom: 16px;
        max-width: 80%;
    }
    img {
        width: 100%;
        height: 100%;
    }

    &:hover{
        .icon-container{
            opacity: 1;

            @include max-width($width-small){
                opacity:0;
            }
        }
        .icon-container{
            @include max-width($width-small){
                opacity:1;
            }
        }
        .overlay{
            background:rgba(255, 255, 255, 0.65);

            span {
                opacity:1;
            }
        }
    }

    .icon-container {
        position:absolute;
        top: 10px;
        right: 10px;
        display:flex;
        justify-content: center;
        align-items:center;
        background:$color-gray-light;
        padding:5px;
        box-sizing: border-box;
        border-radius:50%;
        width:30px;
        height:30px;
        cursor:pointer;
        opacity:0;
        transition: 0.25s ease-in-out;
        z-index:3;

        button{
            background:unset;
            width:unset;
            border:unset;
            padding:unset;
            margin:unset;
        }
        @include max-width($width-small){
            opacity:1;
        }
        .smallBtn{
            display:flex;
            align-items:center;
            justify-content: center;
            height:100%;
        }
        &.favorite{
            opacity:1;
            background:$color-gray-light;
        }
        
    }

    .overlay {
        display:flex;
        justify-content: center;
        align-items:center;
        position: absolute;
        top: 0;
        left: 0;
        height:100%;
        width:100%;
        transition: 0.2s ease-in-out;
        background:rgba(255, 255, 255, 0);
        text-decoration: none;

        span {
            font-size: 2rem;
            font-weight:bold;
            color: black;
            opacity:0;
            transition: 0.2s ease-in-out;
    
        }

    }

    .price {
        position: absolute;
        top: 0;
        left: 8px;
        margin-top: -8px;
        padding: 6px 8px;
        color: white;
        background: $color-red-light;
        border-radius: 8px;
    }
}
</style>

<style lang="scss">
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.tile .icon-container {
    svg {
        height: 100%;
        width: 100%;

        path {
            fill: unset;
        }
    }

    .smallBtn svg path{
        stroke: var(--link-color-active);
        fill: $color-gray-light;
        transition:0.25s ease-in-out;
    }

    &.favorite{
        .smallBtn svg path {
            stroke: var(--link-color-active);
            fill: var(--link-color-active);
        }
    }
}
</style>

