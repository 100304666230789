import { find } from 'lodash';

import { downloadCss, parseFont } from '@/helpers/fonts';
import { generateId } from '@/helpers/utils';
import text from '@/mixins/text';

const serializer = new XMLSerializer();

export function exportSvg ($svg) {
    $svg.setAttribute('width', $svg.viewBox.baseVal.width);
    $svg.setAttribute('height', $svg.viewBox.baseVal.height);
    const string = serializer.serializeToString($svg);
    $svg.removeAttribute('width');
    $svg.removeAttribute('height');
    return string;
}

export function blobifySvgString (string) {
    const parts = [ string ];
    return new Blob(parts, { type: 'image/svg+xml' });
}

function fixColors($el){
    if($el.hasChildNodes()){
        for(let child of $el.children){
            fixColors(child);
        }
    }
    if($el.hasAttribute('style')){
        //clean up string
        const styles = $el.getAttribute('style').trim().split(';').filter(e => e.length != 0);
        //replacement style after removing color attributes
        const replacement = [];
        for(let style of styles){
            style = style.split(':');
            const property = style[0].trim();
            const value = style[1].trim();
            if(property == 'fill'){
                $el.setAttribute('fill', value);
            }else if(property =='stroke'){
                $el.setAttribute('stroke', value);
            }else{
                replacement.push(style.join(':'));
            }
        }
        if(replacement.length > 0){
            $el.setAttribute('style', replacement.join(';'));
        }else{
            $el.removeAttribute('style');
        }
    }
}

function fixClipPath($svg){
    for(let clipPath of $svg.querySelectorAll('clipPath')){
        if(!clipPath.getAttribute('data-generated-id')){
            let $clip = clipPath.querySelector('use');
            if($clip){
                const newId = generateId(50, 'SVGID_') + '_';
                if($clip.tagName == 'use'){
                    const $use = $clip.getAttribute('xlink:href');
                    let $rect = clipPath.parentNode.querySelectorAll('defs')[0].querySelector($use);
                    $clip.setAttribute('xlink:href', '#' + newId);
                    $rect.id = newId;
                }
                clipPath.dataset.generatedId = true;
            }
        }
    }
}

export async function prepareSvg ($template, onNonFatalError) {
    // Remove nodes that are not svg
    for (const $child of Array.from($template.childNodes)) {
        if ($child.tagName !== 'svg') {
            $template.removeChild($child);
        }
    }

    const $svg = $template.querySelector('svg');

    const { width, height } = $svg.viewBox.baseVal;

    // Remove width/height - these break rendering - will be added on export
    $svg.removeAttribute('width');
    $svg.removeAttribute('height');

    //Fix colors
    fixColors($svg);

    //Fix clip paths
    fixClipPath($svg);
    
    //Fix multiply-opacity-layers
    for (const child of $svg.querySelectorAll('[mix-blend-mode]')) {
        //Mix blend needs to be a style so we need find it
        if (child.hasAttribute('mix-blend-mode')) {
            child.style.mixBlendMode = child.getAttribute('mix-blend-mode');
            child.removeAttribute('mix-blend-mode');
        }
    }


    for (const $text of $svg.querySelectorAll('text')) {
        // If first child has special font attributes, pull them up to parent
        const $tspan = $text.children[0];
        if ($tspan) {
            const family = $tspan.getAttribute('font-family');
            family && $text.setAttribute('font-family', family);

            const weight = $tspan.getAttribute('font-weight');
            weight && $text.setAttribute('font-weight', weight);

            const style = $tspan.getAttribute('font-style');
            style && $text.setAttribute('font-style', style);

            const fill = $tspan.getAttribute('fill');
            fill && $text.setAttribute('fill', fill);

            const stroke = $tspan.getAttribute('stroke');
            stroke && $text.setAttribute('stroke', stroke);
        }

        //before proceeding make sure this is an element we want to modify
        badTextChecker($text);
        
        // Apply non scaling transforms as x/y values
        if ($text.hasAttribute('transform') && !$text.hasAttribute('data-uneditable')) {

            // Make sure we don't do this multiple times
            if ($text.hasAttribute('data-applied-transforms')) {
                return;
            } else {
                $text.setAttribute('data-applied-transforms', 'v1');
            }

            if ($text.hasAttribute('data-re-fix-center-transform')) {

                if ($text.hasAttribute('data-re-y')) {
                    const reY = Number($text.getAttribute('data-re-y'));
                    const remF = Number($text.getAttribute('data-re-matrix-f'));
                    $text.setAttribute('y', reY - remF);
                    $text.removeAttribute('data-re-y');
                }
                
                $text.removeAttribute('data-re-fix-center-transform');
                $text.removeAttribute('data-re-x');
                $text.removeAttribute('data-re-matrix-e');
                $text.removeAttribute('data-re-matrix-f');
            }
            
            let x = 0;
            let y = 0;

            if ($text.children.length == 0) {
                // Childless text elements' x/y values do have an effect
                x = Number($text.getAttribute('x')) || 0;
                y = Number($text.getAttribute('y')) || 0;
            }
            const { a, b, c, d, e, f } = $text.transform.baseVal[0].matrix;
            x += e / a;
            y += f / d;
            
            if (a == 1 && b == 0 && c == 0 && d == 1) {
                $text.removeAttribute('transform');
            } else {
                $text.setAttribute('transform', `matrix(${a} ${b} ${c} ${d} 0 0)`);
            }

            $text.setAttribute('x', x);
            $text.setAttribute('y', y);

            // Multiline, need to also update the children
            if ($text.tagName == 'text' && $text.children.length > 0) {
                let lastY = null;

                // We need to normalize parent x to be the smallest child x
                let smallestX = null;

                for (const $tspan of $text.children) {
                    const tx = Number($tspan.getAttribute('x')) || 0;
                    const ty = Number($tspan.getAttribute('y')) || 0;

                    let newX = x + tx;

                    if ($tspan.getAttribute('x') == '50%') {
                        newX = width / 2;
                    }

                    if (smallestX == null) {
                        smallestX = newX;
                    } else if (newX < smallestX) {
                        smallestX = newX;
                    }

                    $tspan.setAttribute('x', newX);
    
                    $tspan.setAttribute('dy', ty - lastY);
                    $tspan.removeAttribute('y');

                    lastY = ty;
                }

                if (smallestX !== null) {
                    $text.setAttribute('x', smallestX);
                }
            }

            // Another fix centering special edge case, where there's one dummy child tspan set up for centering
            if (
                $text.getAttribute('text-anchor') == 'middle'
                && $text.children.length == 1
                && $text.children[0]
                && $text.children[0].hasAttribute('data-original-x')
            ) {
                // We can just promote the contents of the tspan
                $text.innerHTML = $text.children[0].textContent;

                // And then we push the x by width/2
                $text.setAttribute('x', x + ($text.getBBox().width / 2));
            }
        }
    }
    
    // Fix textPaths
    const $textPaths = $svg.querySelectorAll('textPath');
    for (const $textPath of $textPaths) {
        if ($textPath.hasAttribute('startOffset')) {
            $textPath.setAttribute('startOffset', 0);
        }
    }    
    const $defs = $svg.querySelector('defs') || (() => {
        const $defs = document.createElement('defs');
        $svg.appendChild($defs);
        return $defs;
    })();
    const $fontsStyle = $defs.querySelector('.parsed-fonts');

    if ($fontsStyle) {
        return;
    }

    // Correct font attribues
    const $texts = $svg.querySelectorAll('text,tspan');

    const fontsToLoad = [];

    for (const $text of $texts) {
        if ($text.hasAttribute('font-family')) {

            try {
                const font = await parseFont($text);

                if (!find(fontsToLoad, font)) {
                    fontsToLoad.push(font);
                }
            } catch (e) {
                onNonFatalError && onNonFatalError(e);
            }
        }
    }

    const $style = document.createElement('style');
    $style.setAttribute('class', 'parsed-fonts');
    $style.setAttribute('type', 'text/css');

    const rules = [];
    for (const font of fontsToLoad) {
        const css = await horribleHorribleGoogleFontsWorkaround(font);
        rules.push(...css);
    }
    
    $style.innerHTML = rules.join('\n');
    $defs.appendChild($style);
}

//Check for bad editables
function badTextChecker($text) {

    //Is this element rotated, aka vertical text
    if ($text.hasAttribute('transform')) {
        let result = true;
        const { a, b, c, d, e, f } = $text.transform.baseVal[0].matrix; 
        if (a < 1) {
            result = false;
        }
        if ($text.getAttribute('transform').includes('rotate')){
            result = false;
        }
        if (!result) {
            $text.dataset.uneditable = true;
            preventClickableChildren($text);
            return;
        }
    }

    //scan text nodes for 'weird' text
    if (!plainTextChecker($text)) {
        $text.dataset.uneditable = true;
        preventClickableChildren($text);
        return;
    }
}

function preventClickableChildren($text){
    for (let child of $text.children) {
        child.dataset.uneditable = true;
        if(child.children){
            preventClickableChildren(child);
        }
    }
}

function plainTextChecker($text){
    //If any other elements exist in addition to plaintext then this is a bad text element;
    let plainText = false;
    for (let child of $text.childNodes){

        //If is textpath (circular etc) shouldnt edit at this moment
        if (child.nodeName == 'textPath') {
            return false;
        }

        const trimmed = String(child.data).trim();
        if (trimmed != '') {
            if (child.nodeType == child.TEXT_NODE && child.parentNode) {
                plainText = true;
            }
            if (child.nodeType != child.TEXT_NODE && plainText) {
                return false;
            }
        }
    }
    return true;
}

// Thanks to https://stackoverflow.com/a/42405731
async function horribleHorribleGoogleFontsWorkaround (font) {
    const text = await downloadCss(font);
    
    const $style = document.createElement('style');
    $style.innerHTML = text;
    document.head.appendChild($style);
    const styleSheet = $style.sheet;

    const ruleify = (rule) => {
        const src = rule.style.getPropertyValue('src') || rule.style.cssText.match(/url\(.*?\)/g)[0];
        if (!src) return null;

        const url = src.split('url(')[1].split(')')[0];

        return {
            rule: rule,
            src: src,
            url: url.replace(/"/g, ''),
        };
    };

    const fontRules = [];
    const fontPromises = [];

    for (let i = 0; i < styleSheet.cssRules.length; i++) {
        const rule = styleSheet.cssRules[i];

        const fontRule = ruleify(rule);
        if (!fontRule) {
            continue;
        }

        fontRules.push(fontRule);

        fontPromises.push((async () => {
            const blob = await (await fetch(fontRule.url)).blob();

            const dataUrl = await new Promise(resolve => {
                const fr = new FileReader();
                fr.onload = e => resolve(fr.result);
                fr.readAsDataURL(blob);
            });

            return fontRule.rule.cssText.replace(fontRule.url, dataUrl);
        })());
    }

    document.head.removeChild($style);

    return await Promise.all(fontPromises);
}
