<template>
    <div class="template-create-page">

        <tool-nav class="tool-nav">
            <tool-button icon="download" @click.native="exportRet">Export</tool-button>
            <tool-button icon="templates" @click.native="inspecting = true">Inspect</tool-button>
            <tool-button v-if="id" icon="delete" @click.native="onDelete">
                {{ deleting ? 'Deleting...' : 'Delete' }}
            </tool-button>
            <tool-button icon="save" @click.native="onSave">{{ saving ? 'Saving...' : 'Save' }}</tool-button>
        </tool-nav>
        <div class="template-edit">
            <sidebar class="sidebar">
                <h3>Media</h3>
                <radio-picker :options="[ 'social', 'print' ]" :selected="media" @select="media = $event" />

                <h3>Category</h3>
                <category-selector 
                    :selected-categories="categories"
                    @select-category="onSelectCategory" 
                />
                
                <h3>Dimension</h3>
                <dimension-selector 
                    media="media" 
                    :selected-dimensions="selectedDimensions"
                    @select-dimension="onSelectDimension" @change-media="media = $event" 
                />

                <h3>Tags </h3>
                <div><p>Tags enable search. When the user searches, we will look for those search terms in the list of tags.</p></div>
                <tag-creator
                    :keywords="keywords"
                    @add-key="addKey"
                    @remove-key="removeKey"
                />

                <h3>Price</h3>
                <radio-picker 
                    :options="[ 'free', 'premium' ]" 
                    :selected="pricing" 
                    @select="pricing = $event" 
                    design="traditional" 
                />
                <template v-if="pricing == 'premium'"><span>$ </span><input v-if="pricing == 'premium'" v-model="priceString"></template>
                <div v-if="pricingError"><p class="error">Please make sure your price is in this exact format: 5.99</p></div>

                <h3>Editable</h3>
                <editable-field
                    v-for="(e, i) in activeSlide.editable"
                    :key="i"
                    :element-id="e.id"
                    :index="i"
                    :active="activeEditableIndex == i"
                    :type="e.type"
                    :value="e.label"
                    :fillOptions="e.fillOptions"
                    :strokeOptions="e.strokeOptions"
                    :initialOpacity="getOpacity(e.id)"
                    :flags="e.flags"
                    :fillColor="getColor(e.id, e.type, 'fill')"
                    :strokeColor="getColor(e.id, e.type, 'stroke')"
                    :align="e.align"
                    :limit="e.limit"
                    :saving="saving"
                    @delete="onRemove(e.id)"
                    @updateFill="updateColors(e, i, arguments[0], 'fillOptions')"
                    @updateStroke="updateColors(e, i, arguments[0], 'strokeOptions')"
                    @input="updateLabel(e, i, arguments[0])"
                    @flag-add="addFlag(e, arguments[0])"
                    @flag-remove="removeFlag(e, arguments[0])"
                    @set-color="setColor(e, $event)"
                    @input-limit="$set(e, 'limit', $event)"
                    @update-opacity="updateOpacity(e.id, arguments[0])"
                    @switch-type="switchType(i, e.id, $event)"
                    @set-align="setAlign(e, $event)"
                    @force-align="forceAlign(e)"
                    @click.native="selectEditable(e, i)"
                />
            </sidebar>

            <div class="template-editor">
                <dropload v-if="!ready" @drop="onFile" :accept="['image/svg+xml', '.ret']"></dropload>
                <input v-if="ready" class="template-name" v-model="name" />
                <div :class="!ready ? 'disabled' : ''" class="template" ref="template" @mouseover="onMouseover"></div>
                <template-carousel 
                    :activeSlideIndex="activeSlideIndex"
                    :svgContainer="svgContainer" 
                    :windowWidth="windowWidth"
                    @addSlide="addSlide" 
                    @setActive="setActive" 
                >   
                    <div v-if="svgContainer.length > 0" class="add-container">
                        <button  @click="addSlide" class="control-add control img-button control-next">
                            <inline-svg :src="require('@/assets/img/plus.svg')"/>
                        </button>
                    </div>
                </template-carousel>

                <inspector
                    ref="inspector"
                    v-if="inspecting"
                    @close="inspecting=false"
                    :$svg="activeSvg"
                    @highlight="inspectorHighlight"
                    @unhighlight="inspectorUnhighlight"
                    @add="inspectorAdd"
                    @delete="inspectorDelete"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js';
import InlineSvg from 'vue-inline-svg';

import CategorySelector from './CategorySelector.vue';
import DimensionSelector from './DimensionSelector.vue';
import EditableField from './EditableField.vue';
import Inspector from './Inspector.vue';
import TagCreator from './TagCreator.vue';

import Dropload from '@/components/Dropload.vue';
import RadioPicker from '@/components/RadioPicker.vue';
import Sidebar from '@/components/Sidebar.vue';
import TemplateCarousel from '@/components/TemplateCarousel.vue';
import ToolButton from '@/components/ToolButton.vue';
import ToolNav from '@/components/ToolNav.vue';
import api, { form } from '@/helpers/api';
import render from '@/helpers/render';
import { prepareSvg } from '@/helpers/svg-tools';
import TextBoundary from '@/helpers/text-boundary';
import { arrayAsKeys, generateId, hexToRgba, rgbToHex, rgbToRgba } from '@/helpers/utils';
import imageMixin from '@/mixins/image';
import textMixin from '@/mixins/text';

export default {
    name: 'TemplateCreatePage',

    mixins: [ imageMixin, textMixin ],

    props: [ 'id' ],

    components: {
        CategorySelector,
        DimensionSelector,
        Dropload,
        EditableField,
        InlineSvg,
        Inspector,
        RadioPicker,
        Sidebar,
        TagCreator,
        TemplateCarousel,
        ToolButton,
        ToolNav,
    },

    computed:{
        activeEditable(){
            return this.activeSlide.editable[this.activeEditableIndex];
        },
        activeTemplate () {
            return this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
        },
        activeSlide(){
            return this.slides[this.activeSlideIndex];
        },
        activeSvg(){
            return this.svgContainer[this.activeSlideIndex];
        },
        isMobile(){
            if(this.windowWidth < this.$breakPoints['width-small']){
                return true;
            }
            return false;
        },
        
        selectedDimensions () {
            const obj = {};
            obj[this.dimension] = true;
            return obj;
        },

        pricingError () {
            return this.pricing == 'premium' && !this.priceString.match(/^\d+\.\d\d$/);
        },
    },

    data() {
        return {
            name: 'New Template',
            ready: false,
            inspecting: false,
            media: 'social',
            categories: {},
            dimension: null,
            keywords: [],
            pricing: 'free',
            priceString: '',
            slides: [ {editable: [], url: ''} ],
            svgContainer:[],
            activeEditableIndex: -1,
            saving: false,
            deleting: false,
            activeSlideIndex: 0,
            windowWidth: window.innerWidth,

            boundaries: {},
            activeBoundary: null,
        };
    },

    async mounted () {
        if (this.id) {
            this.ready = true;
            const template = (await api.templates.getById(this.id)).data;
            for(let i = 0; i < template.slides.length ; i++){
                let svgResponse = await fetch(template.slides[i].url + '?templateVersion2=' + template.version);
                let svgText = await svgResponse.text();
                const placeholder = this.insertSvgText(svgText);
                this.updateSvg(this.activeSvg);
                this.svgContainer.push(placeholder.querySelector('svg'));
            }
            //Update slides after to avoid editable trying to grab color before template exists
            this.slides = template.slides;
            this.prepareEditables();
            this.fixFlags(this.slides, true);
            this.name = template.name;
            this.media = template.media;
            this.categories = arrayAsKeys(template.categories, true);
            this.dimension = template.dimension;
            this.keywords = template.tags;
            this.pricing = template.premium ? 'premium' : 'free';
            if (template.product && template.product.price) {
                const price = template.product.price;
                const dollar = Math.floor(price / 100);
                const decimal = (price % 100).toString().padStart(2, '0');
                this.priceString = `${dollar}.${decimal}`;
            }
            this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.remove('disabled');
        }
    },

    beforeDestroy () {
        this.activeBoundary && this.activeBoundary.removeBox();
    },

    methods: {

        async selectEditable (e, i) {
            if (i == null || i == undefined) {
                i = this.activeSlide.editable.findIndex(e_ => e_.id == e.id);
            }

            if (this.activeEditableIndex == i) { return; }
            this.activeEditableIndex = i;

            if (e == null || e == undefined) {
                e = this.activeSlide.editable[i];
            }

            this.activeBoundary && this.activeBoundary.removeBox();

            await this.$nextTick();

            // Scroll to the editable in the sidebar if needed
            const $editableField = document.getElementById('editable-field-' + e.id);
            $editableField && $editableField.scrollIntoView({ behavior: 'smooth' });

            if (e.type == 'text' || e.type == 'multiline') {
                if (!this.boundaries[e.id]) {
                    const $el = this.activeTemplate.querySelector(`[id='${e.id}']`);
                    this.boundaries[e.id] = new TextBoundary($el, e, this.activeTemplate.querySelector('svg'));
                }

                this.activeBoundary = this.boundaries[e.id];
                this.activeBoundary.addBox();
            }
        },

        async exportRet () {
            const ret = {
                slides: this.slides,
                name: this.name,
                media: this.media,
                keywords: this.keywords,
                svgs: [],
            };

            for (const $el of this.svgContainer) {
                const { string } = await this.exportSvg($el);
                ret.svgs.push(string);
            }

            const a = document.createElement('a');
            a.href = URL.createObjectURL(new Blob([ JSON.stringify(ret) ], { type: 'application/json' }) );
            a.download = this.name + '.ret';
            a.click();

            URL.revokeObjectURL(a.href);
        },

        import (data) {
            this.ready = true;

            for (const svg of data.svgs) {
                const placeholder = this.insertSvgText(svg);
                this.updateSvg(this.activeSvg);
                this.svgContainer.push(placeholder.querySelector('svg'));
            }

            this.slides = data.slides.map(slide => {
                const newSlide = Object.assign({}, slide);
                delete newSlide._id;
                delete newSlide.url;

                newSlide.editable = slide.editable.map(editable => {
                    const newEditable = Object.assign({}, editable);
                    delete newEditable._id;
                    return newEditable;
                });

                return newSlide;
            });
            this.prepareEditables();
            this.fixFlags(this.slides, true);

            this.slides = data.slides;
            this.name = data.name;
            this.media = data.media;
            this.keywords = data.keywords;

            this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.remove('disabled');
        },

        addKey(tags){
            this.keywords = this.keywords.concat(tags);
        },

        removeKey(tag){
            let index = this.keywords.indexOf(tag);
            this.keywords.splice(index, 1);
        },

        getColor(id, $elType, type){
            if($elType !== 'image'){
                const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
                const $el = $template.querySelector(`[id='${id}']`);
                const styles = window.getComputedStyle($el);
                if(!styles[type].includes('rgba') && styles[type] != 'none'){
                    const rgb = styles[type].split('(')[1].split(')')[0].split(',');
                    const rgba = `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, 1)`;
                    return rgba;
                }
                if(styles[type] == 'none'){
                    return 'rgba(0, 0, 0, 0)';
                }
                return styles[type];
            }
        },
        setColor (editable, $event) {
            if(editable.type !== 'image'){
                const $el = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].querySelector(`[id='${editable.id}']`);
                this.fixColor($el);
                if($event.type == 'fill'){
                    $el.setAttribute('fill', $event.color); 
                    editable.fillColor = $event.color;
                }else if($event.type == 'stroke'){
                    $el.setAttribute('stroke', $event.color);
                    editable.strokeColor = $event.color;
                }
                this.$set(this.svgContainer, this.activeSlideIndex, this.$refs.template.querySelectorAll('svg')[this.activeSlideIndex]);
            }
        },

        setAlign (editable, align) {
            this.$set(editable, 'align', align);

            const $el = document.getElementById(editable.id);
            $el.setAttribute('data-text-anchor', align);

            this.$toast('New align setting will only affect user-generated content. Use the "Force Align" button to '
                + 'force the alignment now (use only if necessary, can cause issues with some templates.)');
        },

        forceAlign (editable) {
            this.boundaries[editable.id].forceAlign();
        },

        onFile (file) {
            this.ready = true;

            const fr = new FileReader();
            fr.readAsText(file);

            fr.onload = () => {
                if (file.name.endsWith('.ret')) {
                    this.import(JSON.parse(fr.result));
                } else {
                    this.onFileLoad(fr.result);
                }
            };
        },

        insertSvgText (text) {
            const placeholder = document.createElement('div');
            placeholder.innerHTML = text;
            placeholder.classList.add('svg-container');
            placeholder.classList.add('disabled');
            this.$refs.template.appendChild(placeholder);
            return placeholder;
        },

        async onFileLoad (text) {
            // Create placeholder to add the element as a real html object
            const placeholder = this.insertSvgText(text);
            //If somehow svg has data-editables
            this.cleanSvgEditables(placeholder);
            this.updateSvg(this.activeSvg);
            this.svgContainer.push(placeholder.querySelector('svg'));
            //needs to be fixed
            this.activeSvg.querySelectorAll('*').forEach($node => {
                if ($node.id.startsWith('text_') || $node.id.startsWith('image_')) {
                    this.addEditable($node);
                }
            });
            //svg ready to show
            this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.remove('disabled');
        },

        addSlide(){
            this.ready = false;
            this.removeClickable(this.activeSvg);
            this.resetColor();
            this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.add('disabled');
            this.slides.push({editable:[], url:''});
            this.activeSlideIndex = this.slides.length - 1;
        },

        setActive(index){
            this.removeClickable(this.activeSvg);
            this.resetColor();
            this.activeBoundary && this.activeBoundary.removeBox();
            this.activeBoundary = null;

            if(this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex]){
                this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.add('disabled');
            }
            this.activeSlideIndex = index;
            this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.remove('disabled');
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            // for(let el in this.slides[this.activeSlideIndex].editable){
            //     this.addEditable(el);
            // }
            this.updateSvg();
        },
        resetColor(){
            for(let element of this.activeSlide.editable){
                if(element.type !== 'image'){
                    if(element.fillOptions.length > 0){
                        this.setColor(element, {color: element.fillOptions[0], type: 'fill'});
                    }else if(element.strokeOptions.length > 0){
                        this.setColor(element, {color: element.strokeOptions[0], type: 'stroke'});
                    }
                }
            }
        },

        async updateSvg () {
            //If this.ready is false, dropload was active but abandoned. Remove's the new empty slide
            if(!this.ready){
                this.slides = this.slides.splice(this.slides, this.slides.length - 1);
            }

            try {
                const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
                await prepareSvg($template, e => this.$toast.error(e.message));

            } catch (e) {
                console.error(e);
                this.$toast.error('There is an issue with your SVG file. Please make sure you are following export guidelines and contact a developer for further support: ' + e.message, { timeout: false });
            }

            this.ready = true;
        },

        onSelectCategory (id) {
            if (this.categories[id]) {
                this.$delete(this.categories, id);
            } else {
                this.$set(this.categories, id, true);
            }
        },

        onSelectDimension (id) {
            if (this.dimension == id) {
                this.dimension = null;
            } else {
                this.dimension = id;
            }
        },

        onMouseover (e) {
            if (
                e.target !== this.$refs.template
                && e.target !== e.currentTarget
                && e.target.tagName.toLowerCase() != 'svg'
                && e.target.tagName.toLowerCase() != 'div'
                && !e.target.hasAttribute('data-creator')
            ) {
                e.target.classList.add('hover');
                e.target.addEventListener('mouseout', this.onMouseout);
                if (!e.target.hasAttribute('data-clickable')) {
                    e.target.addEventListener('click', this.onElementClicked);
                    e.target.setAttribute('data-clickable', 'true');
                }
            }
        },

        inspectorHighlight (node) {
            node.$el.classList.add('hover');
        },

        inspectorUnhighlight (node) {
            node.$el.classList.remove('hover');
        },

        inspectorAdd (node) {
            if (node.$el.hasAttribute('data-editable')) {
                this.onRemove(node.id);
            } else {
                this.addEditable(node.$el);
            }
            this.$set(this.svgContainer, this.activeSlideIndex, this.$refs.template.querySelectorAll('svg')[this.activeSlideIndex]);
        },

        inspectorDelete (node){
            node.$el.remove();
            if(this.$refs.template.querySelectorAll('svg')[this.activeSlideIndex] !== undefined){
                ('wahoo');
                this.$set(this.svgContainer, this.activeSlideIndex, this.$refs.template.querySelectorAll('svg')[this.activeSlideIndex]);
            }else{
                this.svgContainer.splice(this.activeSlideIndex, 1);
            }
        },

        async onElementClicked (e) {
            e.preventDefault();
            if (e.target.hasAttribute('data-editable')) {
                const index = Number(e.target.getAttribute('data-editable')) - 1;
                this.selectEditable(null, index);
            } else {
                const index = await this.addEditable(e.target);
                if (index !== undefined) {
                    this.selectEditable(null, index);
                }
            }
        },

        async addEditable ($el) {
            if($el.hasAttribute('data-uneditable') && $el.getAttribute('data-uneditable')){
                this.$toast.warning('This element is currently unsupported', {timeout: 5000});
                return;
            }
            // Use helper methods to determine things automatically from the element
            const type = this.determineType($el);
            //Multiline, select parent if tspan
            if (type == 'multiline') {
                if ( $el.nodeName == 'tspan') {
                    $el = $el.parentNode;
                    if ($el.hasAttribute('data-editable')) {
                        return Number($el.getAttribute('data-editable')) - 1;
                    }
                }
            }
            const label = this.guessLabel($el);
            const flags = this.determineFlags(type, $el);
            const fillOptions = this.determineOptions(type, 'fill', $el);
            const strokeOptions = this.determineOptions(type, 'stroke', $el);
            const limit = this.determineLimit(type, $el);
            const original = $el.cloneNode();
            // Generate an id for the element if it doesn't have one
            $el.id = generateId(8);
            if ($el.parentNode.nodeName == 'clipPath') {
                //generate id for clip path
                let clipPathId = generateId(30, 'cp-');
                let $clipPath = $el.parentNode;
                let $pointerEl = this.svgContainer[this.activeSlideIndex].querySelector(`[clip-path="url(#${$clipPath.id})"]`);
                $clipPath.id = clipPathId;
                $pointerEl.setAttribute('clip-path', `url(#${clipPathId})`);
            }
            $el.dataset.generatedId = true; // Make sure to track that this id was generated by us
            
            if (type == 'text') {
                const centerX = $el.getBoundingClientRect().left + $el.getBoundingClientRect().width / 2;
                const centerY = $el.getBoundingClientRect().top + $el.getBoundingClientRect().height / 2;
                const foundEl = document.elementsFromPoint(centerX, centerY);
                for (let element of foundEl) {
                    if (element.id != $el.id && element.innerHTML == $el.innerHTML) {
                        element.dataset.linked = $el.id;
                    }
                }
            }
            // Update the editable index stored on the element
            $el.dataset.editable = this.activeSlide.editable.length + 1;

            // Add a new editable field to the active slide
            const editable = {
                id: $el.id,
                element: $el,
                type,
                label,
                flags,
                fillOptions,
                strokeOptions,
                original,
                align: $el.getAttribute('data-text-anchor') || $el.getAttribute('text-anchor'),
                limit,
            };
            this.activeSlide.editable.push(editable);

            // Force a refresh of the active slide by setting it again in the array
            this.$set(this.slides, this.activeSlideIndex, this.activeSlide);

            // Select the editable in the sidebar immediately
            this.selectEditable(editable, this.activeSlide.editable.length - 1);

            // Refresh inspector
            if (this.$refs.inspector) {
                this.$refs.inspector.reload();
            }
        },

        onRemove (id) {
            if (window.confirm('Are you sure you want to remove this element?')) {
                const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
                const $el = $template.querySelector(`[id='${id}']`);
                this.removeEditable($el);
            }
        },

        removeEditable ($el) {
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const index = this.activeSlide.editable.findIndex(editable => editable.id == $el.id);
            const editable = this.activeSlide.editable[index];
           
            // Remove flags
            if (editable.flags) {
                for (let i = editable.flags.length - 1; i >= 0; i--) {
                    this.removeFlag(editable, editable.flags[i]);
                }
            }

            $el.removeAttribute('data-editable');
            const linked = document.querySelectorAll(`[data-linked='${$el.id}']`);
            for(let element of linked){
                element.removeAttribute('data-linked');
            }
            this.activeSlide.editable.splice(index, 1);

            // Fix indices on following elements
            for (let i = index; i < this.activeSlide.editable.length; i++) {
                $template.querySelector(`[id='${this.activeSlide.editable[i].id}']`).dataset.editable = i + 1;
            }

            // Reload inspector
            // this.$refs.inspector.reload();
        },
        determineTextType($el){
            if($el.parentNode.nodeName == 'text'){
                $el = $el.parentNode;
            }
            if($el.children.length <= 1){
                return 'text';
            }
            for(let element of $el.children){
                if(element.nodeName != 'tspan'){
                    return 'text';
                }
            }

            return 'multiline';
        },

        determineType ($el) {
            if ($el.tagName === 'use') {
                $el = this.$refs.template.querySelector($el.href.baseVal);
            }

            switch ($el.tagName) {
                case 'text': return this.determineTextType($el);
                case 'tspan': return this.determineTextType($el);
                case 'image': return 'image';
                default: return 'color';
            }
        },

        guessLabel ($el) {
            while ((!$el.id || $el.dataset.generatedId) && $el.parentNode) {
                $el = $el.parentNode;
            }

            if ($el.id) {
                const idParts = $el.id.split('_');
                const lastPart = idParts[idParts.length - 1];

                if (lastPart && lastPart.length > 2) {
                    return lastPart;
                }
            }

            return 'New Field';
        },

        determineOptions ($elType, type, element) {                
            if($elType !== 'image'){
                if((element.hasAttribute('fill') && element.getAttribute('fill') != 'none') && (element.hasAttribute('stroke' && element.getAttribute('fill') != 'none'))){
                    return [];
                }
                const styles = window.getComputedStyle(element);
                //If there is no color lets just set to invisible
                let color = styles[type];
                if(color == 'none'){
                    return [ 'rgba(0, 0, 0, 0)' ];
                }
                if(!color.includes('rgba') && !color.includes('rgb')){
                    color = hexToRgba(color);
                }
                if(!color.includes('rgba')){
                    color = rgbToRgba(color);
                }
                return [ color ];
            }
            return undefined;
        },

        determineFlags (type, $el) {
            let flags = {};
            flags['remove'] = false;
            flags['user-hideable'] = true;
            switch (type) {
                case 'text': 
                    flags['free-color'] = false;
                    break;
                case 'multiline':
                    flags['free-color'] = false;
                    break;
                case 'color':
                    flags['free-color'] = false;
                    break;
                default: break;
            }
            return flags;
        },

        determineLimit (type, $el) {
            // Default to none
            return null;
        },

        onMouseout (e) {
            e.target.removeEventListener('mouseout', this.onMouseout);
            e.target.classList.remove('hover');
        },

        updateLabel (e, i, label) {
            e.label = label;
            this.activeSlide.editable.splice(i, 1, e);
        },

        updateColors(e, i, colors, type){
            e[type] = colors;
            this.activeSlide.editable.splice(i, 1, e);
        },
        getOpacity(id){
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const $el = $template.querySelector(`[id='${id}']`);
            if($el.hasAttribute('opacity')){
                return $el.getAttribute('opacity');
            }else{
                return 1;
            }
        },
        updateOpacity(id, opacity){
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const $el = $template.querySelector(`[id='${id}']`);
            $el.setAttribute('opacity', opacity);
        },

        addFlag (editable, flag) {
            switch (flag) {
                case 'fill': this.addColor(editable, 'fill'); break;
                case 'stroke': this.addColor(editable, 'stroke'); break;
                case 'remove': this.hideElement(editable); break;
                case 'free-color': this.enableFreeColor(); break;
            }
            editable.flags[flag] = true;
        },

        removeFlag (editable, flag) {
            switch (flag) {
                case 'fill': this.removeColor(editable, 'fill'); break;
                case 'stroke': this.removeColor(editable, 'stroke'); break;
                case 'remove': this.showElement(editable); break;
                case 'free-color': this.disableFreeColor(); break;
            }
            editable.flags[flag] = false;
        },

        enableFreeColor(){

        },

        disableFreeColor(){

        },
        
        hideElement(editable){
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const element = $template.querySelector(`[id='${editable.id}'`);
            element.style.display = 'none';
            element.dataset.removed = true;
        },

        showElement(editable){
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const element = $template.querySelector(`[id='${editable.id}'`);
            element.style.display = 'block';
            element.dataset.removed = false;
        },

        addColor(editable, type){
            //Find current color
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            let color = editable.current ? editable.current : '#fff';
            const element = $template.querySelector(`[id='${editable.id}'`);
            if(type === 'fill'){
                element.removeAttribute('fill-opacity');
                element.setAttribute('fill', color);
            }else{
                element.removeAttribute('stroke-opacity');
                element.setAttribute('stroke', color);
            }
            //replace svg with new modified svg, helps for updating projects with special editable flags
            this.$set(this.svgContainer, this.activeSlideIndex, $template);
            this.$set(this.slides, this.activeSlideIndex, this.activeSlide);
        },

        removeColor(editable, type){
            const $template = this.$refs.template.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const element = $template.querySelector(`[id='${editable.id}'`);
            if(type === 'fill'){
                editable.previous = element.getAttribute('fill');
                element.style = null;
                element.setAttribute('fill', 'none');
            }else{                
                editable.previous = element.getAttribute('stroke');
                element.style = null;
                element.setAttribute('stroke', 'none');                
            }
            if(editable.flags.length === 0){
                element.removeAttribute('stroke');
                element.removeAttribute('fill');
                if(editable.original){
                    if(editable.original.hasAttribute('fill')){
                        element.setAttribute('fill', editable.original.getAttribute('fill'));
                    }else if(editable.original.hasAttribute('stroke')){
                        element.setAttribute('stroke', editable.original.getAttribute('stroke'));
                    }
                }else{
                    //Removing stroke / fill attribute might result in default black color this is a bandaid
                    element.setAttribute('fill-opacity', 0);
                    element.setAttribute('stroke-opacity', 0);
                }
            }
            //replace svg with new modified svg, helps for updating projects with special editable flags
            this.$set(this.svgContainer, this.activeSlideIndex, $template);
            this.$set(this.slides, this.activeSlideIndex, this.activeSlide);
        },
        cleanSvgEditables($svg){
            const $editables = $svg.querySelectorAll('[data-editable]');
            for (const $editable of $editables) {
                $editable.removeAttribute('data-editable');
            }
        },
        removeClickable($svg){
            if(this.ready){
                // Clean up all listeners and clickable data attributes
                const $clickables = $svg.querySelectorAll('[data-clickable]');
                for (const $clickable of $clickables) {
                    $clickable.removeEventListener('click', this.onElementClicked);
                    $clickable.removeAttribute('data-clickable');
                }
            }
        },

        exportSvg (data) {
            const $svg = data;
            this.removeClickable($svg);
            const serializer = new XMLSerializer();
            const string = serializer.serializeToString($svg);

            const fileParts = [ string ];
            const blob = new Blob(fileParts, { type: 'image/svg+xml' });

            return { $svg, string, blob };
        },

        prepareEditables (){
            for (const slide of this.slides) {
                for (const editable of slide.editable) {
                    if (editable.type !== 'image') {
                        const options = editable.options;
                        const fillOptions = editable.fillOptions;
                        const strokeOptions = editable.strokeOptions;

                        //convert hex to rgba or rgb to rgba
                        if(options){
                            for(let i = 0; i < options.length; i++){
                                if(!options[i].includes('rgba') && !options[i].includes('rgb')){
                                    options[i] = hexToRgba(options[i]);
                                }
                                if(!options[i].includes('rgba')){
                                    options[i] = rgbToRgba(options[i]);
                                }
                            }
                        }
                        if(fillOptions){
                            for(let i = 0; i < fillOptions.length; i++){
                                if(!fillOptions[i].includes('rgba') && !fillOptions[i].includes('rgb')){
                                    fillOptions[i] = hexToRgba(fillOptions[i]);
                                }
                                if(!fillOptions[i].includes('rgba')){
                                    fillOptions[i] = rgbToRgba(fillOptions[i]);
                                }
                            }
                        }
                        if(strokeOptions){
                            for(let i = 0; i < strokeOptions.length; i++){
                                if(!strokeOptions[i].includes('rgba') && !strokeOptions[i].includes('rgb')){
                                    strokeOptions[i] = hexToRgba(strokeOptions[i]);
                                }
                                if(!strokeOptions[i].includes('rgba')){
                                    strokeOptions[i] = rgbToRgba(strokeOptions[i]);
                                }
                            }
                        }
                        if(editable.options){
                            //Set old options to fill automatically, stroke starts with transparent color
                            editable.fillOptions = editable.options;
                            editable.strokeOptions = [ 'rgba(0, 0, 0, 0)' ];
                            delete editable.options;
                        }
                    }

                    const $el = document.getElementById(editable.id);
                    
                    this.$set(
                        editable,
                        'align',
                        $el.getAttribute('data-text-anchor') || $el.getAttribute('text-anchor') || 'start',
                    );
                }
            }
        },
    
        fixFlags(preparedSlides, initial = false){
            let newEditables = [];
            if(initial){
                for(let i = 0; i < preparedSlides.length; i++){
                    for(let editable of preparedSlides[i].editable){
                        let newFlags = {};
                        if(Array.isArray(editable.flags) && editable.flags.length > 0){
                            for(let flag of editable.flags){
                                newFlags[flag] = true;
                            }
                        }
                        if(editable.type != 'image'){
                            newFlags['free-color'] = newFlags['free-color'] || false;
                        }
                        newFlags['remove'] = newFlags['remove'] || false;
                        newFlags['user-hideable'] = newFlags['user-hideable'] || false;
                        //If no flags exist then dont add flags object
                        if(Object.keys(newFlags).length != 0){
                            editable.flags = newFlags;
                        }
                        newEditables.push(editable);
                        
                    }
                    preparedSlides[i].editable = newEditables;  
                    newEditables = []; 
                }
                return preparedSlides;
            }else{
                for(let i = 0; i < preparedSlides.length; i++){
                    for(let editable of preparedSlides[i].editable){
                        if(editable.flags){
                            editable.flags = Object.keys(editable.flags).filter(key => editable.flags[key] === true);
                            newEditables.push(editable);
                        }else{
                            newEditables.push(editable);
                        }
                    }
                    preparedSlides[i].editable = newEditables;   
                    newEditables = [];
                }
                return preparedSlides;
            }
        },
        async onSave() {
            this.saving = true;

            //Grab for thumbnail
            this.setActive(0);

            const templates = [];
            for (let data of this.svgContainer){
                const $el = data;
                let { $svg, string, blob } = await this.exportSvg($el);
                templates.push({$svg, string, blob});
            }

            const thumb = await render({ $svg: templates[0].$svg, string: templates[0].string, renderWidth: 640 });
            let preparedSlides = [ ... this.slides ];
            preparedSlides = this.fixFlags(preparedSlides);
            const data = {
                name: this.name,
                media: this.media,
                thumb,
                slides: JSON.stringify(preparedSlides),
                categories: JSON.stringify(Object.keys(this.categories)),
                tags: JSON.stringify(this.keywords),
            };
            if (this.$tenant){ data.tenant = this.$tenant.id; }
            if (this.dimension) { data.dimension = this.dimension; }
            if (this.pricing == 'premium' && !this.pricingError) {
                data.price = Number(this.priceString.replace('.', ''));
            }

            const upload = form(data);

            for(let i = 0; i <= templates.length - 1; i++){
                upload.append('template' + i, templates[i].blob);
            }

            let id = this.id;
            let template;

            try {
                if (id) {
                    template = (await api.templates.update(id, upload)).data;
                } else {
                    template = (await api.templates.create(upload)).data;
                    id = template._id;
                }
                this.$router.replace(`/template/${id}/edit`);
                window.location.reload();
            } catch (e) {
                this.$toast.error('Could not upload template: ' + e, { timeout: false });
            }

            this.saving = false;
        },

        async onDelete () {
            if (!window.confirm('Are you sure you want to permanently delete this template? This could break user projects using this template, and the template will no longer be available.')) { return; }

            this.deleting = true;

            try {
                await api.templates.delete(this.id);
                this.$router.push('/');
            } catch (e) {
                console.error(e);
                this.$toast.error('Could not delete template: ' + e, { timeout: false });
            }

            this.deleting = false;
        },

        switchType(index, id, type){
            this.activeSlide.editable[index].type = type;
            const $el = this.$refs.template.querySelector('#'+id);

            if(type == 'multiline'){
                let lineHeight = 0;
                if($el.children.length == 0){
                    lineHeight = $el.getBBox().height.toPrecision(4);
                }else{
                    lineHeight = $el.children[0].getBBox().height.toPrecision(4);
                }
                
                $el.dataset.lineHeight = lineHeight;
            }else{
                $el.removeAttribute('data-line-height');
            }
            
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

h3 {
    margin: 24px 0 8px;
    font-size: 18px;
    font-weight: 500;
}

p {
    font-size: 11px;
}

.template-create-page {
    display: flex;
    height: 100%;
    width:100vw;
    flex-direction: column;

    @include max-width($width-small){
        min-width: $min-width;
        min-height: 100%;
    }
    .template-edit{
        display: grid;
        width: 100%;
        flex-grow: 1;
        grid-template-columns: auto 1fr;
        grid-template-rows: 100%;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        max-height: 100vh;
        padding-top:90px;

        @include max-width($width-small){
            grid-template-columns:1fr;
            grid-template-rows:auto 1fr;
        }
        .template-editor {
            display: grid;
            background:var(--background-color);
            grid-template-rows: auto 1fr auto;
            grid-template-columns:1fr;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 100%;
            max-height:100%;
            width: 100%;
            overflow-y: scroll;
            box-sizing: border-box;
            justify-items: center;
            
            .add-container{
                .control-add{
                display:flex;
                justify-content: center;
                align-items:center;
                
                    svg{
                        rect{
                            fill:white;
                        }
                    }
                }
            }
            .dropload {
                grid-row:1 / span 2;
                max-width: 640px;
                max-height: 640px;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }

            .template {
                display:flex;
                flex-grow:1;
                width:100%;
                height:100%;
                min-height: 720px;
                overflow:hidden;
                justify-content:center;
                align-items:center;   

                &.disabled {
                    min-height: auto;
                }

                .svg-container{
                    width: 90%;
                    height: 90%;
                    display:flex;
                    flex-grow:1;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                
                    &.disabled{
                        position:absolute;
                        top:0;
                        left:0;
                        opacity:0;
                        pointer-events:none;
                    }
                    svg {
                        background:white;
                        max-width:100%;
                        max-height:100%;

                        .notClickable{
                            pointer-events: none;
                        }
                    }
                }
            }

            .template-name {
                font-size: 20px;
            }
        }
    }
}

.tool-nav {
    background:#fff;
    z-index:2;
}

canvas {
    width: 100%;
}

</style>

<style lang="scss">
html, body {
    height: 100%;
}

.template-create-page .template-editor .template {
        svg {
            cursor: pointer;
            [data-uneditable="true"]{
                [data-uneditable="true"]{
                    pointer-events:none;
                }
            }
        .hover {
            outline: 10px solid rgba(53, 180, 14, 0.548);
        }

        [data-editable]::after {
            content: attr(data-editable);
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -8px;
            margin-right: -8px;
            width: 16px;
            height: 16px;
            background: red;
        }
    }
}
</style>
