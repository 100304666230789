<template>
    <div>
        <category-list :selected="selectedCategories" :categories="categories" @select="onSelectCategory" />

        <button @click="create()">Add New Category</button>
        <create-category-popup v-if="isCreate" @close="closeCreate" />

        <p>
            If you need to remove or edit a category, please contact a developer, as this change can affect multiple templates.
        </p>
    </div>
</template>

<script>
import categoriesMixin from '@/mixins/categories';

import CreateCategoryPopup from './CreateCategoryPopup.vue';

export default {
    mixins: [ categoriesMixin(false, false) ],
    components: { CreateCategoryPopup },
    data () { return {
        isCreate: false,
    }; },
    methods: {

        async create () {
            this.isCreate = true;
        },

        closeCreate () {
            this.isCreate = false;
            this.refreshCategories();
        },
    },
};
</script>

<style lang="scss" scoped>
p {
    font-size: 11px;
}
</style>
