<template>
    <div class="catalog-container">
        <button 
            class="carousel-btn" 
            :class="{ disabled: index == 0 }" 
            @click="handleDir('left')"
        >                            
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px.svg')"/>
        </button>
        <div class="tile-container">
            <div ref="tileTrack" class="tile-track">
                <catalog-tile 
                    v-for="template in templates" :key="template._id"
                    :template="template" 
                    :favorites="favorites" 
                    @add-favorite="addFavorite" 
                    @remove-favorite="removeFavorite" 
                />
            </div>
        </div>
        <button 
            class="carousel-btn" 
            :class="{ disabled: !checkRight }" 
            id="right-arrow" @click="handleDir('right')"
        >            
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px.svg')"/>
        </button>
    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import CatalogTile from './CatalogTile.vue';

import api from '@/helpers/api';
import templatesMixin from '@/mixins/templates';
import userMixin from '@/mixins/user';

export default {
    name: 'CatalogGrid',
    props: [ 'templates', 'page', 'isMobile' ],
    mixins: [ templatesMixin, userMixin ],
    components: {
        InlineSvg,
        CatalogTile,
    },
    computed: {
        checkRight(){
            if (!this.isMobile && (this.index == (this.templates.length - 1) / 3)) {
                return false;
            } else if (this.isMobile && (this.index == this.templates.length - 1)) {
                return false;
            } else {
                return true;
            }
        },
    },
    data () {
        return {
            index: 0,
            favorites: [],
            reset: false,
            cols: {
                default: 4,
                1200: 3,
                1050: 2,
                850: 1,
            },
        };
    },
    async mounted(){
        await this.ensureUser();

        if (this.user) {
            this.getFavorites();
        }
    },
    methods: {
        async handleDir(dir){
            if (dir == 'left' && this.index != 0) {
                this.index -= 1;
                this.handleTrack();
            } else if (dir == 'right' && this.checkRight ) {
                this.index += 1;
                this.handleTrack();
            }
        },

        handleTrack(){
            const track = this.$refs.tileTrack;
            track.style.transform = `translateX(-${(this.isMobile ? 10 : (3 / this.templates.length) * 100) * this.index}%)`;
        },

        async getFavorites(){
            if (this.user) {
                await this.refreshUser();
                this.favorites = this.user.favorites;
            }
        },

        async addFavorite(id){
            await api.users.addFavorite(id);
            await this.getFavorites();
        },  
        
        async removeFavorite(id){
            await api.users.removeFavorite(id);
            await this.getFavorites();
        },

        checkFavorite(id){
            if (this.favorites.includes(id)) {
                return true;
            }
            return false;
        },

    },
    watch: {
        isMobile(newValue){
            this.index = 0;
            this.handleTrack();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/colors';
.catalog-grid{
    opacity:1;
    &.hidden{
        opacity:0;
    }
}
.catalog-container{
    display:flex;
    justify-content: center;
    align-items:center;
    max-width:1080px;
    margin:0 auto;

    @include max-width($width-medium){
        width:100%;
    }

    button{
        margin:unset;
        background:unset;
        border:unset;
        height:unset;
        width:unset;

        &#right-arrow{
            transform:rotate(180deg);
        }
    }
}
.tile-container{
    max-height:500px;
    margin:0 auto;
    overflow:hidden;
    max-width:750px;

    @include max-width($width-medium){
        max-width:unset;
    }
    .tile-track{
        display:grid;
        grid-template-columns: repeat(10, 250px);
        transition:0.25s all;
        width:2500px;

        .tile{
            max-height:100%;
            align-self:center;
            justify-self:center;
            margin:unset;
            margin:8px;
            left:unset;
        }
    }
}
</style>

<style lang="scss">
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.carousel-btn{
    @include max-width($width-medium){
        padding:0;
    }
    &.disabled{
        svg{
            circle{
                stroke:var(--link-color-disabled);
            }
            path{
                fill:var(--link-color-disabled);
            }
        }
    }
    svg{
        circle{
            stroke:var(--link-color-active);
        }
        path{
            fill:var(--link-color-active);
        }
    }
}
</style>
