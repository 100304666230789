<template>
  <div class="tag-container">
      <span v-if="error" class="error">{{ message }}</span>
      <input @keyup.enter="addKey" type="text" v-model="tag" />
      <div :class="checkEmpty" class="keyword-container">
            <button :key="i" v-for="(keyword, i) in keywords" @click="removeKey(keyword)">
                <span>{{ keyword }}</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.70808 7.99989L2.44048 3.73226C2.12336 3.41514 2.12336 2.90097 2.44048 2.58396C2.75748 2.26694 3.27156 2.26694 3.5887 2.58396L7.99974 6.99525L12.4108 2.58411C12.7279 2.26709 13.242 2.26709 13.5589 2.58411C13.8762 2.90113 13.8762 3.41529 13.5589 3.73241L9.29149 7.99999L13.5591 12.2676C13.8762 12.5847 13.8762 13.0989 13.5591 13.4159C13.2421 13.7329 12.728 13.7329 12.4109 13.4159L7.99982 9.00463L3.58874 13.4158C3.27162 13.7328 2.7576 13.7328 2.44063 13.4158C2.12334 13.0988 2.12334 12.5846 2.44063 12.2675L6.70808 7.99989Z" fill="#1B1919"/>
                </svg>
            </button>
      </div>
  </div>
</template>

<script>
export default {
    props: [ 'keywords' ],

    computed:{
        checkEmpty(){
            if(this.keywords.length === 0){
                return 'empty';
            }
            return '';
        },
    },

    data(){
        return{
            error:false,
            message:'',
            tag:'',
        };
    },
    methods:{
        addKey(){
            if(this.tag !== ''){

                //check for spaces & duplicates
                let tags = this.tag.split(' ');
                tags = [ ... new Set(tags) ];
                if(tags.indexOf('') >= 0){
                    tags.splice(tags.indexOf(''), 1);
                }
                let checkDuplicate = this.keywords.filter(tag => tags.includes(tag));

                if(checkDuplicate.length === 0 && tags.length > 0 ){
                    this.error = false;
                    this.message = '';
                    this.$emit('add-key', tags);
                    this.tag = '';
                }else{
                    this.error = true;
                    if(tags.length === 0){
                        this.message = 'Tag cannot be a space';
                    }else{
                        this.message = 'Tag already exists';
                    }
                }
            }
        },
        removeKey(keyword){
            this.$emit('remove-key', keyword);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

.tag-container{
    width:100%;

    .error{
        color:$color-red-light;
    }

    input{
        width:100%;
    }
    .keyword-container{
        display:flex;
        flex-wrap:wrap;
        margin-top:10px;
        padding:2.5px;
        box-sizing:border-box;
        background:white;
        border:1px solid $color-gray-medium;
        border-radius:6px;
        max-height:160px;
        overflow-y:auto;

        &.empty{
            display:none;
        }

        button{
            display:grid;
            grid-template-columns:1fr auto;
            align-items:center;
            margin:2.5px;
            padding:5px 10px;
            height:unset;
            max-width:100%;
            min-width:80px;
            min-height:40px;

            span{
                max-width:100%;
                word-break:break-word;
            }
            svg{
                margin-left:5px;
                justify-content: flex-end;

                path{
                    fill:white;
                }
            }
        }
    }
}

</style>
