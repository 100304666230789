import CategoryList from '@/components/CategoryList.vue';
import api from '@/helpers/api';
import { filterEmpty } from '@/helpers/categories';

export default function categoriesMixin (manageSelected, filterEmptyCategories = true) {

    const mixin = {
        components: { CategoryList },

        mounted () {
            this.refreshCategories();
        },

        methods: {
            async refreshCategories () {
                const response = await api.categories.get({ tenant: this.$tenant?.id });
                this.originalCategories = response.data;

                if (filterEmptyCategories) {
                    this.categories = await this.filterCategories();
                } else {
                    this.categories = this.originalCategories;
                }
            },

            onSelectCategory (id) {
                if (manageSelected) {
                    if (this.selectedCategories[id]) {
                        this.$delete(this.selectedCategories, id);
                    } else {
                        this.$set(this.selectedCategories, id, true);
                    }
                }

                this.$emit('select-category', id);
            },

            findCategory (categories, id) {
                for (const category of categories) {
                    if (category._id == id) {
                        return category;
                    } else {
                        return this.findCategory(category.categories, id);
                    }
                }
            },

            async filterCategories () {
                if (filterEmptyCategories) {
                    return await filterEmpty(this.originalCategories, this.$route.query);
                }
            },
        },

        watch: {
            '$route.query' () {
                this.filterCategories();
            },
        },
    };

    if (manageSelected) {
        mixin.data = function () { return {
            categories: [],
            selectedCategories: {},
        }; };
    } else {
        mixin.props = [ 'selectedCategories' ];
        mixin.data = function () { return {
            categories: [],
        }; };
    }

    return mixin;
}
