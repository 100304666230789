<template>
    <div :class="fading" class="loading-container">
        <div class='loading-objects'>
            <div class='info-container'>
                <div class='info'>
                    <span v-if="slideIndex">Slide - {{ slideIndex }} / {{ slideLength }}</span>
                </div>
                <div class='progress'>
                    <span>{{ progressInfo }}</span>
                    <span> - {{ progress }}%</span>
                </div>
            </div>
            <div class='loading-bar-container'>
                <div class='loading-bar' :style="findProgress">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'progress', 'fadeLoad', 'progressInfo', 'slideIndex', 'slideLength' ],
    computed: {
        findProgress(){
            return `width:${this.progress}%`;
        },
        fading(){
            if(this.fadeLoad){
                return 'fadeOut';
            }
            return '';
        },
    },
    name: 'Loading',
};
</script>

<style lang="scss">
@import '@/assets/css/_colors.scss';

	.loading-container{
		position:absolute;
		top:0;
		left:0;
		background:white;
		width:100%;
        margin-top:90px;
		height: calc(100% - 90px);
		padding:40px;
		box-sizing: border-box;
		display:flex;
		justify-content: center;

		&.fadeOut{
			opacity:0;
			transition:0.25s ease-in-out;
		}
		.loading-objects{
			display:grid;
            justify-items: center;
			height:100%;
			width:100%;
            grid-template-rows: 1fr 1fr;
            grid-template-columns:auto;
			box-sizing: border-box;
			.info-container{
				align-self:flex-end;
				justify-self: center;
				padding-bottom:100px;

				.info, .progress{
					text-align:center;
					margin-bottom:10px;
					font-weight:bold;
				}
			}
			.loading-bar-container{
                grid-row:2;
				height:11px;
				width:60%;
                border-radius:20px;
				background:$color-gray-light;
				overflow:hidden;
				.loading-bar{
					height:100%;
					background:var(--link-color-active);
					transition:0.15s ease-in-out all;
				}
			}
		}
	}
</style>

