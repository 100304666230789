<template>
    <div class="prompt-container">
        <tool-nav class="tool-nav">
            <tool-button v-if="(userRole == 'admin' && !isMobile)" icon="templates" @click.native="onCreate">
                Edit Template
            </tool-button>
        </tool-nav>
        <div class="upper">
            <h3>Start a new project or open an existing one</h3>
        </div>
        <div class="lower">
            <div class="new-project" @click="openNew">
                <button class='icon'>
                    <inline-svg :src="require('@/assets/img/plus-flat.svg')" /> 
                </button>
                <button class="cta">Start New</button>
            </div>
            <tile v-for="(item, index) in projects" :key="index">
                {{item}}
                <template #image><img :src="item.thumbUrl" /></template>
                <template #cta>
                    <button @click="openEditor(index)" class="button">
                        Load
                    </button>
                </template>
            </tile>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import ToolButton from '@/components/ToolButton.vue';
import ToolNav from '@/components/ToolNav.vue';
import Tile from '@/components/Tile.vue';
import api from '@/helpers/api.js';
import userMixin from '@/mixins/user';

export default {
    components: { 
        InlineSvg,
        Tile,
        ToolButton,
        ToolNav,
    },
    mixins: [ userMixin ],

    computed: {
        isMobile(){
            if(this.windowWidth < this.$breakPoints['width-medium']){
                return true;
            }
            return false;
        },
    },

    data: function(){
        return{
            templateId: this.$route.params.id,
            projects: [

            ],
        };
    },
    async mounted(){
        await this.$nextTick();
        await this.ensureUser();
        if (this.user) {
            await this.getProjects();
        }else{
            this.openNew();
        }
    },

    methods: {
        openNew(){
            this.$router.replace('/template/' + this.templateId + '/edit/project/new');
        },

        async getProjects(){
            const q = { templateID: this.templateId };
            if (this.$tenant){ q.tenant = this.$tenant.id; }

            let res = await api.projects.get(q);
            
            this.projects = res.data.reverse();
            if (this.projects.length == 0) {
                this.openNew();
            }
        },

        async openEditor(index){
            let res = await api.projects.open(this.projects[index]._id);
            this.$router.replace('/template/' + res.data.templateID + '/edit/project/' + res.data._id);
        },

        /** Open the Template Create */
        onCreate () {
            this.$router.replace({ name: 'TemplateCreateExisting', params: { id: this.id }});
        },

    },

};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

h3 {
    margin: 48px auto;
    text-align: center;
}

    .prompt-container{
        display: flex;
        flex-direction: column;
        width:100vw;
        
        .upper{
            margin-top:90px;
            padding-left:56px;

            @include max-width($width-medium){
                padding:0 20px;
                text-align:center;
            }
        }
        .lower{
            margin:0 auto;
            padding-top:40px;
            max-width:1200px;
            display:flex;
            flex-wrap:wrap;
            align-content:center;
            justify-content: center;
            @include max-width($width-medium){
                padding:40px 56px;
            }
            @include max-width($width-small){
                padding:40px 5%;
            }

            .new-project {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 40px;

                 @include max-width($width-medium){
                    min-height:250px;
                    max-height:300px;
                }
            }
            
            .new-project .icon {
                background:unset;
                margin:unset;
                padding:unset;
                border:unset;
                width:200px;
                height:100%;
                padding: 10px;
                margin-right:10px;
                margin-bottom: 10px;
                box-sizing: border-box;
                border:2px solid $color-gray;
                transition:0.15s ease-in-out;

                svg{
                    width:80px;
                    height:80px;
                    transition:0.15s ease-in-out;
                }

                &:hover{
                    border-color: var(--link-color-active);

                    svg{
                        fill: var(--link-color-active);
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

    .prompt-container{
        .lower{
            .tile-container{
                .icon-container{
                    display:none;
                }
            }
        }
    }
</style>
