<template>
    <div class="social-posts-day-page">
        <h2>{{ date.format('MMMM D, YYYY') }}</h2>
        <button class="back-button" @click="$router.go(-1)">Back to Calendar</button>
        <div class="posts-grid">
            <div v-for="post in posts" :key="post._id" class="post">
                <image-carousel class="thumb" :srcs="post.mediaUrls" />
                <div class="actions">
                    <div class="time">{{ timeOf(post.date) }}</div>
                    <button v-if="isInFuture(post.date)" @click="deletePost(post)" class="img-button">
                        <img src="@/assets/img/icon_delete_24px.svg">
                    </button>
                </div>
                <div v-if="post.posts.length > 0">
                    <span v-for="(post, i) in post.posts" :key="post.id">
                        <template v-if="i > 0">, </template>
                        <a :href="post.url" target="_blank">{{ toNameCase(post.platform) }}</a>
                    </span>
                </div>
                <div v-else-if="post.platforms.length > 0">
                    <span v-for="(platform, i) in post.platforms" :key="platform">
                        <template v-if="i > 0">, </template>
                        {{ toNameCase(platform) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import ImageCarousel from '@/components/ImageCarousel.vue';
import api from '@/helpers/api';
import { toNameCase } from '@/helpers/utils';

export default {
    props: [ 'day' ],

    components: { ImageCarousel },

    data () { return {
        posts: [],
    }; },

    computed: {
        date () {
            return new dayjs(this.day);
        },
    },

    watch: {
        day () {
            this.refreshPosts();
        },
    },

    mounted () {
        this.refreshPosts();
    },

    methods: {
        async refreshPosts () {
            this.posts = (await api.social.getPosts({
                minDate: this.date.startOf('day').toISOString(),
                maxDate: this.date.endOf('day').toISOString(),
            })).data;
        },

        timeOf (date) {
            return dayjs(date).format('h:mm a');
        },

        isInFuture (date) {
            return dayjs(date).isAfter(dayjs());
        },

        async deletePost (post) {
            if (window.confirm('Are you sure you want to delete this scheduled post?')) {
                await api.social.deletePost(post._id);
                await this.refreshPosts();
            }
        },

        toNameCase,
    },
};
</script>

<style lang="scss" scoped>

.social-posts-day-page {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

h2 {
    flex-grow: 1;
}

.posts-grid {
    display: flex;
    width: 100%;
    margin: 24px -16px;
}

.post {
    width: 320px;
    min-height: 240px;
    margin: 0 16px;

    .thumb {
        width: 100%;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

</style>
