<template>
    <div class="menu-container">
        <button @click="toggleOpen" class="title" :class="open ? 'open' : ''">
            <span><slot name="title"/></span>
            <inline-svg :src="require('@/assets/img/arrow_down_16px.svg')" />
        </button>
        <div ref="description" class="description" :class="open ? '' : 'closed'">
            <span><slot name="description"/></span>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    components:{
        InlineSvg,
    },
    data:function(){
        return{
            open:false,
        };
    },
    methods:{
        toggleOpen(){
            this.open = !this.open;
            if(this.open){
                this.$refs.description.style.height = this.$refs.description.scrollHeight + 'px';
            }else{
                this.$refs.description.style.height = 0;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.menu-container{
    margin:0 auto;
    width:100%;
    margin-top:20px;
    text-align:left;
    
    @include max-width ($width-small) {
        width:100%;
    }
    .title{
        display:flex;
        font-size:16px;
        width:100%;
        height:unset;
        background:white;
        border: 1px solid #EEEEEE;
        padding:0;
        margin:0;
        box-sizing: border-box;
        border-radius:0;
        color:black;
        text-align:left;
        padding:10px;
        font-weight:500;

        @include max-width ($width-small) {
            font-size:12px;
        }
        span{
            display:Block;
            flex-grow:1;
            padding-right:10px;
        }
        &.open{
            color:var(--link-color-active);
            svg{
                transform:rotate(180deg);
            }
        }
        &:hover{
            color:var(--link-color-active);
        }
        svg{
            justify-self:flex-end;
            align-self:center;
            transition:transform 0.15s ease-in-out;
            fill:black;
            min-width:20px
        }
    }
    .description{
        width:100%;
        font-size:14px;
        background:white;
        box-sizing: border-box;
        height:0;
        overflow:hidden;
        transition:all 0.25s ease-in-out;
        font-weight:400;
        span{
            display:block;
            margin:10px;

            @include max-width ($width-small) {
                font-size:10px;
            }
        }
    }
}
</style>
