<template>
    <div class="plan-confirm-page">
        <h1>Congratulations!</h1>
        <p>You now are a member of RE Creates Premium!</p>
    </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.plan-confirm-page {
    margin: 36px 0;
    text-align: center;
}
</style>
