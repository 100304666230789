<template>
    <inline-svg :src="src" :aria-label="alt" :width="size" :height="size" />
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import iconCatBookSvg from '@/assets/img/icon_cat_book.svg';
import iconCatBrochureSvg from '@/assets/img/icon_cat_brochure.svg';
import iconCatBusinessCardSvg from '@/assets/img/icon_cat_business_card.svg';
import iconCatCovidSvg from '@/assets/img/icon_cat_covid.svg';
import iconCatEducationalSvg from '@/assets/img/icon_cat_educational.svg';
import iconCatFlyerSvg from '@/assets/img/icon_cat_flyer.svg';
import iconCatHolidaySvg from '@/assets/img/icon_cat_holiday.svg';
import iconCatNewsletterSvg from '@/assets/img/icon_cat_newsletter.svg';
import iconCatPostcardSvg from '@/assets/img/icon_cat_postcard.svg';
import iconCatPropertySvg from '@/assets/img/icon_cat_property.svg';
import iconCatTestimonialsSvg from '@/assets/img/icon_cat_testimonials.svg';

const srces = {
    book: iconCatBookSvg,
    brochure: iconCatBrochureSvg,
    business_card: iconCatBusinessCardSvg,
    covid: iconCatCovidSvg,
    educational: iconCatEducationalSvg,
    flyer: iconCatFlyerSvg,
    holiday: iconCatHolidaySvg,
    newsletter: iconCatNewsletterSvg,
    postcard: iconCatPostcardSvg,
    property: iconCatPropertySvg,
    testimonials: iconCatTestimonialsSvg,
};

export default {
    components: { InlineSvg },
    props: {
        icon: { type: String, default: 'postcard' },
        alt: { type: String, default: '' },
        size: { type: Number, default: 56 },
    },
    computed: {
        src () { return srces[this.icon]; },
    },
    available: Object.keys(srces),
};
</script>

<style>

</style>
