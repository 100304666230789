<template>
    <nav>
        <button class="back" @click="$router.go(-1)">
            <img src="@/assets/img/backarrow.svg" alt="" />
            <span>Go Back</span>
        </button>
        <router-link :to="{ name: 'Home' }">
            <img class="logo" src="@/assets/img/squareLogo.png" alt="Real Estate Creates" />
        </router-link>
        <div class="tools">
            <slot></slot>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavTool',
};
</script>

<style scoped lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

nav {
    position:absolute;
    top:0;
    display: flex;
    align-items: center;
    height: 90px;
    width: 100%;
    flex-shrink: 0;
    border-bottom: 2px solid #c4c4c4;
    font-size: 20px;
    padding: 0 56px;
    box-sizing: border-box;
    background:var(--template-nav-bg);

    button {
        appearance: none;
        margin: 0 8px;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color:unset;
    }

    a{
        display:block;
        flex-grow: 1;
        height:100%;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        text-decoration: none;
        color: rgb(0, 0, 0);

        img{
            height:100%;
        }

        &:hover{
            color:var(--link-color-active);
        }
    }
    .tools {
        display:flex;
        margin-left: auto;

        button{
            margin-left:10px;
        }
    }

    .back {
        img {
            margin-right: 10px;
        }
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 50px;
        box-sizing: border-box;

        div {
            display: flex;
            align-items: center;

            img {
                align-self: center;
                margin-right: 5px;
                height: 30px;
                width: 30px;
                background: #f5f5f5;
                border-radius: 100%;
                padding: 5px;
            }
        }
    }

    @include max-width($width-small){
        padding:0 5%;
        
        .logo{
            margin: 0 20px;
            max-width:100px;
        }
        .back{
            span{
                display:none;
            }
        }
        
        .tools{
            button{
                margin:0;
            }
        }
    }
}
</style>
