import { jsPDF } from 'jspdf';

import { awaitImageLoad, sleep } from './utils';

export default async function render ({
    $svg,
    string,
    blob,
    url,
    renderWidth = 1920,
    renderHeight,
    format = 'png',
    fill = true,
    dimension,
}) {
    if (format == 'pdf') { return renderPdf($svg, string, dimension); }

    // Correct jpg to jpeg
    if (format == 'jpg') { format = 'jpeg'; }

    if (!renderHeight) {
        const width = $svg.viewBox.baseVal.width;
        const height = $svg.viewBox.baseVal.height;

        if (width == renderWidth) {
            renderHeight = height;
        } else {
            const scale = renderWidth / width;
            renderHeight = Math.floor(height * scale);
        }
    }

    const canvas = document.createElement('canvas');
    canvas.width = renderWidth;
    canvas.height = renderHeight;
    const ctx = canvas.getContext('2d');

    if (fill) {
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, renderWidth, renderHeight);
    }

    if (!url) {
        if (string) {
            url = 'data:image/svg+xml;utf8,' + encodeURIComponent(string);
        } else if (blob) {
            url = URL.createObjectURL(blob);
        }
    }

    const $img = document.createElement('img');
    $img.width = renderWidth;
    $img.height = renderHeight;
    await awaitImageLoad($img, url);
    await sleep(1000);

    ctx.drawImage($img, 0, 0, renderWidth, renderHeight);

    if (format == 'canvas') {
        return canvas;
    } else {
        return new Promise((resolve) => {
            canvas.toBlob(blob => resolve(blob), 'image/' + format);
        });
    }
}

async function renderPdf ($svg, string, dimension) {
    if (!dimension) {
        dimension = { width: $svg.viewBox.baseVal.width, height: $svg.viewBox.baseVal.height, unit: 'pixels' };
    }

    // Dimension might already have a pixel size
    let renderWidth = dimension.width;

    // Calculate width using appropriate DPI / fallback
    switch (dimension.unit) {
        case 'inches':
            renderWidth = dimension.width * 300;
            break;
        case 'centimeters':
            renderWidth = dimension.width * 118;
            break;
        case 'ratio':
            dimension.width = 1920;
            dimension.height = 1920;
            dimension.unit = 'pixels';
            renderWidth = 1920;
            break;
    }

    const scale = renderWidth / dimension.width;
    const renderHeight = dimension.height * scale;

    const canvas = await render({ $svg, string, renderWidth, renderHeight, fill: false, format: 'canvas' });

    const orientation = dimension.height > dimension.width ? 'portrait' : 'landscape';
    const unit = { inches: 'in', centimeters: 'cm', pixels: 'px' }[dimension.unit];

    const doc = new jsPDF({ orientation, unit, format: [ dimension.width, dimension.height ] });

    doc.addImage(canvas, 'PNG', 0, 0, dimension.width, dimension.height);

    await doc.save('output.pdf');
}
