export default class HighlightEditable {
    constructor ($el, $indexEl, editable, $template) {
        this.$el = $el;
        this.$indexEl = $indexEl;
        this.editable = editable;
        this.$template = $template;
        this.hovering = false;
        this.typing = false;
        this.resizeBox = this.resizeBox.bind(this);
        this.mouseoutHandler = this.mouseoutHandler.bind(this);
        this.mouseoverHandler = this.mouseoverHandler.bind(this);
        this.clickEditable = this.clickEditable.bind(this);
        this.mouseoverHandlerEditable = this.mouseoverHandler.bind(this);
        this.mouseoutHandlerEditable = this.mouseoutHandlerEditable.bind(this);
        this.addEvents();
    }

    removeEvents() {
        this.$el.removeEventListener('mouseover', this.mouseoverHandler, true);
        this.$el.removeEventListener('mouseleave', this.mouseoutHandler, true);
        this.$el.removeEventListener('click', this.clickEditable, true);
        this.$indexEl.removeEventListener('mouseover', this.mouseoverHandlerEditable, true);
        this.$indexEl.removeEventListener('mouseleave', this.mouseoutHandlerEditable, true);
    }

    addEvents() {
        this.$el.addEventListener('mouseover', this.mouseoverHandler, true);
        this.$el.addEventListener('mouseleave', this.mouseoutHandler, true);
        this.$el.addEventListener('click', this.clickEditable, true);
        this.$indexEl.addEventListener('mouseover', this.mouseoverHandlerEditable, true);
        this.$indexEl.addEventListener('mouseleave', this.mouseoutHandlerEditable, true);
    }
    
    mouseoverHandlerEditable($event) {
        let target = $event.target;
        if (!target.classList.contains('editable')) {
            target = target.parentNode;
        }
        if (target.classList.contains('editable')) {
            this.highlightEditable(true);
            this.hovering = true;
        }
    }

    mouseoutHandlerEditable() {
        this.highlightEditable(false);
    }

    mouseoverHandler() {
        this.highlightEditable(true);
    }

    mouseoutHandler() {
        this.highlightEditable(false);
    }

    clickEditable() {
        const $editable = document.getElementById('editable-element-' + this.$el.id);

        $editable.scrollIntoView({ behavior: 'smooth' });

        if (this.editable.type !== 'image') {
            const $input = $editable.querySelector('input,textarea');
            $input.focus();
        }
    }

    highlightEditable(hover) {
        const editor = document.querySelector('.template-editor');

        if (hover && !document.getElementById('borderBox' + this.$el.id) && !this.typing) {
            const $overlay = document.createElement('div');

            $overlay.id = 'borderBox' + this.$el.id;
            $overlay.style.outline = '2px solid #25a1e8';
            $overlay.style.opacity = 0.75;
            $overlay.style.position = 'absolute';
            $overlay.style.boxSizing = 'border-box';

            const BBox = this.$el.getBoundingClientRect();

            $overlay.style.width = BBox.width + 'px';
            $overlay.style.height = BBox.height + 'px';
            $overlay.style.left = BBox.x + 'px';
            $overlay.style.top = BBox.y + 'px';
            $overlay.style.pointerEvents = 'none';
            document.body.append($overlay);
            this.$indexEl.classList.add('hover');
            window.addEventListener('resize', this.resizeBox);
            editor.addEventListener('scroll', this.resizeBox);
        } else if(!hover && document.getElementById('borderBox' + this.$el.id) && !this.typing) {
            window.removeEventListener('resize', this.resizeBox);
            editor.removeEventListener('scroll', this.resizeBox);
            document.getElementById('borderBox' + this.$el.id).remove();
            this.$indexEl.classList.remove('hover');
        }
    }

    resizeBox() {
        const $overlay = document.getElementById('borderBox' + this.$el.id);
        const BBox = this.$el.getBoundingClientRect();

        $overlay.style.width = BBox.width + 'px';
        $overlay.style.height = BBox.height + 'px';
        $overlay.style.left = BBox.x + 'px';
        $overlay.style.top = BBox.y + 'px';
        $overlay.style.pointerEvents = 'none';
    }

    isTyping(typing) {
        this.highlightEditable(false);
        if (!typing) {
            this.typing = false;
        } else {
            this.highlightEditable(true);
            this.typing = true;
        }
    }
}
