<template>
    <div v-if='this.user' class="purchased-container">
        <div class="shown">
            <tile v-for="purchase in shownPurchase" :key="purchase._id">
                <template #image><img :src="thumbs[purchase._id]" /></template>
                <template #smallBtn>
                    <button class="purchase-btn" @click="hidePurchase(purchase)">
                        <inline-svg :src="require('@/assets/img/eye_hide.svg')" />
                    </button>
                </template>
                <template #cta>
                    <router-link class="button" :to="`/template/${purchase.template}/edit`">
                        Start Project
                    </router-link>
                </template>
            </tile>
        </div>
        <button @click="toggleShowHidden" class="show-btn" :class="showHidden ? 'active' : 'inactive'">
            <span v-if="showHidden">Hide Hidden</span>
            <span v-else>Show Hidden</span>
        </button>
        <div v-if="showHidden" class="hidden">
            <tile v-for="purchase in hiddenPurchase" :key="purchase._id">
                <template #image><img :src="thumbs[purchase._id]" /></template>
                <template #smallBtn>
                    <button class="purchase-btn" @click="showPurchase(purchase)">
                        <inline-svg :src="require('@/assets/img/eye_show.svg')" />
                    </button>
                </template>
                <template #cta>
                    <router-link class="button" :to="`/template/${purchase.template}/edit`">
                        Start Project
                    </router-link>
                </template>
            </tile>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import Tile from '@/components/Tile.vue';
import api from '@/helpers/api.js';
import userMixin from '@/mixins/user';

export default {
    components: {
        Tile,
        InlineSvg,
    },

    mixins: [ userMixin ],

    computed: {
        shownPurchase(){
            return this.purchases.filter(x => !x.hidden);
        },
        hiddenPurchase(){
            return this.purchases.filter(x => x.hidden);
        },
    },

    data(){
        return{
            purchases: [],
            thumbs: {},
            showHidden: false,
        };
    },
    async mounted(){
        await this.$nextTick();  
        await this.ensureUser();
        
        if (this.user) {
            this.initialize();
        } else {
            this.$router.push('/');
        }
    },

    methods: {
        async initialize(){
            this.purchases = (await api.purchases.get()).data;
            this.purchases.forEach(async purchase => {
                const template = (await api.templates.getById(purchase.template)).data;
                this.$set(this.thumbs, purchase._id, template.thumbUrl);
            });
        },

        toggleShowHidden(){
            this.showHidden = !this.showHidden;
        },
        async showPurchase(purchase){
            purchase.hidden = false;
            await api.purchases.update(purchase._id, purchase);
            this.purchases = (await api.purchases.get()).data;
        },
        async hidePurchase(purchase){
            purchase.hidden = true;
            await api.purchases.update(purchase._id, purchase);
            this.purchases = (await api.purchases.get()).data;
        },
    },
    
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

    .purchased-container{
        display:flex;
        flex-direction: column;
        flex-wrap:wrap;
        width:100%;
        padding-top:25px;
        padding-bottom:35px;
        .show-btn{
            width:40%;
            align-self:center;
            color:$color-gray-dark;
            border-color:$color-gray-dark;
            background:white;
            margin-bottom:40px;

            @include max-width($width-medium){
                width:80%;
            }

            &.active{
                color:white;
                border-color:var(--link-color);
                background:var(--link-color);
            }
            &:hover{
                color:white;
                border-color:var(--link-color-active);
                background:var(--link-color-active);
            }
        }
        .shown, .hidden{
            display:flex;
            justify-content: center;
            align-items:flex-start;
            flex-wrap:wrap;
        }
        .icon-container{
            .purchase-btn{
                display:flex;
                margin:0;
                padding:0;
                background:unset;
                border:unset;
                svg{
                    margin:0 auto;
                    height:20px;
                    width:20px;
                    justify-self:center;
                    align-self:center;
                }
            }
        }
        @include max-width ($width-small) {
            justify-content: center;
        }
    }
</style>


