import api, { query } from '@/helpers/api';

export async function filterEmpty (categories, qi = {}) {
    const counts = await Promise.all(categories.map(async category => {
        const q = Object.assign({}, qi);
        q.categories = category._id;
        return (await api.templates.getCount(query(q))).data;
    }));

    return categories.filter((category, index) => {
        return counts[index] > 0;
    });
}
