<template>
    <footer>
        <div class="lowerFooter content">
            <router-link to="/" class="logo">
                <inline-svg :src="require('@/assets/img/Logo.svg')" class="desktop-logo"/>
            </router-link>
            <div class="links-container">
                <div class="links-left">
                    <router-link class="label navMenu" to="/template/catalog">
                        Templates
                    </router-link>
                    <router-link class="label navMenu" to="/how-it-works">
                        How it works
                    </router-link>
                    <router-link v-if="($tenant && $tenant.showPricing) || (!$tenant)" class="label navMenu" to="/plans">
                        Plans
                    </router-link>
                </div>
                <div class="links-right">
                    <router-link to="/contact">Contact</router-link>
                    <router-link to="/terms">Terms & Condition</router-link>
                    <router-link to="/privacy">Privacy Policy</router-link>
                </div>
                <div class="socialmedia">
                    <a><inline-svg :src="require('@/assets/img/icons8-facebook-50.svg')"/></a>
                    <a><inline-svg :src="require('@/assets/img/icons8-instagram-24.svg')"/></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AppFooter',
    components: {
        InlineSvg,
    },
};
</script>

<style lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/colors';

footer {
    display:flex;
    justify-content: center;
    bottom:0;
    width:100vw;
    text-align: left;
    background: var(--background-color);
    border-top:5px solid black;

    @include max-width($width-medium){
        flex-direction:column;
        width:100%;
    }
    .lowerFooter {
        display:grid;
        grid-template-columns:auto 1fr auto;
        padding: 40px;
        align-items:center;
        max-width: 1680px;
        box-sizing: border-box;

        @include max-width($width-medium){
            display:flex;
            flex-direction: column;
            width:100%;
        }
        .links-container{
            display:Flex;
            flex-direction:row;
            justify-self:flex-end;
            gap:40px;


            @include max-width($width-medium){
                flex-direction: column;
                gap:0;
                text-align:center;
                margin-top:40px;
            }
            .links-left, .links-right{
                display:flex;
                flex-direction:column;
            }

            a{
                color:black;
                margin-bottom:5px;

                &:hover{
                    color:var(--link-color-active);
                }
            }
        }
        .socialmedia{
            display:flex;
            gap:10px;
            align-items:center;

            @include max-width($width-medium){
                margin-top:40px;
            }
            a{
                svg{
                    height:65px;
                    width:65px;
                    fill:black;

                    &:hover{
                        fill:var(--link-color-active);
                    }
                }
            }
        }
        .copyright {
            font-size: 12px;
            font-weight: 500;
            text-align:right;
            flex-grow:1;

            @include max-width($width-small){
                grid-column: 1 / span 3;
                text-align:center;
            }
        }
    }
}
</style>
