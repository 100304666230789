<template>
    <checklist-view>
        <li v-for="category in categories" :key="category._id" @click="$emit('select', category._id)">
            <checkbox :value="selected[category._id]" />
            <span>{{ category.name }}</span>
            <span v-if="category.hoverName" class="hover-name">{{ category.hoverName }}</span>
        </li>
    </checklist-view>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import ChecklistView from '@/components/ChecklistView.vue';

export default {
    props: [ 'selected', 'categories' ],
    components: { Checkbox, ChecklistView },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

li {
    position: relative;
    cursor: pointer;

    .hover-name {
        display: none;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        margin: 0;
        padding: 0 4px;
        border: 1px solid $color-gray;
        border-radius: 4px;
        background: white;
        color: $color-gray;
        white-space: nowrap;
        z-index: 10;
    }

    &:hover .hover-name {
        display: block;
    }
}
</style>
