<template>
    <!-- Radio picker lets user select only one of several options -->
    <div class="radio-picker" :class="design">
        <button
            v-for="option in options"
            :key="option"
            :class="{ disabled: option !== selected }"
            @click="$emit('select', option)"
        >
            {{ name(option) }}
        </button>
    </div>
</template>

<script>
import { startCase } from 'lodash';

export default {
    name: 'RadioPicker',
    props: [ 'options', 'selected', 'design' ],

    methods: {
        name (option) {
            switch (this.design) {
                case 'mini':
                case 'traditional':
                    return startCase(option);
                default: return option.toUpperCase();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

button {
    min-width: 80px;
    height: 36px;
    margin-right: 16px;
    padding: 8px 16px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    
    &.disabled {
        border: none;
        cursor: pointer;
    }
}

.traditional button {
    position: relative;
    min-width: auto;
    height: auto;
    padding: 8px 8px 8px 32px;
    background: none;
    color: $color-black;
    font-weight: normal;
    box-sizing: border-box;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border: 1px solid var(--link-color);
        border-radius: 50%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 7px;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background: var(--link-color);
        border-radius: 50%;
    }

    &.disabled::after {
        display: none;
    }
}

.mini button {
    min-width: auto;
    height: 28px;
    margin-right: 8px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: normal;
    border-radius: 4px;
}
</style>
