<template>
    <div
        :id="'editable-element-' + id"
        v-if="checkRemove"
        ref="editable"
        class="editable"
        :data-editable="index + 1"
    >
        <div v-if="editable.type != 'color'" class="type">
            <span ref="index" class="index">{{ order + 1 }}</span
            >{{ editable.label }}
        </div>
        <div 
            v-if="isEditableColor" 
            class="type">
            <span ref="index" class="index">{{ order + 1 }}</span
            >{{ editable.label }}
        </div>
        <input
            v-if="editable.type == 'text'"
            ref="input"
            :value="getTextValue"
            :maxlength="editable.limit ? editable.limit : false"
            :placeholder="placeholder"
            @input="$emit('edit-element', {index:index, value:$event.target.value, order:order })"
        />
        <textarea
            v-if="editable.type == 'multiline'"
            ref="input"
            :value="getTextValue"
            :placeholder="placeholder"
            @input="multilineInput"
        />
        <button
            v-if="editable.type == 'image'"
            @click="$refs.imageUpload.click()"
        >
            Upload Image
        </button>
        <input
            v-if="editable.type == 'image'"
            @change="handleImage"
            ref="imageUpload"
            type="file"
            style="display:none"
            accept="image/png, image/jpeg, image/gif"
        />
        <template v-if="editable.type != 'image'">
            <label v-if="editable.fillOptions.length > 1 || checkFreeColor">
                {{ (editable.type == 'text' || editable.type == 'multiline') ? 'Text Color' : 'Background Color' }}:
            </label>
            <color-selector 
                @set-color="$emit('set-color', {type:'fill', color:$event.color})"
                v-if="editable.fillOptions.length > 1 && !checkFreeColor"
                :initialColor="editable.fillColor"
                :options="editable.fillOptions"
                :editable="false"
            />
            <color-selector
                @set-color="$emit('set-color', {type:'fill', color:$event.color})"
                @handle-color="$emit('handle-color', {color:$event.color})"
                v-if="checkFreeColor"
                :initialColor="editable.fillColor"
                :options="editable.fillOptions"
                :free="true"
                :colorHistory="getColorHistory"
            />
            <label v-if="editable.strokeOptions.length > 1 || checkFreeColor">Outline Color:</label>
            <color-selector 
                @set-color="$emit('set-color', {type:'stroke', color:$event.color})"
                v-if="editable.strokeOptions.length > 1 && !checkFreeColor"
                :initialColor="editable.strokeColor"
                :options="editable.strokeOptions"
                :editable="false"
            />
            <color-selector
                @set-color="$emit('set-color', {type:'stroke', color:$event.color})"
                @handle-color="$emit('handle-color', {color:$event.color})"
                v-if="checkFreeColor"
                :initialColor="editable.strokeColor"
                :options="editable.strokeOptions"
                :free="true"
                :colorHistory="getColorHistory"
            />
        </template>
        <zoom-slider
            @handle-zoom="$emit('handle-zoom', $event)"
            v-if="editable.type == 'image' && editable.value"
            :id="editable.id"
            :index="index"
            :value="getZoom"
        />
        <button
            v-if="editable.type == 'image'"
            @click="$emit('handle-reset-image')"
        >
            Reset Position &amp; Zoom
        </button>
        <flag-selector v-if="hasUserFlags" :flags="editable.flagsState" @select="onSelectFlag" :editor='true' />
    </div>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import ColorSelector from '@/components/ColorSelector.vue';
import FlagSelector from '@/components/FlagSelector.vue';
import ZoomSlider from '@/components/ZoomSlider.vue';
import userMixin from '@/mixins/user';

export default {
    props: [ 
        'id',
        'index', 
        'label',  
        'flagsState',
        'disabled', 
        'editable',
        'active',
        'windowWidth', 
        'textValue',
        'order',
    ],

    mixins: [ userMixin ],

    components: { ColorSelector, AppButton, ZoomSlider, FlagSelector },
    computed: {
        hasUserFlags(){
            if(this.editable.flags.includes('user-hideable')){
                return true;
            }
            return false;
        },
        checkRemove(){
            if(this.editable.flags.includes('remove')){
                return false;
            }
            return true;
        },
        checkFreeColor(){
            if(this.editable.flags.includes('free-color')){
                return true;
            }
            return false;
        },
        getZoom(){
            return this.editable.zoom;
        },
        getTextValue(){
            return this.textValue;
        },
        isEditableColor(){
            if (this.editable.type == 'color' && 
                ((this.editable.strokeOptions.length > 1 ||
                    this.editable.fillOptions.length > 1) ||
                    this.checkFreeColor || this.hasUserFlags
                )) {
                return true;
            }
            return false;
        },
        getColorHistory(){
            if (this.user) {
                if (!this.user.colorHistory) {
                    return [];
                }
                return this.user.colorHistory;
            }
            return [];
        },
    },

    data() {
        return {
            typing: false,
            placeholder: '',
        };
    },
    mounted() {
        this.placeholder = this.getPlaceholder();
        if (!this.editable.flags.includes('remove')) {
            if (this.editable.type === 'text' || this.editable.type === 'multiline') {
                this.$refs.input.addEventListener('focus', this.onFocus);
                this.$refs.input.addEventListener('blur', this.onBlur);
                
            }
        }
    },
    unmounted(){
        if (this.checkType && !this.editable.flags.includes('remove')) {
            if (this.editable.type === 'text' || this.editable.type === 'multiline') {
                this.$refs.input.removeEventListener('focus', this.onFocus);
                this.$refs.input.removeEventListener('blur', this.onBlur);
            }
        }
    },
    methods: {
        onFocus($event){
            this.activeInput($event, true);
        },

        onBlur($event){
            this.activeInput($event, false);
        },

        getPlaceholder(){
            const $el = document.getElementById(this.editable.id);
            if(this.editable.type == 'multiline'){
                let text = '';
                let children = $el.children;
                for(let i = 0; i < children.length; i++){
                    if(i > 0){
                        text += '\n';
                    }
                    text += children[i].textContent + ' ';
                }
                return text;
            }else{
                return $el.textContent;
            }
        },
        multilineInput($event){
            let ignore = $event.target.value.split('\n').length;
            let previousCursor = $event.target.selectionStart;
            if(this.editable.limit){
                if(($event.target.value.length - ignore) - 1 <= this.editable.limit){
                    this.$emit('edit-element', {index: this.index, value: $event.target.value, order: this.order });
                }else{
                    this.$refs.input.value = this.editable.value;
                    //puts cursor back to where it was before update;
                    $event.target.selectionStart = previousCursor;
                    $event.target.selectionEnd = previousCursor - 1;
                }
            }else{
                this.$emit('edit-element', {index: this.index, value: $event.target.value, order: this.order });
            }
        },
        activeInput(e, focus) {
            if (focus) {
                this.$emit('highlight-input', { order: this.order, typing: true });
                this.$emit('set-typing', true);
            } else {
                this.$emit('set-typing', false);
                this.$emit('highlight-input', { order: this.order, typing: false });
            }
        },
        handleImage(e) {
            const fr = new FileReader();
            fr.readAsDataURL(e.target.files[0]);

            fr.onload = () => {
                this.$emit('change-image', { value: fr.result, id: this.editable.id, index: this.index });
            };
        },
        onSelectFlag (flag) {
            this.$set(this.flagsState, flag, !this.flagsState[flag]);
            this.$emit('handle-user-flags', { index: this.index, flag: flag });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
.editable {
    margin-top: 30px;

    input,
    div {
        margin-bottom: 10px;
        font-weight: 500;
    }
    textarea{
        min-height:80px;
        min-width:100%;
        max-width:100%;
    }
    textarea, input {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;

        &:focus {
            border: 2px solid black;
        }
    }
    button{
        margin-bottom:20px;
    }
    .type {
        font-size: 20px;

        .index {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 4px;
            padding: 0 8px;
            border-radius: 50%;
            background: $color-red-light;
            color: white;
            font-size: 16px;
            line-height: 26px;
            vertical-align: 1px;
            box-sizing: border-box;
            transition:0.15s ease-in-out;
        }
    }

    &.hover{
        .type{
            .index{
                background: var(--link-color-active);
            }
        }
    }
}
</style>
