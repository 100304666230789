<template>
    <popup title="Unsaved Changes" @close="$emit('close')">
        <h2>You have unsaved changes!</h2>
        <p>Would you like to save before continuing?</p>
        <button @click="save">Save</button>
        <button @click="$emit('continue-routing')">Don't Save</button>
        <button @click="$emit('close')">Cancel</button>
    </popup>
</template>

<script>
import Popup from '@/components/Popup.vue';

export default {
    components: { Popup },
    methods: {
        save () {
            this.$emit('save', () => {
                this.$emit('continue-routing');
            });
        },
    },
};
</script>

<style scoped>
button {
    margin-right: 16px;
}
</style>
