
export default class MultiProgress {

    constructor (onUpdate) {
        this.progress = 0;
        this.trackers = [];
        this.onUpdate = onUpdate;
    }

    track () {
        const tracker = {
            progress: 0,
        };

        tracker.update = (progress) => {
            tracker.progress = progress;
            this.recalculate();
        };

        this.trackers.push(tracker);

        return tracker.update;
    }

    recalculate () {
        let total = 0;

        for (const tracker of this.trackers) {
            total += tracker.progress;
        }

        this.progress = total / this.trackers.length;
        this.onUpdate(this.progress);
    }
}
