<template>
    <div class="contact-container"> 
        <div class="text-form">
            <span v-if="this.successful == 204" class="success">Submitted!</span>
            <h2>We are just one E-mail away!</h2>
            <form ref="form">
                <div class="text-row">
                    <div>
                        <label>Name</label>
                        <input type="text" v-model="name">
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" v-model="email">
                    </div>
                </div>
                <label>Message</label>
                <textarea v-model="description" />
                <button @click="sendEmail">Send</button>
            </form>
            
        </div>
        <div class="faq-container">
            <h2>Frequently Asked Questions</h2>
            <p>Below you can find our handy FAQ section. If you don’t find what you’re looking for go ahead and contact us, our customer support team would be happy to help you</p>
            <drop-down-row>
                <template v-slot:title>
                    Why should I subscribe?
                </template>
                <template v-slot:description>
                    In Real Estate, each day that passes brings new clients and leads like a revolving door. 
                    Exceptional promotional quality can often be the difference in a sea of agents and listings that potential clients wade through. 
                    As an agent, you're constantly marketing yourself, your services, and your listings; do it well, and start today.
                </template>
            </drop-down-row>
            <drop-down-row>
                <template v-slot:title>
                    Who should use Real Estate Creates?
                </template>
                <template v-slot:description>
                    This is a tool designed specifically for Real Estate agents with promotional designs and templates created to maximize clients, 
                    listings, and sales through professional advertising strategies. 
                    Any agent can benefit from using Real Estate Creates.
                </template>
            </drop-down-row>
            <drop-down-row>
                <template v-slot:title>
                    Do you have team options?
                </template>
                <template v-slot:description>
                    There is a helpful co-working feature built in that allows you to share completed promotions or 
                    works-in-progress to other people so that they can either view or edit the work.
                </template>
            </drop-down-row>
            <drop-down-row>
                <template v-slot:title>
                    What results can I expect?
                </template>
                <template v-slot:description>
                    As with any given tool, much of the results will depend on how you use it. 
                    While we cannot guarantee record-breaking sales, you will have access to high-quality promotional designs and marketing material with which to create a 
                    trustworthy and professional agency image for both yourself and your clientele.
                </template>
            </drop-down-row>
            <drop-down-row>
                <template v-slot:title>
                    What can I do with the templates?
                </template>
                <template v-slot:description>
                    Once you've produced a personalized promotion from one of the templates, you can download it to your device to either print, post, or email as you see fit. 
                    You can even save yourself time and energy by immediately sharing it to your various social media outlets directly from the site.
                </template>
            </drop-down-row>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import DropDownRow from '@/components/DropDownRow.vue';
import api from '@/helpers/api';


export default {
    components: {
        DropDownRow,
        InlineSvg,
    },
    data () { 
        return {
            name: '',
            email: '',
            description: '',
            successful: '',
        };
    },
    mounted() {
        this.$refs.form.addEventListener('submit', this.formListener);
    },
    unmounted() {
        this.$refs.form.removeEventListener('submit', this.formListener);
    },
    methods: {
        formListener($event){
            $event.preventDefault();
        },
        
        async sendEmail(){        
            const data = {
                name: this.name,
                email: this.email,
                description: this.description,
            };

            let res = await api.contact.sendEmail(data);
            this.successful = res.status;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';
.contact-container{
    .text-form{
        margin:0 auto;
        width:80%;
        padding:40px;
        box-sizing: border-box;
        position:relative;
        z-index:2;
        background:$color-gray-light;
        border-radius:15px;
        margin-top:80px;
        margin-bottom:40px;
        @include max-width ($width-large) {
            width:100%;
            margin-top:40px;
            padding:20px;
        }
        .success{
            color:green;
            font-weight:500;
        }
        h2{
            text-align:Center;
            color:var(--link-color-active);
            
            @include max-width ($width-medium) {
                font-size:18px;
            }
        }
        form{
            display:flex;
            flex-direction:column;
            .text-row{
                display:grid;
                grid-template-columns: 1fr 1fr;
                grid-gap:30px;
                margin-bottom:40px;
                input{
                    width:100%;
                }
                @include max-width ($width-small) {
                    grid-template-columns:1fr;
                }
                div{
                    display:flex;
                    flex-direction:Column;
                }
            }
            textarea{
                min-height:300px;

                @include max-width ($width-small) {
                    min-height:100px;
                }
            }
            button{
                margin-top:40px;
                width:150px;
                align-self:center;
            }
        }
    }
    .faq-container{
        text-align:Center;
        position:relative;
        padding-top:40px;
        padding-bottom:80px;
        width:60%;
        margin:0 auto;

        @include max-width ($width-large) {
            width:100%;
            padding-bottom:40px;
        }
        h2{
            color:var(--link-color-active);


            @include max-width ($width-medium) {
                font-size:18px;
                margin-top:0;
            }
        }
        p{
            font-size:16px;

             @include max-width ($width-small) {
                font-size:12px;
            }
        }
        &::before{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 100vw;
            height:100%;
            transform: translateX(-50%);
            background: #f5f5f5;
            z-index: -1;
        }
    }
}
</style>
