import CategoryList from '@/components/CategoryList.vue';
import api from '@/helpers/api';
import { filterEmpty } from '@/helpers/dimensions';

export default function dimensionsMixin (manageSelected, filterEmptyDimensions = true, filterWithQuery = false) {

    const mixin = {
        components: { CategoryList },

        mounted () {
            this.refreshDimensions();
        },

        methods: {
            async refreshDimensions () {
                const response = await api.dimensions.get();
                this.originalDimensions = response.data.map(dimension => {
                    if (dimension.width && dimension.height) {
                        dimension.hoverName = `${dimension.width} x ${dimension.height} ${dimension.unit}`;
                    }
                    return dimension;
                });

                if (filterEmptyDimensions) {
                    this.dimensions = await this.filterDimensions();
                } else {
                    this.dimensions = this.originalDimensions;
                }
            },
    
            onSelectDimension (id) {
                if (manageSelected) {
                    if (this.selectedDimensions[id]) {
                        this.$delete(this.selectedDimensions, id);
                    } else {
                        this.$set(this.selectedDimensions, id, true);
                    }
                }

                this.$emit('select-dimension', id);
            },
    
            findDimension (id) {
                for (const dimension of this.dimensions) {
                    if (dimension._id == id) {
                        return dimension;
                    }
                }
            },

            async filterDimensions () {
                if (filterEmptyDimensions) {
                    return await filterEmpty(this.originalDimensions, filterWithQuery && this.$route.query);
                }
            },
        },

        watch: {
            '$route.query' () {
                this.filterDimensions();
            },
        },
    };

    if (manageSelected) {
        mixin.data = function () { return {
            dimensions: [],
            selectedDimensions: {},
        }; };
    } else {
        mixin.props = [ 'selectedDimensions' ];
        mixin.data = function () { return {
            dimensions: [],
        }; };
    }

    return mixin;
}
