export function generateId (len, prefix = 'id-') {
    const arr = new Uint8Array(len / 2);
    window.crypto.getRandomValues(arr);
    return prefix + Array.from(arr, dec2hex).join('');
}

export function toNameCase (str) {
    if (!str || !str.charAt) { return; }
    const f = str.charAt(0).toUpperCase();
    return f + str.slice(1);
}

function dec2hex (dec) {
    return dec.toString(16).padStart(2, '0');
}

export function arrayAsKeys (arr, value) {
    const obj = {};

    if (arr) {
        for (const key of arr) {
            obj[key] = value;
        }
    }

    return obj;
}

export function defer () {
    let resolve, reject = null;

    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });

    return { promise, resolve, reject };
}

export function capitalizeFirst(string){
    return string && (string[0].toUpperCase() + string.slice(1));
}

export function copyTextFrom ($input) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText($input.value);
    } else {
        $input.focus();
        $input.select();
        document.execCommand('copy');
    }
}

export function awaitImageLoad ($img, url) {
    return new Promise((resolve, reject) => {
        $img.onload = () => resolve();
        $img.onerror = reject;
        $img.src = url;
    });
}

export function date (fn) {
    const date = new Date();
    fn && fn(date);
    return date;
}

export function sleep (ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

export function rgbToHex(rgb){
    const hex = rgb.split('(')[1].split(')')[0].split(',').map(function(value){
        value = parseInt(value).toString(16);
        return (value.length == 1) ? '0' + value : value;
    });
    return '#' + hex.join('');
}

export function hexToRgba(hex){
    const [ r, g, b ] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, 1)`;
}

export function rgbToRgba(rgb){
    rgb = rgb.split('(')[1].split(')')[0].split(',');
    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, 1)`;
}
export function dimensionToPixels (dimension) {
    let width, height;
    switch (dimension.unit) {
        case 'inches':
            width = dimension.width * 72;
            height = dimension.height * 72;
            break;
        case 'centimeters':
            width = dimension.width * 182.88;
            height = dimension.height * 182.88;
            break;
        case 'ratio': {
            const ratio = dimension.width / dimension.height;
            if (ratio > 1) {
                width = 1920;
                height = 1920 * (1 / ratio);
            } else {
                width = 1080;
                height = 1080 * (1 / ratio);
            }
            break;
        }
        default:
            width = dimension.width;
            height = dimension.height;
            break;
    }
	
    return { width, height };
}
