<template>
    <div class="userContainer">
        <div class="main">
            <h4 class="desktopHeader">MY PROFILE</h4>
            <div class="mobileHeader">
                <button v-if="$route.name != 'profile'" @click="setRoute('profile')">
                    <inline-svg :src="require('@/assets/img/backarrow.svg')" class="mobile-icon"/>
                </button>
                <h4>{{ currentPage }}</h4>
            </div>
            <div v-if="user" class="userInfo">
                <div class="userRole">{{ fixRole }}</div>
                <div class="userEmail"> {{ user.email }}</div>
                <hr/>
            </div>
            <div class="desktopNav">
                <nav aria-label='profile nav'>
                    <button 
                        @click="setRoute('profile')" 
                        :class="$route.name === 'profile' ? 'active' : ''">
                        <span>Profile Settings</span>
                    </button>
                    <button @click="setRoute('projects')" :class="$route.name === 'projects' ? 'active' : ''">
                        <span>Projects</span>
                    </button>
                    <button @click="setRoute('purchased')" :class="$route.name === 'purchased' ? 'active' : ''">
                        <span>Purchased Templates</span>
                    </button>
                    <button @click="setRoute('favorites')" :class="$route.name === 'favorites' ? 'active' : ''">
                        <span>Favourites Templates</span>
                    </button>
                    <button @click="setRoute('social')" :class="$route.name.startsWith('social') ? 'active' : ''">
                        <span>Social Posts</span>
                    </button>
                </nav>
                <hr/>
            </div>
            <nav v-if='$route.name == "profile" && isMobile' aria-label='profile nav' class='mobileNav'>
                <button @click="setRoute('profileSettings')" :class="$route.name === 'profileSettings' ? 'active' : ''">
                    <inline-svg :src="require('@/assets/img/Settings.svg')" class="mobile-icon"/>
                    <span>Profile Settings</span>
                </button>
                <button @click="setRoute('projects')" :class="$route.name === 'projects' ? 'active' : ''">
                    <inline-svg :src="require('@/assets/img/Projects.svg')" class="mobile-icon"/>
                    <span>Projects</span>
                </button>
                <button @click="setRoute('purchased')" :class="$route.name === 'purchased' ? 'active' : ''">
                    <inline-svg :src="require('@/assets/img/Purchased.svg')" class="mobile-icon"/>
                    <span>Purchased Templates</span>
                </button>
                <button @click="setRoute('favorites')" :class="$route.name === 'favorites' ? 'active' : ''">
                    <inline-svg :src="require('@/assets/img/FavoritesProfile.svg')" class="mobile-icon"/>
                    <span>Favourites Templates</span>
                </button>
                <button @click="setRoute('social')" :class="$route.name.startsWith('social') ? 'active' : ''">
                    <inline-svg :src="require('@/assets/img/Calendar.svg')" class="mobile-icon"/>
                    <span>Social Posts</span>
                </button>
                <LogoutButton id="logout" :disableSVG="false" />
            </nav>
            <router-view v-if='!isMobile' id="desktopView" :user="user" />
        </div>
        <div class="mobileMain" v-if="$route.name != 'profile'">
            <router-view v-if='isMobile' :user="user" />
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import AppButton from '@/components/AppButton.vue';
import LogoutButton from '@/components/LogoutButton.vue';
import api from '@/helpers/api';
import resizeMixin from '@/mixins/resize';
import userMixin from '@/mixins/user';

export default {
    components: {
        AppButton,
        InlineSvg,
        LogoutButton,
    },
    mixins: [ userMixin, resizeMixin ],

    computed: {
        currentPage(){
            switch(this.$route.name){
                case 'profileSettings' : return 'Profile Settings';
                case 'projects' : return 'Projects';
                case 'purchased' : return 'Purchased Templates';
                case 'favorites' : return 'Favorites Templates';
                case 'social' : return 'Social Posts';
                default:
                    return 'My Profile';
            }
        },
        fixRole(){
            if(this.user.role == 'premium'){
                return 'Premium User';
            }else if(this.user.role == 'admin'){
                return 'Admin';
            }
            return 'Free User';
        },
        isMobile(){
            if(this.windowWidth <= 900){
                return true;
            }else{
                return false;
            }
        },
    },
    data: function(){
        return{
            selected: false,
        };
    },
    async mounted(){
        await this.ensureUser();
        if(!this.user){
            this.$toast.error('Please log in!');
            this.$router.push('/');
        }
    },

    methods: {
        goBack(){
            this.$router.go(-1);
            if (this.$route.name == 'profile' && this.selected) {
                this.selected = false;
            }
        },
        setRoute(route = 'profile'){
            if (route != 'profile') {
                this.$router.push(route);
            } else {
                this.selected = !this.selected;
                this.$router.push(route);
            }
        },
    },
};
</script>

<style lang='scss' scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

    .userContainer{
        padding:0 154px;
        padding-top:52px;

        @include max-width ($width-large) {
            padding:0 74px;
        }
        @include max-width ($width-medium) {
            padding:0;
        }

        .desktopHeader{
            @include max-width ($width-medium) {
                display:none;
            }
        }
        .mobileHeader{
            display:none;
            @include max-width ($width-medium) {
                display:flex;
                flex-direction:row;
                align-items:center;
            }
            button{
                margin:0;
                padding:0;
                padding-top:5px;
                text-align:left;
                width:25px;
            }
            h4{
                margin:10px 0;
            }
        }
        .userInfo{
            display:none;
            @include max-width ($width-medium) {
                display:block;
            }
        }
        #desktopView, .desktopNav{
            @include max-width ($width-medium) {
                display:none;
            }
        }

        .mobileMain{
            display:none;

            @include max-width ($width-medium) {
                display:unset;
            }
        }
        nav{
            display:flex;
            width:100%;
            @include max-width ($width-medium) {
                flex-direction:column;
            }
            #logout{
                display:none;
            }
            button, #logout{
                margin-right:16px;
                font-size:14px;
                padding:10px;
                @include max-width($width-large){
                    padding:5px;
                    font-size:11px;
                    min-width:100px;
                }
                @include max-width ($width-medium) {
                    align-items:center;
                    margin-bottom:10px;
                    padding:0;
                    height:unset;
                    width:100%;
                    display:grid;
                    grid-template-columns:30px 1fr;
                    text-align:left;
                }
            }
            &.mobileNav{
                display:none;
                @include max-width ($width-medium){
                    display:flex;
                }
            }
        }
        hr{
            margin:28px 0;
            background: #C4C4C4;
            border-radius: 2px;
            border-width: 2px;
                @include max-width ($width-medium) {
                    margin:14px 0;
                }
        }

        button{
            color:#666666;
            background:#EEEEEE;
            border:transparent;
            @include max-width ($width-medium) {
                background:unset;
                color:unset;
            }
            &.active{
                pointer-events:none;
                @include max-width ($width-medium) {
                    pointer-events:unset;
                }
            }
            &:hover, &.active, &.connected, &.inactive{
                background:var(--link-color);
                color:white;
                 @include max-width ($width-medium) {
                     background:unset;
                     color:unset;
                }
            }

            &.inactive{
                cursor:not-allowed;
                opacity:0.5;
            }
        }
    }
</style>
