<template>
    <div class="registerContainer">
        <h3>SIGN UP</h3>
        <p class="signup" title="Submit">
            Already have an account?
            <a @click="$emit('open-login')">Log in</a>
        </p>

        <label>
            <img src="@/assets/img/User.svg" />
            <span>Email</span>
            <span v-if="email !== '' && !emailValid" class="error">&nbsp;- Email invalid or taken</span>
        </label>
        <input :class="emailStatus" v-model="email" type="email" placeholder="Email" />

        <label>
            <img src="@/assets/img/User.svg" /> 
            <span>Name</span>
            <span v-if="nameStatus !== '' && !nameValid" class="error">&nbsp;- Name cannot be empty</span>
        </label>
        <div class="userRow"><input :class="nameStatus" v-model="name" type="text" placeholder="Name" /></div>

        <label>
            <img src="@/assets/img/Lock.svg" /> 
            <span>Password</span>
            <span v-if="passwordStatus !== '' && !passwordValid" class="error">
                &nbsp;- Password length must be at least 8 characters long
            </span>
        </label>
        <div class="input-row">
            <input :class="passwordStatus" v-model="password" type="password" placeholder="Password" />
            <!-- <button class="reveal"><img src="@/assets/img/Eye.svg" /></button> -->
        </div>

        <label>
            <img src="@/assets/img/Lock.svg" />
            <span>Confirm Password</span>
            <span v-if="confirmPass !== '' && !passwordConfirmed" class="error">&nbsp;- Passwords must match</span>
        </label>
        <div class="input-row">
            <input :class="confirmStatus" v-model="confirmPass" type="password" placeholder="Confirm Password" />
            <!-- <button class="reveal"><img src="@/assets/img/Eye.svg" /></button> -->
        </div>

        <p class="terms">
            By signing up, you agree to our
            <a href="/terms" target="_blank">Terms &amp; Conditions</a> and
            <a href="/privacy" target="_blank">Privacy Policy</a>.
        </p>

        <button :disabled="!emailValid || !nameValid || !passwordValid || !passwordConfirmed" @click="submitForm">
            Sign Up
        </button>
    </div>
</template>

<script>
import api from '@/helpers/api';
import userMixin from '@/mixins/user';

export default {
    name: 'Register',

    mixins: [ userMixin ],

    data() {
        return {
            submitted: false,
            error: false,
            confirmpassError: false,
            email: '',
            name: '',
            password: '',
            confirmPass: '',
            emailStatus:'',
            nameStatus:'',
            passwordStatus:'',
            confirmStatus:'',
            emailValid:false,
            nameValid:false,
            passwordValid:false,
            passwordConfirmed:false,
        };
    },
    props: [ 'callback', 'openLogin' ],
    methods: {
        loginPage() {
            this.$router.push('login');
        },

        async submitForm () {
            const { email, name, password, confirmPass } = this;

            if (email != '' && password != '' && name != '' && password === confirmPass) {
                this.error = false;
                this.confirmpassError = false;
                this.submitted = true;
                await api.auth.register({ email, name, password });
                this.$emit('login-closed');
                this.refreshUser();
            } else if (password !== this.confirmpass) {
                this.confirmpassError = true;
            } else {
                this.error = true;
            }
        },
    },
    watch:{
        async email(){
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(this.email !== ''){
                if(re.test(this.email)){
                    this.emailValid = true;
                    this.emailStatus = 'valid';
                }else{
                    this.emailValid = false;
                    this.emailStatus = 'invalid';
                }
            }else{
                this.emailValid = false;
                this.emailStatus = '';
            } 
        },
        name(){
            if(this.name !== ''){
                this.nameStatus = 'valid';
                this.nameValid = true;
            }else{
                this.nameStatus = 'invalid';
                this.nameValid = false;
            }
        },
        password(){
            if(this.password.length >= 8){
                this.passwordStatus = 'valid';
                this.passwordValid = true;
            }else{
                this.passwordStatus = 'invalid';
                this.passwordValid = false;
            }
        },
        confirmPass(){
            if(this.password === this.confirmPass){
                this.confirmStatus = 'valid';
                this.passwordConfirmed = true;
            }else{
                this.confirmStatus = 'invalid';
                this.passwordConfirmed = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.registerContainer {
    position: relative;

    .error {
        font-size: 11px;
        color: red;
    }

    .terms {
        margin: 8px 0 16px;
        font-size: 12px;
    }
}
</style>
