<template>
    <div class="tile-container">
        <div class="img-container">
            <slot name="image">
            </slot>
            <div class="icon-container">
                <slot name="smallBtn">
                </slot>
            </div>
        </div>
        <slot name="cta"></slot>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';
    
    .tile-container{
        display:flex;
        flex-direction:column;
        justify-items: flex-start;
        align-items:flex-start;
        width:auto;
        padding:0 10px;
        box-sizing: border-box;
        flex-wrap:nowrap;
        margin-bottom:40px;
        
        a{
            align-self:flex-start;
        }
        .img-container{
            position:relative;

            @include max-width ($width-medium) {
                max-width:100%;
                align-items:center;
            }
            img{
                max-height:245px;
                max-width:100%;
            }
            .icon-container{
                opacity:0;
                position:absolute;
                top:5%;
                right:5%;
                display:flex;
                justify-content: center;
                align-items:center;
                background:#EEEEEE;
                border-radius:100%;
                width:30px;
                height:30px;
                transition:0.25s ease-in-out;
                cursor:pointer;

                button{
                    display:flex;
                    position:absolute;
                    top:0;
                    left:0;
                    height:100%;
                    width:100%;
                    border-radius:100%;
                    padding:0;
                    background:unset;
                    border:unset;
                    transition:0.25s ease-in-out;

                    svg{
                        path{
                            fill:var(--link-color-active);
                            transition:0.25s ease-in-out;
                        }
                    }
                    &:hover{
                        background:var(--link-color-active);
                        svg{
                            path{
                                fill:$color-gray-light;
                            }
                        }
                    }
                }
                
            }
        }

        button, .button {
            margin:0 auto;
            width:unset;
        }
        .button{
            margin-top:10px;
        }

        &:hover{
            .icon-container{
                opacity:1;
            }
        }
    }
</style>

<style lang="scss">
@import '@/assets/css/colors';
@import '@/assets/css/utils';

    .tile-container{
        .img-container{
            .icon-container{
                button{
                    svg{
                        margin:0 auto;
                        justify-self:center;
                        align-self:center;
                        path{
                            fill:var(--link-color-active);
                            transition:0.25s ease-in-out;
                        }
                    }
                    &:hover{
                        background:var(--link-color-active);
                        svg{
                            path{
                                fill:$color-gray-light;
                            }
                        }
                    }
                }
                
            }
        }
    }
</style>
