<template>
    <visibility-container v-click-outside="hide" ref="container">
        <vue-color v-model="color" :preset-colors="colorHistory"></vue-color>
    </visibility-container>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import VueColor from 'vue-color/src/components/Sketch';

import VisibilityContainer from './VisibilityContainer.vue';

export default {
    components: { VisibilityContainer, VueColor },
    directives: { ClickOutside },
    props: { 
        initialColor: String,
        colorHistory: Array, 
    },
    data: function () { 
        return {
            color: this.initialColor,
        }; 
    },
    methods: {
        show () {
            this.$refs.container.show();
        },

        hide () {
            if (this.color !== this.initialColor) {
                const color = this.color.rgba;
                this.$emit('update', `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
                this.$emit('handle-color', `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
            } else {
                this.$emit('update', this.color);
            }
            this.$emit('hide');
        },
    },
    watch: {
        color(){
            if (this.color !== this.initialColor) {
                const color = this.color.rgba;
                this.$emit('update', `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
            } else {
                this.$emit('update', this.color);
            }
        },
    },
};
</script>

<style>

</style>
