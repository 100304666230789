<template>
    <div class="tileContainer" ref="tileContainer">
    </div>
</template>

<script>

export default {
    props:[ 'svgContainer', 'index', 'activeSlideIndex' ],
    computed:{
    },
    
    mounted:function(){
        this.$refs.tileContainer.appendChild(document.querySelectorAll('.svg-container')[this.index].cloneNode(true));
    },

    watch:{
        svgContainer(){
            if(this.$refs.tileContainer.childNodes.length > 0){
                this.$refs.tileContainer.removeChild(this.$refs.tileContainer.childNodes[0]);
            }
            this.$refs.tileContainer.appendChild(document.querySelectorAll('.svg-container')[this.index].cloneNode(true));
        },
    },
};
</script>

<style lang="scss">
    .tileContainer{
        display:flex;
        flex-grow:1;
        width:100%;
        height:100%;
        overflow:hidden;
        justify-content:center;
        align-items:center;      

        .svg-container{
            width: 80%;
            height: 80%;
            display:flex;
            flex-grow:1;
            align-items: center;
            justify-content: center;
            align-content: center;

            svg{
                height:100%;
                width:100%;
            }
        }
    }
</style>
