export function fixColor($el){
    if($el.nodeName == 'text'){
        for(let $child of $el.children){
            if($child.hasAttribute('stroke')){
                $child.removeAttribute('stroke');
            }
            if($child.hasAttribute('fill')){
                $child.removeAttribute('fill');
            }
        }
    }
}

export default {
    methods: { fixColor },
};
