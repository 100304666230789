<template>
    <div class="forgot-page">
        <h3>FORGOT PASSWORD?</h3>
        <p class="signup" title="Submit">
            Back to
            <a @click="$emit('open-login')">Log in</a>
        </p>
        <p class="instructions">Please enter your email address to reset your password.</p>
        <label><img src="@/assets/img/User.svg" /> Email</label>
        <input
            v-model="email"
            ref="username"
            type="text"
            name="email"
            placeholder="Email"
        />
        <button @click="onSend">Send Email</button>
    </div>
</template>

<script>
import api from '@/helpers/api';

export default {
    data () { return {
        email: '',
    }; },

    methods: {
        async onSend () {
            try {
                await api.auth.passwordReset(this.email);
                this.$toast('Please check your inbox for an email!', { timeout: false });
            } catch (e) {
                this.$toast.error('Could not send a password reset email!', { timeout: false });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.forgot-page {

    .instructions {
        margin: 0 0 8px;
    }

    button {
        margin: 24px 0 0;
    }

        .lowerContainer {
            display: grid;
            grid-template-rows: repeat(6, auto) 1fr;
            height: 100%;
            max-width: 100%;

            label {
                display: flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }
            }

            input {
                display: block;
            }
            .userRow {
                position: relative;
                margin-top: 10px;
                margin-bottom: 10px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 40px;
                margin-top: 20px;
                margin-bottom: 30px;
                input {
                    margin: 20;
                    border: 2px solid black;
                }
                .revealPassword {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    height: 20px;
                    width: 40px;
                    background: unset;
                    border: unset;
                    cursor: pointer;
                    img {
                        height: 100%;
                    }
                }
            }
            button {
                width: 100%;
                height: 40px;
            }
            .signup {
                margin-top: 15%;
            }
            .terms {
                display: grid;
                grid-template-columns: 1fr;
                align-items: center;
                font-size: 14px;
                height: 46px;

                .remember {
                    display: flex;
                    align-items: center;
                }

                .forgot {
                    text-align: right;
                    margin: 10px 0;
                }
            }

            hr {
                width: 100%;
                margin-top: 20px;
                border: 2px solid #eeeeee;
                box-sizing: border-box;
                border-radius: 6px;
            }
        }
        .orContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #7b7b7b;
            margin: 0;
            margin-bottom: 10px;

            &:before,
            &:after {
                flex: 1 1;
                content: '';
                border-bottom: 2px solid #7b7b7b;
            }
            &:before {
                margin-right: 10px;
            }
            &:after {
                margin-left: 10px;
            }
        }
    }
    .submitContainer {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 75px 75px 75px 1fr;
        grid-gap: 10px;
    }
    .infoContainer {
        .error {
            color: red;
        }
        .success {
            color: green;
        }
}
</style>
