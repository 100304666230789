export default {
    data () { return {
        windowWidth: window.innerWidth,
    }; },
    mounted () {
        window.addEventListener('resize', this.onResize);
    },
    unmounted () {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize () {
            this.windowWidth = window.innerWidth;
        },
    },
};
