<template>
    <popup title="Collaborate" @close="$emit('close-collab')">
        <div>Choose the type of sharing for users</div>
        <div class="buttonContainer">
            <button @click="byEmail" :class="isEmail ? '' : 'button-inverted'">By email</button>
            <button @click="byLink" :class="!isEmail ? '' : 'button-inverted'">By link</button>
        </div>
        <hr>
        <div v-if="isEmail" class="email-container">
            <div class="email-instruction">Enter the email of the registered user that you want to share this project with</div>
            <div v-if="userExists" class="email-error"><span>User already added</span></div>
            <div v-if="!emailValid && email !== ''">{{ emailStatus }}</div>
            <div class="input-row">
                <input @keyup.enter="addUser" v-model="email" placeholder="Email">
                <select v-model="emailRole">
                    <option value="editor">Editor</option>
                    <option value="viewer">Viewer</option>
                </select>
            </div>
            <div v-if="user.email" class="emails">
                <div class="email-row">
                    <div class="owner">
                        <span>{{user.email}} (You)</span>
                        <h3>Owner</h3>
                    </div>
                </div>
                <div class="email-row" v-for="(oldUser, index) of oldUsers" :key="oldUser.email">
                    <button @click="removeUser(false, index)">
                        <span>{{oldUser.name}}</span>
                        <span class="email-row">{{ capitalizeFirst(oldUser.role) }}</span>
                    </button>
                </div>
                <div class="email-row" v-for="(newUser, index) of newUsers" :key="newUser.email">
                    <button @click="removeUser(true, index)">
                        <span>{{newUser.email}} (New)</span>
                        <span class="email-row">{{ capitalizeFirst(newUser.role) }}</span>
                    </button>
                </div>
            </div>
            <div v-if="newUsers.length > 0" class="email-row">
                <button @click="confirmUsers">Add Users</button>
            </div>
        </div>
       
        <div v-else class="link-container">
            <div class="input-row">
                <div class="link-instruction"> 
                    You can allow other users to view or edit this project.
                    <br /> 
                    Select role and generate the url
                </div>
                <select v-model="linkRole">
                    <option value="editor">Editor</option>
                    <option value="viewer">Viewer</option>
                </select>
            </div>
            <div v-if="shareKey.length > 0" class="links">
                <div class="link-row" v-for="(data, index) of shareKey" :key='index'>
                    <div class="link-container">
                        <input 
                            class="url-btn" 
                            @click="copyLink(index)" 
                            :value="getLink(data.key)" 
                            type="text" 
                            ref="url"
                        >
                        <span>{{ capitalizeFirst(data.role) }}</span>
                        <button @click="copyLink(index)">Copy Link</button>
                        <button @click="removeKey(index)">Remove Link</button>
                    </div>
                </div>
            </div>
            <button @click="generateLink">Generate Url</button>
        </div>
    </popup>
</template>

<script>


import AppButton from '@/components/AppButton.vue';
import Popup from '@/components/Popup.vue';
import RadioPicker from '@/components/RadioPicker.vue';
import api from '@/helpers/api';
import { capitalizeFirst, copyTextFrom } from '@/helpers/utils';

export default {
    components: { AppButton, Popup, RadioPicker },
    props: [ 'userID', 'shareKey', 'shareWith', '_id' ],
    data () { return {
        isEmail: true,
        user: '',
        oldUsers: [],
        newUsers: [],
        email: '',
        emailRole: 'editor',
        userExists: false,
        emailValid: false,
        emailStatus: '',
        linkRole: 'editor',
        shareUrl: '',
    }; },
    async mounted(){
        const userData = await api.users.get(this.userID);
        this.user = userData.data;
        this.getOldUsers();
    },
    methods:{
        capitalizeFirst,

        async getOldUsers(){
            this.oldUsers = [];
            for(let user of this.shareWith){
                const foundUser = await api.users.get(user.id);
                user.name = foundUser.data.name;
                this.oldUsers.push({name: user.name, role: user.role, id: user.id});
            }
        },

        byEmail(){
            this.isEmail = true;
        },
        byLink(){
            this.isEmail = false;
        },

        generateLink(){
            this.$emit('generate-link', {id: this._id, role: this.linkRole});
        },

        getLink (shareKey) {
            const templateId = this.$route.params.id;
            const projectId = this.$route.params.projectId;

            return window.location.origin + `/template/${templateId}/edit/project/${projectId}/` + shareKey;
        },

        copyLink (index) {
            copyTextFrom(this.$refs.url[index]);
        },

        async addUser(){
            //Check if email exists / is valid
            if(this.email === this.user.email){
                this.emailValid = false;
                this.emailStatus = 'Email already exists';
            }
            for(let user of this.oldUsers){
                if(user.email === this.email){
                    this.emailValid = false;
                    this.emailStatus = 'Email already exists';
                }
            }
            for(let user of this.newUsers){
                if(user.email === this.email){
                    this.emailValid = false;
                    this.emailStatus = 'Email already exists';
                }
            }

            if(this.emailValid){
                for(let newUser of this.newUsers){
                    if(newUser.email === this.email){
                        this.userExists = true;
                        return;
                    }
                }

                if(!this.newUsers.includes(this.email)){
                    this.newUsers.push( { email: this.email, role: this.emailRole } );
                }
                this.userExists = false;
            }else{
                if(this.emailStatus === ''){
                    this.emailStatus = 'Invalid Email';
                }
            }
        },

        removeUser(isNew, index){
            if(isNew){
                this.newUsers.splice(index, 1);
            }else{
                this.$emit('remove-user', this.oldUsers.splice(index, 1)[0]);
            }
        },

        removeKey(index){
            this.$emit('remove-key', {index: index});
        },

        confirmUsers(){
            for(let newUser of this.newUsers){
                this.$emit('confirm-users', {id: this._id, newUser: newUser});
            }
            this.newUsers = [];
        },
    },
    watch:{
        async email(){
            this.emailStatus = '';
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(this.email !== ''){
                if(re.test(this.email)){
                    this.emailValid = true;
                }else{
                    this.emailValid = false;
                }
            }else{
                this.emailValid = false;
            } 
        },

        shareWith(){
            this.getOldUsers();
        },

    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

.buttonContainer{
    display:flex;

    button{
        margin:16px;
        margin-left:0;
    }
}
select{
    min-height:40px;
    margin-left:8px;
    border:1px solid;
    box-sizing: border-box;
    border-radius:6px;
    border-color:$color-gray;
}
.link-container{
    .input-row{
        display:grid;
        min-height:40px;
        grid-template-columns:1fr auto;
        margin-top:16px;
        align-items:center;

        .link-instruction{
            padding-right:16px;

            @include max-width($width-large){
                font-size:0.9rem;
            }
        }
    }
    button{
        margin-top:16px;
        width:100%;
    }
}
.email-container, .link-container{

    .email-instruction{
        margin-bottom:22px;
        font-size:1rem;

        @include max-width($width-large){
            margin-top:15px;
            font-size:0.80rem;
        }
    }
    .email-error{
        color:red;
        font-size:12px;
    }
    .input-row{
        display:grid;
        grid-template-columns:1fr auto;
        width:100%;

        
    }
    .links{
        max-height:250px;
        overflow:auto;
        margin-top:16px;
        margin-bottom:16px;
        border:2px solid $color-gray;
        padding:20px;

    .link-row{
        position:relative;
        margin-bottom:16px;
        padding-right:20px;
        height:40px;
        width:auto;

        .link-container{
            position:absolute;
            top:0;
            left:0;
            display:flex;
            flex-direction:row;
            gap:16px;
            align-items:center;
            padding-right:16px;

            h3, .link-type{
                text-align:right;
                margin:0;
            }
            button, span{
                font-size:10px;
                margin:0;
                white-space:nowrap;
            }
            .link-type{
                font-size:16px;
            }
        }
    }
}

    .emails{
        max-height:250px;
        overflow-y:auto;
        margin-top:16px;
        padding:10px;
        margin-bottom:16px;
        border:2px solid $color-gray;

        .email-row{
            margin-bottom:16px;
            h3, .email-row{
                text-align:right;
                margin:0;
            }
            .email-row{
                font-size:16px;
            }
            .owner, button{
                display:Grid;
                grid-template-columns:auto 1fr;
                align-items:center;
            }
            button{
                border:unset;
                background:unset;
                width:unset;
                color:unset;
                padding:unset;
                margin:unset;
                font-weight:unset;
                width:100%;
                height:unset;
                
                &:hover{
                    color: var(--link-color-active);
                }
            }
        }
    }
}

</style>
