<template>
    <div id="app">
        <app-nav
            v-if="!$route.meta.fullscreen"
            @login-clicked="loggingIn = true"
            @logout-clicked="$router.push('/logout')"
        />
        <main :class="[{ content: !$route.meta.fullscreen }, $route.name == 'Home' ? 'gray' : '']">
            <router-view 
                @login-clicked="loggingIn = true"
                @logout-clicked="$router.push('/logout')"
            />
        </main>
        <app-footer v-if="!$route.meta.fullscreen" />
        <auth-page v-if="loggingIn" @login-closed="loggingIn = false" />
        <report-bug />
    </div>
</template>

<script>
import AppFooter from '@/AppFooter.vue';
import AppNav from '@/AppNav.vue';
import ReportBug from '@/components/ReportBug.vue';
import AuthPage from '@/views/auth/AuthPage.vue';

export default {
    name: 'App',
    components: { AppFooter, AppNav, AuthPage, ReportBug },
    data () { return {
        loggingIn: false,
    }; },
};
</script>

<style lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

#app {
    width:100%;
    height:100%;
    min-height: 100%;
    display:flex;
    flex-direction:column;

    

    main{
        flex-grow:1;
        margin:0 auto;
        
        &.content{
            @include max-width($width-small){
                padding:0 16px;
                box-sizing:border-box;
                width:100%;
                max-width:unset;
            }
        }

        &.gray{
            background: var(--background-color);
            width:100vw;
            max-width:unset;
        }
    }
}
</style>
