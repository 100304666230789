<template>
    <button>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'AppButton',
};
</script>

<style scoped lang="scss">
@import '@/assets/css/colors';

button {
    border: unset;
    padding: 10px 24px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    min-width: 133px;
    height: 40px;
    background: var(--link-color);
    border:2px solid transparent;
    border-radius: 6px;
    cursor: pointer;

    &.error{
        opacity:0.5;
        pointer-events:none;
        cursor:not-allowed;
    }
}
</style>
