<template>
    <nav>
        <div class="desktopNav nav-container">
            <router-link to="/" class="logo">
                <img :src="$tenant ? $tenant.logo : require('@/assets/img/squareLogo.png')" />
            </router-link>
            <div class="login-container">
                <!-- <a v-if="authenticated" @click="$emit('logout-clicked')" class="loginButton">Logout</a> -->
                <router-link v-if="userRole == 'admin'" to="/template/create">New Template</router-link>
                <router-link class="label navMenu" to="/template/catalog" :class="$route.name == '/template/catalog' ? 'active' : ''">
                    Templates
                </router-link>
                <router-link class="label navMenu" to="/how-it-works" :class="$route.name == 'How it works' ? 'active' : ''">
                    How it works
                </router-link>
                <router-link v-if="($tenant && $tenant.showPricing) || (!$tenant)" class="label navMenu" to="/plans" :class="$route.name == 'Plans' ? 'active' : ''">
                    Pricing
                </router-link>
                <a v-if="authenticated" @click="openProfile" class='login-button'>My Profile</a>
                <a v-else-if="$route.name != 'Home' && $tenant && $tenant.homeName =='Login'" @click="$emit('login-clicked')" class="login-button">Login</a>
                <a v-else-if="!$tenant" @click="$emit('login-clicked')" class="login-button">Login</a>
            </div>
        </div>
        <div ref="mobileNav" class="mobileNav content">
            <router-link to="/" class="logo" @click.native="closeMenu">    
                <img :src="$tenant ? $tenant.logo : require('@/assets/img/squareLogo.png')" />
            </router-link>
            <button>
                <a v-if="authenticated" @click="openProfile" class='loginButton'>
                    <inline-svg :src="require('@/assets/img/User.svg')"/>
                </a>
                <a v-else @click="$emit('login-clicked')" class="loginButton">
                    <inline-svg :src="require('@/assets/img/User.svg')"/>
                </a>
            </button>
            <button @click="()=>{ this.menuActive = !this.menuActive }">
                <inline-svg :src="require('@/assets/img/burger.svg')"/>
            </button>
            <div class="mobile-menu" :class="menuActive ? '' : 'closed'">
                <mobile-menu-row-nav 
                    @close-menu="closeMenu"
                    class="menu-row" 
                    :media="'social'" 
                    :category="'Social Media'" 
                    :categories="socialCategories"
                />
                <div class="menu-row">
                    <router-link @click.native="closeMenu" class="label" to="/how-it-works">
                        How it works
                    </router-link>
                    <div class="menu"></div>
                </div>
                <div v-if="($tenant && $tenant.showPricing) || (!$tenant)" class="menu-row">
                    <router-link class="label navMenu" to="/plans" :class="$route.name == 'Plans' ? 'active' : ''">
                        Pricing
                    </router-link>
                    <div class="menu"></div>
                </div>
                <div class="menu-row">
                    <router-link @click.native="closeMenu" class="label" to="/contact">
                        Contact
                    </router-link>
                    <div class="menu"></div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import MobileMenuRowNav from '@/components/MobileMenuRowNav.vue';
import api from '@/helpers/api';
import { filterEmpty } from '@/helpers/categories';
import userMixin from '@/mixins/user';

export default {
    name: 'AppNav',

    components: {
        InlineSvg,
        MobileMenuRowNav,
    },

    mixins: [ userMixin ],

    data() {
        return {
            categories: [],
            socialCategories: [],
            printCategories: [],
            menuActive: false,
            search: '',
            searchActive: false,
        };
    },

    mounted() {
        if(this.$route.query.q){
            this.search = this.$route.query.q;
        }
        this.refresh();
    },

    methods: {
        clearSearch(){
            this.search = '';
        },

        toggleSearch(){
            this.menuActive = false;
            this.searchActive = !this.searchActive;
        },
        closeMenu(){
            this.menuActive = false;
        },

        menuClicked(){
            this.menuActive = !this.menuActive;
        },

        openProfile(){
            if(this.$route.name !== 'profile'){
                this.$router.push({name: 'profile'});
            }
        },
        async pushSearch() {
            this.searchActive = false;
            this.menuActive = false;
            this.$router.push('/template/catalog?q=' + this.search);
        },

        async refresh() {
            this.categories = (await api.categories.get()).data;
            this.socialCategories = await filterEmpty(this.categories, { media: 'social' });
            this.printCategories = await filterEmpty(this.categories, { media: 'print' });
        },
    },

    watch: {
        menuActive(){
            if(this.menuActive){
                document.body.style.overflow = 'hidden';
            }else{
                document.body.style.overflow = 'auto';
            }
        },

        '$route.query' () {
            if(this.$route.query.q){
                this.search = this.$route.query.q;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/colors';

nav{
    height:200px;
    background:var(--background-color);

    @include max-width($width-large){
        height:100px;
    }
    .nav-container{
        margin:0 auto;
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 0 100px;
        background:var(--background-color);
        max-width:1600px;
        @include max-width($width-medium){
            display:none;
        }

        a{
            display:block;
            flex-grow: 1;
            height:100%;
            font-weight: 400;
            font-style: normal;
            text-transform: uppercase;
            text-decoration: none;
            color: rgb(0, 0, 0);

            @include max-width($width-large){
                font-size:0.7rem;
            }

            img{
                height:100%;
            }

            &:hover{
                color:var(--link-color-active);
            }
        }

        .login-container{
            display:flex;
            flex-direction:row;
            gap:15px;
            align-items:center;

            a{
                height:unset;
            }

            .login-button{
                display:block;
                color:var(--link-color-active);
                border:2px solid var(--link-color-active);
                border-radius:20px;
                padding: 5px 15px;
                font-weight:800;
            }
        }
    }
    .mobileNav{
        display:none;
        @include max-width($width-medium){
            position:relative;
            display:flex;
            justify-content:flex-end;
            align-items:center;
            height:80px;
            width:100%;
            padding:0 48px;
            max-width:unset;
            z-index:4;
            box-sizing: border-box;
            background:var(--background-color);
            border-bottom: 2px solid $color-gray;

        }
        @include max-width($width-small){
            padding:0 10px;
            width:100%;
            margin:unset;
        }
        .logo{
            flex-grow:1;
            padding: 10px;
            height: 100%;

            img{
                height:100%;
            }
        }
        .burger{
            &.active{
                path{
                    fill:var(--link-color-active);
                }
            }
        }
        button{
            background:unset;
            border:unset;
            padding:10px;
        }
        .mobile-menu{
            position:absolute;
            align-items:center;
            flex-direction:column;
            background:var(--background-color);
            height:calc(100vh - 80px);
            width:100%;
            top:100%;
            z-index:5;
            overflow:auto;
            top:80px;
            left:0;
            
            &.closed{
                display:none;
            }
            
            .menu-row{
                width:100%;
                min-height:40px;

                .label{
                    text-decoration:underline;
                    font-size: 14px;
                    font-weight: 500;
                    &.active{
                        color:var(--link-color-active);
                    }
                }
                button, a{
                    font-size:14px;
                    display:flex;
                    background:unset;
                    border:unset;
                    padding:10px;
                    color:black;
                    width:100%;
                    box-sizing:border-box;
                    border: 1px solid #C4C4C4;
                    border-radius:0;
                    
                    .menu-button{
                        flex-grow:1;
                        display:flex;
                        background:unset;
                        border:unset;
                        padding:0;
                        margin:0;
                        color:black;
                        height:100%;

                        &.open{
                            color:var(--link-color-active);
                        }
                    }
                    svg{
                        justify-self:flex-end;
                        align-self:center;
                    }
                }
            }
        }
    }
}
</style>
