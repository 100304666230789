import mixpanel from 'mixpanel-browser';

const token = process.env.VUE_APP_MIXPANEL_TOKEN;

if (token) {
    mixpanel.init(token, { debug: process.env.NODE_ENV == 'development' });
}

const track = (name, data) => {
    if (token) {
        mixpanel.track(name, data);
    }
};

export default {
    templates: {
        open: ({ id, name, project }) => track('Template Open', { templateId: id, templateName: name, project }),
        save: ({ id, name, existing }) => track('Template Save', { templateId: id, templateName: name, existing }),
        download: ({ id, name, format }) => track('Template Download', { templateId: id, name, format }),
        post: ({ id, name, platforms, date }) => track('Template Post', { templateId: id, name, platforms, date }),
    },
};
