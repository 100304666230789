const weights = {
    'thin': 100,
    'extra-light': 200,
    'extralight': 200,
    'light': 300,
    'normal': 400,
    'regular': 400,
    'reg': 400,
    'medium': 500,
    'semi-bold': 600,
    'semibold': 600,
    'bold': 700,
    'extra-bold': 800,
    'extrabold': 800,
    'black': 900,
};

const resolvedFamilies = {};

export async function parseFont ($el) {

    const font = {};

    font.family = $el.getAttribute('font-family');
    font.weight = $el.getAttribute('font-weight');
    font.style = $el.getAttribute('font-style');

    const split = font.family.split('-');
    const adobeFamily = split[0];
    const adobeWeightStyle = split[1] && split[1].toLowerCase();

    // Try to grab font weight and style from adobe font family
    if (adobeWeightStyle) {
        for (const weight in weights) {
            if (adobeWeightStyle.includes(weight)) {
                font.weight = weights[weight];
                break;
            }
        }

        if (adobeWeightStyle.includes('italic')) {
            font.style = 'italic';
        }
    }

    // Try to parse out a number from font weight if it's in string form right now
    if (typeof font.weight == 'string') {
        font.weight = parseInt(font.weight) || font.weight;
    }

    // Fall back to normal font weight
    font.weight = font.weight || 400;

    // We only support italic font style
    if (font.style != 'italic') {
        font.style = '';
    }

    // Try to resolve & fix font family name
    if (resolvedFamilies[font.family]) {
        font.family = resolvedFamilies[font.family];
    } else {
        // 'PinyonScript' => PinyonScript
        const withoutQuotes = adobeFamily.replace(/['"]+/g, '');

        // PinyonScript => Pinyon Script
        const respaced = withoutQuotes.match(/[A-Z][a-z]+/g).join(' ');

        // IBMPlexSerif => IBM Plex Serif
        const respaced2 = withoutQuotes.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

        // All potential font families
        const potentialFamilies = [ respaced2, respaced, withoutQuotes, adobeFamily, font.family ];

        let foundFont = false;

        for (const potential of potentialFamilies) {
            try {
                await downloadCss({ family: potential, weight: font.weight, style: font.style });
                font.family = potential;
                foundFont = true;
                break;
            } catch (e) {
                continue;
            }
        }

        if (!foundFont) {
            throw new Error('Could not find font on Google Fonts: ' + font.family);
        }
    }

    // Update HTML
    $el.setAttribute('font-family', font.family);
    $el.setAttribute('font-weight', font.weight);
    $el.setAttribute('font-style', font.style);

    return font;
}

export async function downloadCss (font) {
    return await (await fetch(
        'https://fonts.googleapis.com/css?family=' + `${encodeURIComponent(font.family)}:${font.weight}${font.style}`,
    )).text();
}
