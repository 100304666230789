<template>
    <div class="template-edit-page">
        <tool-nav class="tool-nav">
            <tool-button v-if="(userRole == 'admin' && !isMobile)" icon="templates" @click.native="onCreate">
                Edit Template
            </tool-button>
            <tool-button v-if="(role == 'owner' || userRole =='admin') && this.projectId" icon="collab" @click.native="onCollab">Collaborate</tool-button>
            <tool-button
                v-if="(role != 'viewer' || userRole == 'admin')"
                icon="save"
                @click.native="onSave"
            >
                {{ isSaving ? 'Saving Project...' : 'Save Project' }}
            </tool-button>
            <tool-button icon="share" @click.native="onShowPost">Post</tool-button>
            <tool-button icon="download" @click.native="onShowDownload">Download</tool-button>

            <div
                v-if="template && template.premium && !purchase && userRole !== 'proUser'" 
                class="template-price"
                @click="onShowDownload"
            >
                Pro
            </div>
        </tool-nav>
        <div class="template-edit">
            <sidebar v-if="role != 'viewer' && activeSlide.editable.length != 0" ref="sidebar" class="sidebar" :active="activeEditable" :editables="activeSlide.editable" @set-active="setActiveEditable" >
                <editable-element
                    v-for="(e, i) in filteredEditable"
                    :active="activeEditable"
                    :key="e.id"
                    :id="e.id"
                    :order="i"
                    :index="getEditableIndex(e)"
                    :editable="e"
                    :flagsState="e.flagsState"
                    :disabled="disabled"
                    :windowWidth="windowWidth"
                    :textValue='e.value'
                    @handle-user-flags="handleUserFlags(e, $event)"
                    @edit-element="editElement(e, $event)"
                    @set-color="setColor(e, $event)"
                    @handle-color="handleColor($event)"
                    @set-typing="setTyping"
                    @change-image="handleChangeImage"
                    @handle-zoom="handleZoom(e, i, $event)"               
                    @handle-reset-image="handleResetImage(e)"
                    @highlight-input="highlightInput"
                />
            </sidebar>

            <div class="template-editor" :class="role == 'viewer' || activeSlide.editable.length == 0 ? 'viewer-view' : ''">
                <div class="template" ref="editor">
                </div>
                <template-carousel 
                    v-if="slides.length > 1"
                    :activeSlideIndex="activeSlideIndex"
                    :svgContainer="svgContainer" 
                    :windowWidth="windowWidth"
                    @setActive="setActive" 
                />
            </div>
            <!-- <loading-bar :progress="progress" :progressInfo="progressInfo" /> -->
            <loading-bar v-if="loading" :progress="progress" :progressInfo="progressInfo" :fadeLoad='fadeLoad' :slideIndex="loadedSlide" :slideLength="slides.length" />
        </div>

        <post-popup
            v-if="isPostOpen"
            :template="template"
            :media="pendingPostMedia"
            :media-urls="pendingPostMediaUrls"
            @close="isPostOpen = false"
        />

        <download-popup
            v-if="isDownloadOpen"
            @close="isDownloadOpen = false"
            @download="onDownload"
            :media="template.media"
        />

        <collab-popup
            v-if="isCollabOpen"
            :userID="project.userID"
            :shareKey="project.shareKey"
            :shareWith="project.shareWith"
            :_id="project._id"
            @remove-user="removeUser"
            @confirm-users="confirmUsers"
            @generate-link="generateLink"
            @remove-key="removeKey"
            @close-collab="onCollab"
        />

        <purchase-popup v-if="isPurchaseOpen" :template="template" @purchase="onPurchase"
                        @close="isPurchaseOpen = false" />

        <upgrade-popup v-if="isUpgradeOpen" @close="isUpgradeOpen = false" />
        <save-popup v-if="isSaveOpen" @close="handleSaveClose" @continue-routing="onContinueRouting()" @save="onSave(false, $event)" />
    </div>
</template>

<script>
import axios from 'axios';

import PurchasePopup from './PurchasePopup.vue';
import PostPopup from './PostPopup.vue';
import EditableElement from './EditableElement.vue';
import SavePopup from './SavePopup.vue';
import DownloadPopup from './DownloadPopup.vue';
import CollabPopup from './CollabPopup.vue';
import UpgradePopup from './UpgradePopup.vue';
import LoadingBar from '@/components/LoadingBar.vue';
import Sidebar from '@/components/Sidebar.vue';
import TemplateCarousel from '@/components/TemplateCarousel.vue';
import ToolButton from '@/components/ToolButton.vue';
import ToolNav from '@/components/ToolNav.vue';
import analytics from '@/helpers/analytics';
import api, { form } from '@/helpers/api';
import MultiProgress from '@/helpers/multi-progress.js';
import render from '@/helpers/render.js';
import { blobifySvgString, exportSvg, prepareSvg } from '@/helpers/svg-tools';
import { dimensionToPixels } from '@/helpers/utils';
import templatesMixin from '@/mixins/templates';
import textMixin from '@/mixins/text';
import userMixin from '@/mixins/user';
import HighlightEditable from '@/helpers/highlight-editable.js';
import ImageHandler from '@/helpers/image-handler';
import TextBoundary from '@/helpers/text-boundary';

export default {
    name: 'TemplateEditPage',

    mixins: [ templatesMixin, textMixin, userMixin ],

    props: [ 'id', 'projectId', 'shareKey' ],

    components: {
        CollabPopup,
        TemplateCarousel,
        DownloadPopup,
        EditableElement,
        PostPopup,
        PurchasePopup,
        SavePopup,
        Sidebar,
        ToolButton,
        ToolNav,
        UpgradePopup,
        LoadingBar,
    },

    computed: {
        activeSlide(){
            return this.slides[this.activeSlideIndex];
        },
        activeSvg(){
            return this.svgContainer[this.activeSlideIndex];
        },
        isMobile(){
            if(this.windowWidth < this.$breakPoints['width-medium']){
                return true;
            }
            return false;
        },
        filteredEditable(){
            if(this.activeEditable == 'text'){
                return this.activeSlide.editable.filter($el => $el.type == 'text' || $el.type == 'multiline');
            }
            return this.activeSlide.editable.filter($el => $el.type == this.activeEditable);
        },
    },

    data() {
        return {
            template: null,
            purchase: null,
            project: null,

            activeEditable: '',

            slides: [ { editable: [], url: '' } ],
            svgContainer: [],
            saving: false,
            activeSlideIndex: 0,
            disabled: false,
            isCollabOpen: false,

            isPostOpen: false,
            pendingPostMedia: null,
            pendingPostMediaUrls: null,

            isProjectsOpen: true,
            isDownloadOpen: false,
            isPurchaseOpen: false,
            isUpgradeOpen: false,

            isDirty: false,
            isSaving: false,
            isSaveOpen: false,
            routeAfterSave: null,

            dimension: null,
            role: 'viewer',
            windowWidth: window.innerWidth,

            fadeLoad: false,
            loading: true,
            loadedSlide: false,
            
            progressTrackers: [],
            progressTracker: null,
            progressInfo: 'Initializing',
            progress: 0,
            reduceImageSizeP: null,
            templateFillP: null,
            makeDraggableP: null,
            loadProjectP: null,
            otherP: null,

            editableImages: {},
            eventListeners: [],
            highlightListeners: [],

            createSelected: false,
        };
    }, 

    async mounted() {
        await this.ensureUser();
        this.templatePromise = (async () => {
            this.template = (await api.templates.getById(this.id)).data;
        })();

        await this.templatePromise;
        this.initialize();
    },

    beforeRouteLeave (to, from, next) {
        if (this.isDirty) {
            this.routeAfterSave = to;
            this.isSaveOpen = true;
            next(false);
        } else {
            next(true);
        }
    },

    methods: {
        highlightInput($event){
            for(const listener of this.highlightListeners){
                listener.isTyping(false);
            }
            this.highlightListeners[$event.order].isTyping($event.typing);
        },

        getEditableIndex(editable){
            return this.activeSlide.editable.indexOf(editable);
        },

        async initialize(){

            this.progressTracker = new MultiProgress(progress =>{
                this.progress = Math.floor(progress);
            });

            if (this.template.dimension) {
                await api.dimensions.getById(this.template.dimension).then(response => {
                    this.dimension = response.data;
                });
            }

            if (this.template.premium && this.authenticated) {
                this.purchase = (await api.purchases.getForTemplate(this.id)).data;
            }
            while (this.progressTrackers.length != this.template.slides.length) {
                this.progressTrackers.push(this.progressTracker.track());
            }
            this.reduceImageSizeP = this.progressTracker.track();
            this.templateFillP = this.progressTracker.track();
            this.makeDraggableP= this.progressTracker.track();
            this.loadProjectP = this.progressTracker.track();
            this.otherP = this.progressTracker.track();

            //populated svgContainer with each slides svg
            for (let i = 0; i < this.template.slides.length; i++){
                this.progressInfo = `Loading Slides ${i + 1} / ${this.template.slides.length}`;
                const svgResponse = await axios.get(this.template.slides[i].url + '?templateVersion2=' + this.template.version, {
                    onDownloadProgress: progressEvent => {
                        let percentCompleted = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                        this.progressTrackers[i](percentCompleted);
                    },
                });
                const svgText = svgResponse.data;
                const placeholder = document.createElement('div');
                placeholder.innerHTML = svgText;
                placeholder.classList.add('svg-container');
                placeholder.classList.add('disabled');
                const svg = placeholder.querySelector('svg');
                const { width, height } = this.dimension ? dimensionToPixels(this.dimension) : {width:svg.viewBox.baseVal.width, height:svg.viewBox.baseVal.height };
                placeholder.style.maxWidth = width + 'px';
                placeholder.style.maxHeight = height + 'px';
                this.$refs.editor.appendChild(placeholder);
                // this.progressTracker.track(1);
                this.updateSvg(this.activeSvg);
                this.$set(this.svgContainer, i, svg);
            }

            const $svgContainers = this.$refs.editor.querySelectorAll('.svg-container');
            await Promise.all(this.template.slides.map(async (slide, index) => {
                const $slide = $svgContainers[index];
                const $svg = $slide.querySelector('svg');

                await Promise.all(slide.editable.map(async (editable) => {
                    const $el = $slide.querySelector(`[id='${editable.id}']`);
                    if (editable.type == 'text' || editable.type == 'multiline') {
                        const $linkedEl = $slide.querySelector(`[id='${$el.getAttribute('data-linked')}']`);
                        editable.boundary = new TextBoundary($el, editable, $svg, $linkedEl);
                    }
                    if (editable.type == 'image'){
                        this.$set(editable, 'zoom', 100);
                        this.$set(editable, 'coords', {x: 0, y: 0});
                        const imageHandler = new ImageHandler(
                            $el,
                            editable,
                            $svg,
                            $svgContainers[index],
                            index,
                            this.handleDrag,
                        );
                        await imageHandler.promise;
                        this.$set(this.editableImages, editable.id, imageHandler);
                        this.$set(editable, 'value', $el.getAttribute('xlink:href'));
                    }
                }));
                this.reduceImageSizeP(100);
            }));
            this.progressInfo = 'Slides Loaded';
            //Set initial template add to 
            this.slides = [ ...this.template.slides ];
            window.addEventListener('resize',()=>{
                this.windowWidth = window.innerWidth;
            });
            this.templateFill();
            this.templateFillP(100);
            this.makeDraggableP(100);
            if (this.projectId) {
                this.progressInfo = 'Loading Project';
                await this.loadProject(this.projectId);
            } else {
                this.role = 'editor';
            }
            this.loadProjectP(100);
            this.progressInfo = 'Wrapping up';
            this.fixOldColors();
            this.otherP(50);
            $svgContainers[0].classList.remove('disabled');
            this.otherP(88);
            analytics.templates.open({ id: this.id, name: this.template.name, project: !!this.projectId });
            this.otherP(100);
            this.setActive(0);
            setTimeout(()=>{
                this.fadeLoad = true;
                setTimeout(()=>{
                    this.loading = false;
                }, 500);
            }, 500);
        },
        fixOldColors(){
            for (const slide of this.slides) {
                for (const editable of slide.editable) {
                    if (editable.options) {
                        //Set old options to fill automatically, stroke starts with transparent color
                        editable.fillOptions = editable.options;
                        editable.strokeOptions = [ 'rgba(0,0,0,0)' ];
                        delete editable.options;
                    }
                }
                if (this.projectId && slide.values) {
                    for (const value of slide.values) {
                        if (value.color) {
                            //Set old value to fill automatically, stroke starts with transparent color
                            value.fillColor = value.color;
                            value.strokeColor = 'rgba(0,0,0,0)';
                            delete value.color;
                        }
                    }
                }
                this.$set(this.slides, this.activeSlideIndex, this.slides[this.activeSlideIndex]);
            }
        },
        async removeUser(data){
            const res = await api.projects.removeShareWith(this.project._id, data.id);
            this.project.shareWith = res.data.shareWith;
        },

        async confirmUsers(data){
            const res = await api.projects.shareWith(data.id, data.newUser);
            this.project.shareWith = res.data.shareWith;
        },

        async generateLink(data){
            const res = await api.projects.shareKey(data.id, {role: data.role});
            this.project.shareKey = res.data.shareKey;
        },

        async removeKey(data){
            const res = await api.projects.removeKey(this.project._id, this.project.shareKey[data.index].key);
            this.project.shareKey = res.data.shareKey;
        },

        onCollab(){
            this.isCollabOpen = !this.isCollabOpen;
        },

        findFirstTab(){
            if(this.activeSlide.editable.find($el => $el.type == 'text' || $el.type == 'multiline')){
                return 'text';
            }
            if(this.activeSlide.editable.find($el => $el.type == 'image')){
                return 'image';
            }
            if(this.activeSlide.editable.find($el => $el.type == 'color')){
                return 'color';
            }
            return '';
        },

        handleRemoveEvents(){
            for(const listener of this.highlightListeners){
                listener.removeEvents();
            }
            this.highlightListeners = [];
        },

        handleaddEvents(){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const editables = this.activeSlide.editable.filter($el => $el.type == this.activeEditable || 
                ($el.type == 'multiline' && this.activeEditable == 'text'));
            for(let i = 0; i < editables.length; i++){
                let $el = document.getElementById(editables[i].id);
                let $indexEl = document.getElementById(`editable-element-${$el.id}`);
                this.highlightListeners.push(new HighlightEditable($el, $indexEl, editables[i], $template));
            }
        },

        async setActiveEditable(type){
            this.handleRemoveEvents();
            this.activeEditable = type;
            await this.$nextTick();
            this.handleaddEvents();
        },

        async setActive(index){
            // this.$set(this.svgContainer, this.activeSlideIndex, this.$refs.editor.querySelector('svg'));
            if (this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex]) {
                this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.add('disabled');
            }
            this.activeSlideIndex = index;
            this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex].classList.remove('disabled');
            await this.updateSvg();
            await this.setActiveEditable(this.findFirstTab());
        },

        async updateSvg () {
            this.templateFill();
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            await prepareSvg($template);
        },

        async templateFill(){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];

            for (let index in this.activeSlide.editable) {
                let $el = $template.querySelector(`[id='${this.activeSlide.editable[index].id}']`);

                if (this.activeSlide.editable[index].type != 'image') {
                    const fillColor = this.activeSlide.editable[index].fillColor ? this.activeSlide.editable[index].fillColor : this.activeSlide.editable[index].fillOptions[0];
                    const strokeColor = this.activeSlide.editable[index].strokeColor ? this.activeSlide.editable[index].strokeColor : this.activeSlide.editable[index].strokeOptions[0];
                    this.$set(this.activeSlide.editable[index], 'fillColor', fillColor);
                    this.$set(this.activeSlide.editable[index], 'strokeColor', strokeColor);
                    this.setColor(this.activeSlide.editable[index], {color: this.activeSlide.editable[index].fillColor, type: 'fill'});
                    this.setColor(this.activeSlide.editable[index], {color: this.activeSlide.editable[index].strokeColor, type: 'stroke'});
                }
                if (this.activeSlide.editable[index].type === 'image') {
                    if (!$el.hasAttribute('x')) {
                        $el.setAttribute('x', 0);
                    }
                    if (!$el.hasAttribute('y')) {
                        $el.setAttribute('y', 0);
                    }
                    //Reuse previous id if already fixed, otherwise update editable
                    if (this.activeSlide.editable[index].id.includes('IMGID_')) {
                        $el.id = this.activeSlide.editable[index].id;
                    } else {
                        this.activeSlide.editable[index].id = $el.id;
                    }
                    //Some older templates are missing flags, declare it to avoid issues. Admin should resave this template
                    if (this.activeSlide.editable[index].flags === null) {
                        this.activeSlide.editable[index].flags = [];
                    }
                }
                if (this.activeSlide.editable[index].flags) {
                    if (!this.activeSlide.editable[index].flagsState) {
                        this.$set(this.activeSlide.editable[index], 'flagsState', {});
                    }
                    if (this.activeSlide.editable[index].flags.includes('user-hideable') && this.activeSlide.editable[index].flagsState['user-hideable'] === undefined) {
                        this.$set(this.activeSlide.editable[index].flagsState, 'user-hideable', false);
                    }
                }
                this.progressInfo = `Filling default values ${parseInt(index) + 1} / ${this.activeSlide.editable.length}`;
            }
        },

        handleDrag (editable) {
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const $el = document.querySelector(`#${editable.id}`);

            const mousedown = ($event) => {
                this.editableImages[editable.id].startDrag($event);
            };

            $el.addEventListener('mousedown', mousedown);
            $el.addEventListener('touchstart', mousedown, true);

            const mouseup = ($event) => {
                const coords = this.editableImages[editable.id].endDrag($event);
                this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
                this.$set(editable, 'coords', coords);
            };

            document.addEventListener('mouseup', mouseup);
            document.addEventListener('touchend', mouseup);
        },        

        handleZoom(editable, i, $event){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            if ($event.type =='start'){
                this.editableImages[editable.id].zoomOverlayStart();
            } else if ($event.type == 'move') {
                this.editableImages[editable.id].zoomOverlayMove();
            } else if ($event.type == 'end') {
                this.editableImages[editable.id].zoomOverlayEnd();
                this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
            } 
            if($event.type == 'zoom') {
                //clean up $event.$event
                let zoom = {zoom: $event.$event.zoom, oldZoom: editable.zoom};
                const { coords } = this.editableImages[editable.id].zoomImage(zoom, i);
                this.$set(editable, 'zoom', $event.$event.zoom);
                this.$set(editable, 'coords', coords);
            }
        },
        async loadProjectImage(editable, index){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            await this.editableImages[editable.id].promise;
            await this.editableImages[editable.id].changeImage({value: editable.value});
            this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
        },
        async handleChangeImage($event){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const editable = this.activeSlide.editable[$event.index];
            this.$set(editable, 'zoom', 100);
            this.$set(editable, 'coords', {x: 0, y: 0});
            await this.editableImages[editable.id].reduceImageSize($event.value);
            await this.editableImages[editable.id].promise;

            let $el = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex].querySelector(`#${editable.id}`);
            this.$set(editable, 'value', $el.getAttribute('xlink:href'));
            this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
        },
        handleUserFlags(editable, $event){
            const index = $event.index;
            const flag = $event.flag;
            editable.flags[flag] = !editable.flags[flag];
            if(flag == 'user-hideable'){
                this.handleHide(editable);
            }
        },
        async handleHide(editable){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const $el = $template.querySelector(`[id='${editable.id}']`);        
            if(editable.flagsState['user-hideable']){
                $el.style.display = 'none';
            }else{
                $el.style.display = 'block';
            }
            this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
        },
        async handleResetImage(editable){
            const oldZoom = editable.zoom;
            this.$set(editable, 'zoom', 100);
            this.$set(editable, 'coords', {x: 0, y: 0});
            await this.editableImages[editable.id].resetImagePosition({zoom: editable.zoom, oldZoom: oldZoom});
            await this.editableImages[editable.id].promise;
        },
        async projectFill(){
            for (let i = 0; i < this.slides.length; i++) {
                this.setActive(i);
                this.loadedSlide = i + 1;
                const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
                if (this.activeSlide.values) {
                    for (let j = 0; j < this.activeSlide.values.length; j++) {
                        //If editable was removed on template edit just skip it
                        if (this.activeSlide.editable[j]) {
                            let editable = this.activeSlide.editable[j];
                            let $el = $template.querySelector(`[id='${editable.id}']`);
                            if (this.activeSlide.values[j].flagsState !== undefined) {
                                if (Object.keys(this.activeSlide.values[j].flagsState).length > 0) {
                                    //apply flags
                                    this.$set(editable, 'flagsState', this.activeSlide.values[j].flagsState);
                                    if (editable.flagsState['user-hideable']) {
                                        this.$set(editable.flagsState, 'user-hideable', editable.flagsState['user-hideable']);
                                        this.handleHide(editable);
                                    }
                                }
                            }
                            if (editable.type == 'text' || editable.type == 'multiline') {
                                if (this.activeSlide.values[j].textValue) {
                                    this.$set(editable, 'value', this.activeSlide.values[j].textValue);
                                    this.editElement(editable, {index: j, value: editable.value});
                                    editable.default = false;
                                }
                            }

                            if (editable.type != 'image') {
                                const fillColor = this.activeSlide.values[j].fillColor ? this.activeSlide.values[j].fillColor : editable.fillOptions[0];
                                const strokeColor = this.activeSlide.values[j].strokeColor ? this.activeSlide.values[j].strokeColor : editable.strokeOptions[0];
                                this.$set(editable, 'fillColor', fillColor);
                                this.$set(editable, 'strokeColor', strokeColor);
                                this.setColor(editable, {color: editable.fillColor, type: 'fill'});
                                this.setColor(editable, {color: editable.strokeColor, type: 'stroke'});
                            }
                            if (this.activeSlide.editable[j].type === 'image' && this.activeSlide.values[j].image) {
                                this.$set(editable, 'zoom', this.activeSlide.values[j].zoom);
                                this.$set(editable, 'coords', this.activeSlide.values[j].coords);
                                this.$set(editable, 'value', this.activeSlide.values[j].image);

                                if (this.activeSlide.editable[j].id != $el.id) {
                                    this.activeSlide.values[j].id = $el.id;
                                    this.$set(this.activeSlide.values, j, this.activeSlide.values[j]);
                                }
                                this.$set(editable, 'id', editable.id);
                                await this.loadProjectImage(editable, j);
                            }
                            this.$set(this.slides, this.activeSlideIndex, this.activeSlide);
                        }
                    }
                    if (this.activeSlide.values.length > this.activeSlide.editable.length) {
                        this.activeSlide.values.slice(0, this.activeSlide.editable.length);
                        this.$set(this.slides, i, this.activeSlide);
                    }
                }
                this.progressInfo = `Filling project values ${i + 1} / ${this.slides.length}`;
            }
            this.setActive(0);
            this.isDirty = false;
        },

        async loadProject(id){
            try {
                if (this.shareKey) {
                    this.project = await api.projects.getShared(id, this.shareKey);
                    this.project = this.project.data;
                    this.role = this.project.shareKey.find(e => e.key == this.shareKey).role;
                } else {
                    this.project = await api.projects.open(id);
                    this.project = this.project.data;
                    if (this.user && this.user._id == this.project.userID) {
                        this.role = 'owner';
                    } else {
                        this.role = this.project.shareWith.find(e => e.id == this.user._id).role;
                    }
                }
                //clean up project values
                for (let i = 0; i < this.slides.length; i++) {
                    //New slides that were added after project was created shouldnt be included here causes error
                    if (this.project.slides[i]) {
                        for (let j = 0; j < this.project.slides[i].values.length; j++) {
                            if (this.project.slides[i].values[j] && this.slides[i].editable[j]) {
                                if (this.project.slides[i].values[j].id == this.slides[i].editable[j].id) {
                                    this.slides[i].values = this.project.slides[i].values;
                                } else {
                                    //Value doesnt apply anymore due to being removed from template.
                                    this.project.slides[i].values.splice(j, 1);
                                    j--;
                                }
                            }
                        }
                    }
                    this.$set(this.slides, i, this.slides[i]);
                }
                await this.projectFill();
            } catch (e) {
                this.$toast.error('Could not load project!', { timeout: false });
            }
        },

        setTyping(obj) {
            this.disabled = obj;
        },
        async handleColor($event){
            if (this.user) {
                await api.users.addColor($event.color);
                await this.refreshUser();
            }
        },
        setColor (editable, $event, $el) {
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            if (!$el) {
                $el = $template.querySelector(`[id='${editable.id}']`);
            }
            if (editable.type !== 'image') {
                //Lets check if the colors are using an invalid method
                this.fixColor($el);
                if ($event.type == 'fill') {
                    $el.setAttribute('fill', $event.color); 
                    this.$set(editable, 'fillColor', $event.color);
                } else if($event.type == 'stroke') {
                    $el.setAttribute('stroke', $event.color);
                    this.$set(editable, 'strokeColor', $event.color);
                }
            }
            this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
            this.isDirty = true;
        },
        editElement(editable, $event) {
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            const id = editable.id;
            const $el = $template.querySelector(`[id='${id}']`);
            const linked = document.querySelectorAll(`[data-linked='${id}']`);
            const value = $event.value;

            this.$set(editable, 'default', false);
            this.$set(editable, 'value', $event.value);

            if ($el.children.length > 0) {
                if ($el.children[0].hasAttribute('font-family')) {
                    $el.setAttribute('font-family', $el.children[0].getAttribute('font-family'));
                }
                if ($el.children[0].hasAttribute('font-size')) {
                    $el.setAttribute('font-size', $el.children[0].getAttribute('font-size'));
                }
                if ($el.children[0].hasAttribute('font-weight')) {
                    $el.setAttribute('font-weight', $el.children[0].getAttribute('font-weight'));
                }
            }

            //Multiline 90deg causes infinite loop caution
            //Convert Multiline -> Text, remove tspans before edit;
            if (isNaN($el.getAttribute('font-size') && editable.type != 'multiline')) {
                if ($el.children) {
                    $el.setAttribute('font-size', $el.children[0].getAttribute('font-size'));
                }
            }
            
            editable.boundary && editable.boundary.setText(value);

            this.$set(this.svgContainer, this.activeSlideIndex, $template.querySelector('svg'));
            this.isDirty = true;

            if (this.highlightListeners[$event.order]) {
                this.highlightListeners[$event.order].resizeBox();
            }
        },

        onShowDownload () {
            if (this.authenticated) {
                if (!this.template.premium || this.purchase || this.userRole == 'proUser' || this.userRole == 'admin') {
                    this.isDownloadOpen = true;
                } else {
                    this.isPurchaseOpen = true;
                }
            } else {
                this.$toast.error('Please login or register first!', { timeout: 5000 });
                this.$emit('login-clicked');
            }
        },

        async onShowPost () {
            if (this.authenticated) {
                if (this.userRole == 'freeUser') {
                    this.isUpgradeOpen = true;
                } else {
                    // Revoke old media URL if any to prevent memory leaks
                    if (this.pendingPostMediaUrls) {
                        this.pendingPostMediaUrls.forEach(url => URL.revokeObjectURL(url));
                    }

                    this.pendingPostMedia = [];
                    this.pendingPostMediaUrls = [];

                    const { strings } = this.exportSvg();

                    for (let i = 0; i < strings.length; i++) {
                        const media = await render({
                            $svg: this.svgContainer[i],
                            string: strings[i],
                            renderWidth: 1440,
                            format: 'jpeg',
                        });

                        this.pendingPostMedia.push(media);
                        this.pendingPostMediaUrls.push(URL.createObjectURL(media));
                    }

                    this.isPostOpen = true;
                }
            } else {
                this.$toast.error('Please login or register first!', { timeout: 5000 });
                this.$emit('login-clicked');
            }
        },

        /**
         * Serialize and export the svg as an element, string, and Blob.
         */
        exportSvg () {
            const strings = this.svgContainer.map(exportSvg);
            const blobs = strings.map(blobifySvgString);

            return { strings, blobs };
        },

        /** Open the Template Create */
        onCreate () {
            this.createSelected = true;
            this.$router.replace({ name: 'TemplateCreateExisting', params: { id: this.id }});
        },

        /**
         * Download the currently edited template.
         */
        async onDownload (format) {
            let { strings, blobs } = this.exportSvg();

            for (let i = 0; i < blobs.length; i++) {
                let downloadableBlob = blobs[i];

                if (format !== 'svg') {
                    // Format is not svg, so we need to actual render to whatever format is required
                    downloadableBlob = await render({
                        $svg: this.svgContainer[i],
                        string: strings[i],
                        format,
                        dimension: this.dimension,
                    });
                }

                this.downloadBlob(downloadableBlob, format);
            }

            api.templates.incrementDownloads(this.id);
            analytics.templates.download({ id: this.id, name: this.template.name, format });
        },

        /**
         * Download a blobs file with a specific format.
         */
        downloadBlob (blobs, format) {
            const a = document.createElement('a');
            const url = URL.createObjectURL(blobs);
            a.href = url;
            if (this.projectId) {
                a.download = this.project.name + '.' + format;
            } else {
                a.download = this.template.name + '.' + format;
            }
            a.click();

            URL.revokeObjectURL(url);
        },

        async onPurchase () {
            const projectId = await this.onSave(false);
            window.location = (await api.purchases.start(this.id, projectId)).data;
        },

        onContinueRouting () {
            this.isDirty = false;
            if(this.createSelected){
                this.createSelected = false;
                this.$router.replace(this.routeAfterSave);
            }else if(this.routeAfterSave.name == 'Home'){
                this.$router.push(this.routeAfterSave);
            }else{
                this.$router.go(-1);
            }
        },

        handleSaveClose(){
            this.isSaveOpen = false
            this.createSelected = false;
        },

        async onSave (refresh = true, cb) {
            if (this.isSaving) { return; }

            if (!this.authenticated) {
                this.$toast.error('Please login or register first!', { timeout: 5000 });
                this.$emit('login-clicked');
                return;
            }

            //make sure any events are removed from highlight
            this.handleRemoveEvents();

            this.isSaving = true;

            const strings = this.svgContainer.map(exportSvg);
            const thumb = await render({ $svg: this.svgContainer[0], string: strings[0] });

            const slides = [];

            for (const oldSlide of this.slides) {
                const slide = Object.assign({}, oldSlide);
                slides.push(slide);
                slide.values = [];
                slide.editable = [];

                for (const oldEditable of oldSlide.editable) {
                    const editable = Object.assign({}, oldEditable);
                    delete editable.boundary;
                    slide.editable.push(editable);

                    const values = {};
                    slide.values.push(values);

                    values.id = editable.id;
                    values.flagsState = editable.flagsState;

                    if (editable.type == 'image') {
                        if (editable.value) {
                            values.image = editable.value;
                        }
                        if (editable.zoom) {
                            values.zoom = editable.zoom;
                        }
                        values.coords = editable.coords;
                    } else {
                        values.fillColor = editable.fillColor;
                        values.strokeColor = editable.strokeColor;

                        if (editable.type == 'text' || editable.type == 'multiline') {
                            values.textValue = editable.value;
                        }
                    }
                }
            }
            const upload = form({
                thumb,
                slides: JSON.stringify(slides),
                name: this.template.name,
            });

            let projectId = null;
            
            if(this.$tenant){ upload.append('tenant', this.$tenant.id);}

            if (this.project) {
                upload.append('projectId', this.project._id);

                if (this.shareKey) {
                    upload.append('shareKey', this.shareKey);
                }

                await api.projects.update(
                    this.project._id, 
                    upload,
                );

                this.isDirty = false;
                refresh && this.$router.go();

                analytics.templates.save({ id: this.id, name: this.template.name, existing: true });

                projectId = this.project._id;
            } else {
                upload.append('templateID', this.id);
                const project = (await api.projects.save(upload)).data;

                this.isDirty = false;
                refresh && this.$router.replace(`/template/${this.id}/edit/project/${project._id}`);
                //Ensure project id is used
                refresh && this.loadProject(project._id);
                analytics.templates.save({ id: this.id, name: this.template.name, existing: false });
                projectId = project._id;
            }

            this.isSaving = false;
            cb && cb();
            return projectId;
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

.template-edit-page {
    display: flex;
    height: 100%;
    width: 100vw;
    flex-direction: column;

    @include max-width($width-medium){
        min-width: $min-width;
        min-height: 100%;
    }
    .tool-nav {
        
        .template-price {
            padding: 4px 8px;
            margin: 3px 0 3px 16px;
            background: $color-red-light;
            color: white;
            font-size: 16px;
            line-height: 25px;
            border-radius: 6px;
        }
    }

    .template-edit {
        display: grid;
        width: 100%;
        flex-grow: 1;
        grid-template-columns: auto 1fr;
        grid-template-rows: 100%;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        max-height: 100vh;
        padding-top:90px;

        @include max-width($width-medium){
            grid-template-columns:1fr;
            grid-template-rows:auto 1fr;
            max-height:unset;
        }
        
        .template-editor {
            position:relative;
            display: grid;
            background:var(--background-color);
            grid-template-rows:1fr auto;
            grid-template-columns:1fr;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 100%;
            max-height:100%;
            width: 100%;
            box-sizing: border-box;
            justify-items: center;
            overflow-y: scroll;

            @include max-width($width-medium){
                min-height:500px;
                max-width:100vw;
                padding: 16px;
            }

            .dropload {
                max-width: 640px;
                max-height: 640px;
                height: 100%;
                width: 100%;
            }

            .template {
                display:flex;
                flex-grow:1;
                width:100%;
                height:100%;
                min-height: 720px;
                max-height: 100vh;
                overflow:hidden;
                justify-content:center;
                align-items:center;           

                @include max-width($width-medium){
                    min-height:360px;
                }
                .svg-container{
                    width: 90%;
                    height: 90%;
                    display:flex;
                    flex-grow:1;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                
                    &.disabled{
                        position:absolute;
                        top:0;
                        left:0;
                        opacity:0;
                        pointer-events:none;
                    }

                    svg {
                        background:white;
                        width: intrinsic;
                        height: intrinsic;
                        max-width:100%;
                        max-height:100%;

                        * {
                            pointer-events: none;
                        }

                        g, text, tspan, image {
                            pointer-events: unset;
                        }
                    }
                }
            }
            .templateName,
            .go {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .go {
                grid-row: 3;
            }
            &.viewer-view{
                grid-column: 1 / span 2;
            }
        }
        canvas {
            width: 100%;
        }
    }
}


</style>

<style lang="scss">
html,
body {
    height: 100%;
}

.template-edit-page .template-editor .template svg {
    max-height: 650px;
    cursor: pointer;

    [data-editable]::after {
        content: attr(data-editable);
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -8px;
        margin-right: -8px;
        width: 16px;
        height: 16px;
        background: red;
    }
}
</style>
