<template>
    <img @click="$emit('input', !value)" :src="src" :class="{ enabled }">
</template>

<script>
import checkedSvg from '@/assets/img/checkbox_checked_16px.svg';
import disabledSvg from '@/assets/img/checkbox_disabled_16px.svg';
import emptySvg from '@/assets/img/checkbox_empty_16px.svg';

export default {
    props: { value: { type: Boolean, default: false }, enabled: { type: Boolean, default: true } },
    computed: {
        src () {
            switch (true) {
                case !this.enabled: return disabledSvg;
                case this.value: return checkedSvg;
                default: return emptySvg;
            }
        },
    },
};
</script>

<style scoped>
img.enabled {
    cursor: pointer;
}
</style>
