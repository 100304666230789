<template>
    <div
        v-if="node"
        class="inspector-tree"
        @mouseover="onMouseover"
        @mouseout="onMouseout"
    >
        <span class="name">&lt;{{ node.name }}&gt;</span>
        <span v-if="node.id" class="id">#{{ node.id }}</span>
        <span v-if="node.content" class="content">{{ node.content }}</span>
        <button v-if="buttonText" class="action" @click="$emit('add', node)">{{ buttonText }}</button>
        <button v-if="!node.$el.hasAttribute('data-editable') && !this.editableMultilineTspan" class="action" @click="onDelete">Delete Element</button>
        <button v-if="node.$el.hasAttribute('data-editable')" class="action" @click="$emit('select', node)">Adjust</button>
        <InspectorTree
            v-for="child in node.children"
            :key="child && child.uid"
            :node="child"
            @highlight="$emit('highlight', $event)"
            @unhighlight="$emit('unhighlight', $event)"
            @add="$emit('add', $event)"
            @delete="$emit('delete', $event)"
            @select="$emit('select', $event)"
        />
    </div>
</template>

<script>
export default {
    name: 'InspectorTree',
    props: [ 'node' ],
    data () { return {
        open: true,
        highlighted: false,
    }; },
    computed: {
        potentiallyEditable () {
            return ![ 'g', 'svg', 'defs', 'clippath' ].includes(this.node.name.toLowerCase());
        },

        buttonText () {
            if (this.potentiallyEditable) {
                if (this.node.$el.hasAttribute('data-editable') || this.editableMultilineTspan) {
                    return 'Remove Editable';
                } else {
                    return 'Make Editable';
                }
            } else {
                return null;
            }
        },

        editableMultilineTspan () {
            return this.node.name == 'tspan'
                && this.node.parent
                && this.node.parent.name == 'text'
                && this.node.parent.$el.hasAttribute('data-editable');
        },
    },

    methods: {
        onMouseover () {
            if (this.potentiallyEditable) {
                this.highlighted = true;
                this.$emit('highlight', this.node);
            }
        },

        onMouseout () {
            if (this.highlighted) {
                this.highlighted = false;
                this.$emit('unhighlight', this.node);
            }
        },

        onDelete(){
            if(window.confirm(`Are you sure you want to delete ${this.node.name}? This will be a PERMANENT change on save.`)){
                this.$emit('delete', this.node);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

.inspector-tree {
    padding: 4px 0 4px 16px;
}

.id {
    margin-left: 4px;
    color: $color-gray-dark;
}

.content {
    margin-left: 4px;
    padding: 2px 4px;
    background: $color-blue-light;
    border-radius: 4px;
}

.action {
    margin-left: 4px;
    height: 24px;
    font-size: 13px;
    padding: 1px 8px;
}
</style>
