<template>
    <input :value="value" @input="onInput($event.target.value)">
</template>

<script>
export default {
    props: [ 'elementId' ],

    data () { return {
        value: 'normal',
    }; },

    computed: {
        element () {
            return document.getElementById(this.elementId);
        },
    },

    watch: {
        element () {
            this.value = this.element.getAttribute('letter-spacing');
        },
    },

    methods: {
        onInput (value) {
            this.value = value;
            this.element.setAttribute('letter-spacing', this.value);
        },
    },
};
</script>

<style>

</style>
