<template>
    <div>
        <category-list :selected="selectedDimensions" :categories="dimensions" @select="onSelectDimension" />

        <button @click="create()">Add New Dimension</button>
        <create-dimension-popup v-if="isCreate" @close="closeCreate" :media="media"
            @change-media="$emit('change-media', $event)" />

        <p>
            If you need to remove or edit a dimension, please contact a developer, as this change can affect multiple templates.
        </p>
    </div>
</template>

<script>
import dimensionsMixin from '@/mixins/dimensions';

import CreateDimensionPopup from './CreateDimensionPopup.vue';

export default {

    mixins: [ dimensionsMixin(false, false) ],

    components: { CreateDimensionPopup },

    props: [ 'media' ],

    data () { return {
        isCreate: false,
    }; },

    methods: {

        async create () {
            this.isCreate = true;
        },

        closeCreate () {
            this.isCreate = false;
            this.refreshDimensions();
        },
    },
};
</script>

<style lang="scss" scoped>
p {
    font-size: 11px;
}
</style>
