<template>
    <div class="home">
        <div class="hero">
            <div class="hero-content">
                <h1>Realtors, save more time and look better online.</h1>
                <router-link to="/template/catalog" class="button">Start for free</router-link>
            </div>
        </div>

        <div class="screenshots">
            <span>
                <strong>Top Realtors</strong> use Real Estate Creates to grow their business on social media.
                <router-link to="/template/catalog">Start&nbsp;now.</router-link>
            </span>
            <img :src="require('@/assets/img/screenshot1.png')" />
            <img :src="require('@/assets/img/screenshot2.png')" />
            <img :src="require('@/assets/img/screenshot3.png')" />
        </div>

        <div class="section-content">
            <div class="info">
                <h2>Social media has never been easier with the most intuitive content posting plaform</h2>
                <router-link to="/template/catalog" class="button">Start for free</router-link>
            </div>
        </div>
            
        <div class="templates">
            <h2>Hundreds of designs, for <u>Realtors.</u></h2>
            <catalog-grid-carousel :templates="socialTemplates" :limit="10" :isMobile="isMobile"/>
            <router-link to="/template/catalog" class="button">Start for free</router-link>
        </div>

        <div class="section-content">
            <h2 id="plans">Plans starting at $0</h2>
            <div class="tile-container">
                <div class="price-tile">
                    <h2>Free</h2>
                    <span>per user</span>
                    <p>Limited access to templates</p>
                    <p>Browse Designs</p>
                    <p>Customize limited templates</p>
                </div>
                <router-link to="/template/catalog" class="button">Get Started</router-link>
            </div>
            <div class="tile-container">
                <div class="price-tile">
                    <h2>$14/mo</h2>
                    <span>per user</span>
                    <p>Unlimted Templates</p>
                    <p>Unlimited Posting</p>
                    <p>Customize All Templates</p>
                </div>
                <router-link to="/plans?plan=monthly" class="button">Get Started</router-link>
            </div>
            <div class="tile-container">
                <div class="price-tile">
                    <h2>Custom</h2>
                    <span>Enterprise plans available</span>
                    <p>
                        Great for brokerages and large teams. 
                        Manage all of your realtor's social 
                        media from this easy to use platform.
                    </p>
                </div>
                <router-link to="/plans?plan=yearly" class="button">Get Started</router-link>
            </div>
        </div>
        <div class="final-content">
            <h1>Turn your followers into transactions.</h1>
            <router-link to="/template/catalog" class="button">Start for free</router-link>
        </div>
    </div>
</template>

<script>
import CatalogGridCarousel from '@/components/CatalogGridCarousel.vue';
import CategoryCarousel from '@/components/CategoryCarousel.vue';
import api, { query } from '@/helpers/api';
import userMixin from '@/mixins/user';

export default {
    components: { CategoryCarousel, CatalogGridCarousel },
    mixins: [ userMixin ],
    name: 'Home',

    computed:{
        isMobile(){
            if(this.windowWidth < this.$breakPoints['width-medium']){
                return true;
            }
            return false;
        },
    },

    data () { return {
        socialTemplates: [],
        printTemplates: [],
        windowWidth: window.innerWidth,
    }; },

    async mounted () {
        this.socialTemplates = (await api.templates.get(1, 10, query({ media: 'social' }))).data;
        this.printTemplates = (await api.templates.get(1, 10, query({ media: 'print' }))).data;
        window.addEventListener('resize', this.onResize);
    },
    
    beforeRouteLeave(to, from, next){
        window.removeEventListener('resize', this.onResize);
        next();
    },

    methods: {
        onResize(){
            this.windowWidth = window.innerWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';
@import '@/assets/fonts/RoxboroughCF';

.home{
    width:100%;
    background: $color-gray-light;

    @include max-width($width-medium){
        max-width:100%;
        margin:0 auto;
    }
}

h1, h2 {
    font-family: RoxboroughCF;
    font-weight: bold;
}

h2 {
    font-size:2.5rem;
    text-align:center;
}

.button {
    margin:0 auto;
    margin-top:15px;
    display:flex;
    justify-content: center;
    align-items:center;
    max-width:180px;
    background:unset;
    border:2px solid #000;
    border-radius:20px;
    padding: 5px;
    font-weight:800;
    color:black;
    text-transform: uppercase;
}

.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 24px;

    @include max-width ($width-small) {
        padding: 16px 0;
    }

    > * {
        width: 50%;
        padding: 32px 52px;
        box-sizing: border-box;

        @include max-width ($width-large) {
            padding: 28px 24px;
        }

        @include max-width ($width-medium) {
            width: 100%;
            padding: 16px 0;
        }
    }

    .hero-image {
       height: 100%;
       min-height: 320px;
       background-size: cover;
       background-position: 50% 50%;
    }

}

.screenshots{
    display:flex;
    max-width:100%;
    width:100vw;
    align-items:center;
    justify-content:center;
    grid-gap:50px;
    margin-top:60px;
    flex-wrap:wrap;

    @include max-width($width-medium){
        margin:0;
    }
    img{
        max-width:100%;
        max-height:350px;
    }

    span{
        text-align:center;
        align-self:center;
        justify-self:center;
        flex-basis:100%;
        width:100%;
        font-size:1.5rem;

        @include max-width($width-medium){
            font-size:1rem;
        }
    }
}

.section-content{
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap:80px;
    text-align:center;
    width:100%;
    margin:0 auto;
    margin-top:160px;
    margin-bottom:160px;
    max-width:1200px;

    @include max-width($width-medium){
        margin:60px 0;
        gap:40px;
    }
    h2{
        @include max-width($width-medium){
            font-size:1.25rem;
        }
    }
    #plans{
        width:100%;
        flex-basis:100%;
        margin:0;
    }

    .price-tile{
        width:300px;
        margin-bottom:20px;


        h2{
            margin:unset;
            
        }
        p{
            margin:10px 0;
        }
        padding:25px;
        box-sizing: border-box;
        border:6px solid $color-blue-gray;
        border-radius:10px;
    }
    
}

.hero-content, .final-content {
    text-align:center;
    width:100%;
    margin:0 auto;
    margin-bottom:40px;
    max-width:1100px;

    @include max-width($width-medium){
        margin:20px 0;
    }
    h1 {
        font-size:5rem;
        letter-spacing: -0.03em;
        margin:0;
        margin-bottom:20px;

        @include max-width($width-medium){
            font-size:1.8rem;
        }
    }

    h2 {
        font-weight: 300;
        margin: 8px 0 40px;
        text-transform: none;

        @include max-width ($width-large) {
            margin: 8px 0 24px;
        }
        @include max-width($width-medium){
            margin:0;
        }
    }

    hr {
        margin: 0;
        width: 82px;
        height: 2px;
        background: var(--link-color);
    }

    p {
        margin: 24px 0 0;

        @include max-width ($width-large) {
            margin: 16px 0 0;
        }
    }

    .button{
        margin-top:0;
    }

}

.final-content{
    margin-top:160px;
    margin-bottom:160px;

    @include max-width($width-medium){
        margin:80px 0;
    }
}

.categories {
    position: relative;
    padding: 32px 52px;

    @include max-width ($width-small) {
        padding:24px 0;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        background: $color-gray-bg;
        z-index: -1;
    }
}

.templates {
    text-align: center;
    padding: 32px 52px; 

    @include max-width ($width-medium) {
        width:100%;
        padding:0;
    }
    .catalog-grid {
        grid-gap:40px;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 80px;

        @include max-width($width-large){
            padding:0;
        }
        @include max-width ($width-small) {
            padding:0;
        }
    }

    > p {
        text-align: center;
    }

    h2{
        @include max-width($width-medium){
            font-size:1.25rem;
        }
    }
}
</style>
