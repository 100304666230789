<template>
    <div class="loginContainer">
        <h3>LOG IN</h3>

        <p class="signup" title="Submit">
            Don't have an account?
            <a @click="$emit('open-register')">Sign Up</a>
        </p>

        <div v-if="this.error" class="error">User info incorrect. Try again</div>

        <label><img src="@/assets/img/User.svg" /> Email</label>
        <input
            v-model="email"
            type="text"
            name="username"
            placeholder="Username"
        />

        <label><img src="@/assets/img/Lock.svg" /> Password</label>
        <div class="input-row">
            <input
                v-model="password"
                type="password"
                name="password"
                placeholder="Password"
            />
            <!-- <button class="reveal img-button"><img src="@/assets/img/Eye.svg" /></button> -->
        </div>
        <p class="forgot"><a @click="$emit('open-forgot')">Forgot Password?</a></p>

        <button class="login" @click="submitForm">Log In</button>
        <hr />
        <div class="social-buttons">
            <button @click="loginGoogle">Google</button>
            <button @click="loginFacebook">Facebook</button>
        </div>
    </div>
</template>

<script>
import api, { search } from '@/helpers/api';

export default {
    name: 'LoginPage',

    data() {
        return {
            submitted: false,
            error: false,
            email: '',
            password: '',
        };
    },

    methods: {

        async submitForm () {
            const { email, password } = this;

            if (email != '' && password != '') {
                this.error = false;
                this.submitted = true;

                try {
                    await api.auth.login(search({ email, password }));
                    if (this.$tenant?.homeName == 'Login'){
                        this.$router.push('/template/catalog');
                    }
                    this.$router.go();
                } catch (e) {
                    this.error = true;
                    console.error(e);
                }
            } else {
                this.error = true;
            }
        },

        loginGoogle () {
            this.$cookies.set('auth-path-restore', this.$route.path);
            window.location.href = api.auth.google;
        },

        loginFacebook () {
            this.$cookies.set('auth-path-restore', this.$route.path);
            window.location.href = api.auth.facebook;
        },
    },
};
</script>

<style scoped lang="scss">
.loginContainer {
    position: relative;

    .forgot {
        font-size: 14px;
        text-align: right;
    }

    hr {
        margin: 20px 0;
    }

    .social-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 50px;
        grid-gap: 10px;
        margin-top: 10px;

        button {
            margin: 0;
        }
    }
}
</style>
