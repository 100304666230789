<template>
    <div 
        class="inspector"             
        :style="{ 'width' : width }"
    >
        <inspector-tree
            :node="node"
            @highlight="$emit('highlight', $event)"
            @unhighlight="$emit('unhighlight', $event)"
            @add="handleClick('add', $event)"
            @delete="handleClick('delete', $event)"
        />
        <button class="close img-button" @click="$emit('close')"><img src="@/assets/img/Close.svg" width="24"></button>
    </div>
</template>

<script>
import InspectorTree from './InspectorTree.vue';
import { generateId } from '@/helpers/utils';

export default {
    components: { InspectorTree },
    props: [ '$svg' ],
    data () { return {
        open: false,
        node: null,
        width: window.innerWidth - 350,
    }; },

    mounted () {
        this.reload();
    },

    computed: {
        windowHeight () { return window.clientHeight; },
    },

    methods: {
        handleClick(type, $event){
            this.$emit(type, $event);
            this.reload();
        },

        reload () {
            this.node = this.buildNode(this.$svg);
        },

        includeNode ($el) {
            switch ($el.nodeName.toLowerCase()) {
                case 'defs': return $el.querySelector('text');
                case 'clippath': return $el.querySelector('text');
                case 'style': return false;
            }

            return true;
        },

        buildNode ($el, parent) {
            if (!this.includeNode($el)) { return null; }

            const node = {
                name: $el.nodeName,
                id: $el.id,
                uid: $el.id || generateId(12),
                content: $el.children.length == 0 && $el.textContent,
                $el,
                parent,
            };

            node.children = Array.from($el.children).map($child => this.buildNode($child, node));

            return node;
        },
    },
    watch:{
        $svg(){
            this.node = null;
            if(this.$svg){
                this.reload();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

.inspector {
    height:350px;
    position: absolute;
    left: 350px;
    bottom: 0;
    right: 0;
    overflow: scroll;
    background: white;
    padding-top: 10px;
    border-top:5px solid var(--link-color-active);
}

.close {
    position: absolute;
    top: 12px;
    right: 8px;
}
</style>

<style lang="scss">
@import '@/assets/css/colors';

.resizable-t {
    background: var(--link-color-active);
}
</style>
