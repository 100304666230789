<template>
    <popup title="Download" @close="$emit('close')">
        <h2>Please choose download format</h2>
        <radio-picker :options="formats" :selected="selectedFormat" @select="selectedFormat = $event" />
        <hr>
        <button @click="$emit('download', selectedFormat)">Download</button>
    </popup>
</template>

<script>
import Popup from '@/components/Popup.vue';
import RadioPicker from '@/components/RadioPicker.vue';

export default {
    components: { Popup, RadioPicker },
    props: [ 'media' ],
    data () { return {
        selectedFormat: 'png',
    }; },
    computed: {
        formats () {
            if (this.media == 'print') {
                return [ 'png', 'jpg', 'pdf' ];
            } else {
                return [ 'png', 'jpg', 'svg' ];
            }
        },
    },
};
</script>

<style scoped>
button {
    width: 100%;
}

hr {
    margin: 24px 0;
}
</style>
