
module.exports = {
    methods: {
        formatPrice (price) {
            const dollars = Math.floor(price / 100);
            const cents = price % 100;
            return `$${dollars}.${cents}`;
        },
    },
};
