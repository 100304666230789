<template>
    <div class="plans-page">
        <h1>Confirm your subscription</h1>
        <p>Choose your preferred pricing plan below!</p>
        <div class="plans" :class="{ disabled: hasPlan }">
            <div class="plan" :class="{ selected: plan == 'free' }" @click="changePlan('free')">
                <div class="title">Free</div>
                <div class="price"><span class="dollar">$ </span>0<span class="mo">/mo</span></div>
                <div class="features-header">Includes:</div>
                <ul class="features">
                    <li>Access to free templates</li>
                    <li>Download to your device</li>
                </ul>
                <div class="check"><img src="@/assets/img/ic_check_white.svg"></div>
            </div>
            <div class="plan" :class="{ selected: plan == 'monthly' }" @click="changePlan('monthly')">
                <div class="title">Monthly Pro</div>
                <div class="price"><span class="dollar">$ </span>17.50<span class="mo">/mo</span></div>
                <div class="features-header">Includes:</div>
                <ul class="features">
                    <li>Access to all templates</li>
                    <li>Post to social media</li>
                    <li>Schedule social media posts</li>
                </ul>
                <div class="check"><img src="@/assets/img/ic_check_white.svg"></div>
            </div>
            <div class="plan" :class="{ selected: plan == 'yearly' }" @click="changePlan('yearly')">
                <div class="title">Annual Pro</div>
                <div class="price"><span class="dollar">$ </span>14.00<span class="mo">/mo</span></div>
                <div class="features-header">Includes:</div>
                <ul class="features">
                    <li>Access to all templates</li>
                    <li>Post to social media</li>
                    <li>Schedule social media posts</li>
                </ul>
                <div class="check"><img src="@/assets/img/ic_check_white.svg"></div>
                <div class="discount">50% OFF</div>
            </div>
        </div>
        <div v-if="renewDate && !endDate">Your subscription will renew on {{renewDate}}</div>
        <div v-if="endDate">Your subscription will end on {{endDate}}</div>
        <button v-if="plan !== 'free'" @click="onClick">{{ buttonText }}</button>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import api from '@/helpers/api';
import userMixin from '@/mixins/user';

export default {
    mixins: [ userMixin ],

    data () { return {
        plan: 'free',
        subscription: null,
    }; },

    async mounted () {
        await this.ensureUser();

        if (this.$route.query.cancelled) {
            this.$toast.error(
                'Payment was cancelled. Please select a plan again if you want to subscribe.',
                { timeout: null },
            );
        }

        if (this.$route.query.plan) {
            this.plan = this.$route.query.plan;
        }

        if (this.user) {
            this.subscription = this.user.subscription;
            if (this.subscription) {
                this.plan = this.subscription.periodType;
            }
        }
    },

    computed: {
        buttonText () {
            if (this.subscription) {
                if (this.subscription.cancelAt) {
                    return 'Reactivate Subscription';
                } else if (this.subscription.status == 'active') {
                    return 'Cancel Subscription';
                }
            }

            if (!this.authenticated) {
                return 'Login / Register';
            }

            return 'Go to Checkout';
        },

        hasPlan () {
            return this.subscription && this.subscription.status == 'active';
        },

        renewDate () {
            if (this.hasPlan) {
                return dayjs(this.subscription.currentPeriodEnd).format('MMMM DD, YYYY');
            } else {
                return null;
            }
        },

        endDate () {
            if (this.subscription && this.subscription.cancelAt) {
                return dayjs(this.subscription.cancelAt).format('MMMM DD, YYYY');
            } else {
                return null;
            }
        },
    },

    methods: {

        changePlan (plan) {
            if (!this.hasPlan) {
                this.plan = plan;
            }
        },

        async onClick () {
            if (!this.authenticated) {
                this.$emit('login-clicked');
            } else if (this.subscription && this.subscription.status == 'active') {
                if (this.subscription.cancelAt) {
                    await api.subscription.subscribe(this.plan);
                } else {
                    await api.subscription.unsubscribe();
                }

                this.$router.go(0);
            } else {
                const url = (await api.subscription.subscribe(this.plan)).data;
                window.location.href = url;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.plans-page {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    margin-bottom:40px;
}

h1 {
    margin: 24px 0 8px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
}

h2 {
    font-size: 18px;
    color: var(--link-color);
}

p {
    margin: 0 0 24px;
    font-size: 16px;
}

ul {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.plans {
    display: flex;
    margin: 32px 0;

    @include max-width($width-medium){
        flex-direction: column;
        gap:20px;
        align-items:center;;
    }

    .plan {
        cursor: pointer;
    }

    &.disabled {

        .plan {
            cursor: default;
        }
    }
}

.plan {
    display:flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    margin: 0 16px;
    padding: 48px;
    text-align: center;
    border: 1px solid $color-gray-light;
    cursor: pointer;
    gap:10px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        background: var(--link-color);
    }

    @include max-width($width-large){
        font-size:0.7rem;
        gap:10px;
    }
    @include max-width($width-medium){
        font-size:1rem;
    }
    @include max-width($width-small){
        flex-direction: column;
        align-items:center;
        padding:0;
        width:80%;
        padding:40px;
        box-sizing: border-box;
    }

    .title {
        margin: 0 0 8px;
        color: var(--link-color);
        font-size:1.5rem;
        font-weight: 600;

        @include max-width($width-large){
            font-size:1.1rem;
        }
        @include max-width($width-medium){
            font-size:2rem;
        }
    }

    .price {
        color: $color-gray-dark;
        font-size:2.4rem;

        @include max-width($width-large){
            font-size:1.1rem;
        }
        @include max-width($width-medium){
            font-size:2rem;
        }
    }

    .features-header {
        margin-top: 8px;
    }

    .features {
        padding: 0;
        list-style: none;
    }

    .check {
        display:flex;
        margin: 32px auto 0;
        position: relative;
        width: 32px;
        height:100%;
        justify-content: flex-end;
        align-items:flex-end;

        img {
            background: $color-gray-medium;
            padding:5px;
            box-sizing: border-box;
            border: 2px solid $color-gray-medium;
            border-radius: 50%;
            height:32px;
            width:32px;
        }
    }

    .discount {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -4px;
        margin-right: -4px;
        padding: 4px 8px;
        background: $color-red-light;
        color: white;
        border-radius: 6px;
        font-size: 15px;
    }

    &.selected {
        background: $color-gray-bg;

        .title {
            color: $color-gray-medium;
        }

        .check {
            img{
                border: none;
                background: var(--link-color);
            }
            
        }
    }
}

button {
    margin: 24px 0 0;
}

</style>
