<template>
    <popup title="Pro Plan Required" @close="$emit('close')">
        <h2>Please upgrade to a Pro Plan to continue</h2>
        <p>Pro Plans give you unlimited access to all templates, as well as social media features.</p>
        <router-link to="/plans" class="button">See Plans</router-link>
    </popup>
</template>

<script>
import Popup from '@/components/Popup.vue';

export default {
    components: { Popup },
};
</script>

<style>

</style>
