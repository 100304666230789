<template>
    <div>
        <dropload @drop="onFile"></dropload>
        <div ref="svgs"></div>
    </div>
</template>

<script>
import Dropload from '@/components/Dropload.vue';

export default {
    components: { Dropload },
    methods: {
        onFile (file) {
            const fr = new FileReader();
            fr.readAsText(file);

            fr.onload = () => {
                if (file.name.endsWith('.ret')) {
                    this.import(JSON.parse(fr.result));
                } else {
                    this.addSvg(fr.result);
                }
            };
        },

        import (data) {
            for (const svg of data.svgs) {
                this.addSvg(svg);
            }

            console.log(this.name, this.media, this.keywords, this.slides);
        },

        addSvg (text) {
            const placeholder = document.createElement('div');
            placeholder.innerHTML = text;
            this.$refs.svgs.appendChild(placeholder);
        },
    },
};
</script>

<style>

</style>
