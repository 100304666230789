<template>
    <!-- Entire container is clickable, clicking outside of the popup itself will close the popup -->
    <div class="popup-container" @click.self="$emit('close')">
        <div class="popup">
            <header>
                <h2>{{ title }}</h2>
                <button @click="$emit('close')"><img src="@/assets/img/Close.svg" alt="Close"></button>
            </header>
            <div class="popup-content">
                <!-- Content is populated using a slot -->
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Popup',
    props: [ 'title' ],
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/colors';

.popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb($color-black, 0.45);
    z-index: 10;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 600px;
    transform: translate(-50%, -50%);
    background: white;

    @include max-width($width-small){
        display:flex;
        flex-direction:column;
        min-width:unset;
        width:100%;
        min-height:100%;
    }
}

header {
    display: flex;
    height: 56px;
    justify-content: space-between;
    background: $color-gray-bg;

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 18px 20px 16px;
    }

    button {
        width: 56px;
        height: 56px;
        padding: 20px;
        background: none;
        border: none;
    }
}

.popup-content {
    padding: 48px 48px 56px;

    @include max-width($width-small){
        padding:20px;
    }
}
</style>
