<template>
    <input @change="$emit('update-opacity', opacity)" type="number" placeholder="1.0" step="0.01" min="0" max="1" v-model="opacity"/>
</template>

<script>
export default {
    props:[ 'initialOpacity' ],
    data(){
        return{
            opacity:1,
        };
    },
    mounted(){
        this.opacity = this.initialOpacity;
    },
};
</script>

<style lang="scss" scoped>

</style>
