<template>
    <popup class="create-dimension-popup" title="Create New Dimension" @close="$emit('close')">
        <radio-picker :options="[ 'social', 'print' ]" :selected="media" @select="$emit('change-media', $event)" />
        <editor-text :title="null" :text="dimensionName" @text-input="dimensionName = arguments[0]" />
        <div class="measure">
            <input type="number" v-model="width">
            <span>x</span>
            <input type="number" v-model="height">
        </div>
        <radio-picker :options="availableUnits" :selected="unit" @select="unit = $event" />
        <button @click="save">Save</button>
        <button @click="$emit('close')">Cancel</button>
    </popup>
</template>

<script>
import EditorText from '@/components/EditorText.vue';
import Popup from '@/components/Popup.vue';
import RadioPicker from '@/components/RadioPicker.vue';
import api from '@/helpers/api';

export default {
    components: { EditorText, Popup, RadioPicker },

    props: [ 'media' ],

    data () { return {
        dimensionName: 'Name of New Dimension',
        width: 1,
        height: 1,
        unit: this.media == 'social' ? 'ratio' : 'inches',
    }; },

    computed: {
        availableUnits () {
            if (this.media == 'social') {
                return [ 'pixels', 'ratio' ];
            } else if (this.media == 'print') {
                return [ 'inches', 'centimeters' ];
            } else {
                return [];
            }
        },
    },

    methods: {

        async save () {
            await api.dimensions.create({
                name: this.dimensionName,
                width: this.width,
                height: this.height,
                unit: this.unit,
            });
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.create-dimension-popup {

    .measure span {
        margin: 0 8px;
    }

    input {
        margin: 0 0 8px;
    }

    .radio-picker {
        margin: 0 0 16px;
    }

    button {
        margin: 0 4px 0 0;
    }
}
</style>
