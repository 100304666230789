<template>
    <div class="zoom-slider">
        <div>Zoom - {{ value }}%</div>
        <input 
            type="range" 
            :value="value" 
            min="0"
            max="250" 
            @input="zoomChange" 
            @mousedown="$emit('handle-zoom', { type:'start', $event })" 
            @mousemove="$emit('handle-zoom', { type:'move', $event })" 
            @mouseup="$emit('handle-zoom', { type:'end', $event })" 
        />
    </div>
</template>

<script>

export default {
    props: [ 'id', 'index', 'value', 'isInitial' ],
    name: 'zoomSlider',
    data() {
        return {
            zoom: undefined,
        };
    },
    methods: {
        zoomChange($event){
            this.zoom = $event.target.value;
            this.$emit('handle-zoom', { type: 'zoom', $event: { zoom: this.zoom, index: this.index } });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/colors';

.zoom-slider{
    input{
        width: 100%;
        padding:0;
    }
}
</style>
