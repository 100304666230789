<template>
    <div v-if='this.user' class="social-posts-page">
        <h2>Social Posts Calendar</h2>
        <calendar class="social-posts-calendar" 
                  is-expanded trim-weeks 
                  :attributes="attributes" 
                  :masks="masks" @update:to-page="onPageChange"
        >
            <template v-slot:day-content="{ day, attributes }">
                <span class="day-label">{{ day.day }}</span>
                <button v-if="attributes && attributes.length" class="day-posts" @click="onSelectDay(day)">
                    {{ attributes.length }} 
                    <span class="desktop-posts">posts</span>
                </button>
            </template>
        </calendar>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import Calendar from 'v-calendar/lib/components/calendar.umd';

import api from '@/helpers/api';
import resizeMixin from '@/mixins/resize';
import userMixin from '@/mixins/user';

dayjs.extend(objectSupport);

export default {
    mixins: [ resizeMixin, userMixin ],
    components: { Calendar },
    computed: { 
        masks(){
            if(this.windowWidth < this.$breakPoints['width-medium']){
                return {weekdays: 'W'};
            }
            return {weekdays: 'WWWW'};
        },
    },
    data: () => ({
        attributes: [],
        // Updated in onPageChange
        minDate: '',
        maxDate: '',
    }),

    async mounted () {
        await this.$nextTick();  
        await this.ensureUser();
        
        if (this.user) {
            this.refreshPosts();
        } else {
            this.$router.push('/');
        }
    },

    methods: {

        onPageChange (page) {

            const minDate = dayjs({ year: page.year, month: page.month - 1 });
            const maxDate = minDate.endOf('month');
            
            this.minDate = minDate.toISOString();
            this.maxDate = maxDate.toISOString();

            this.refreshPosts();
        },

        async refreshPosts () {
            const { minDate, maxDate } = this;
            const q = { minDate, maxDate };
            if(this.$tenant){ q.tenant = this.$tenant.id; }
            
            const posts = (await api.social.getPosts(q)).data;
            this.attributes = posts.map(post => ({
                key: post._id,
                post,
                dates: new Date(post.date),
            }));
        },

        onSelectDay (day) {
            this.$router.push('/user/social/' + dayjs(day.date).format('YYYY-MM-DD'));
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.social-posts-calendar.vc-container {
    margin-bottom: 72px;
    border: none;
    font-family: "Montserrat", sans-serif;

    .vc-arrows-container {
        justify-content: center;

        .vc-arrow {
            margin: 0 72px;
        }
    }

    .vc-day {
        height: 88px;
        border: 1px solid $color-gray-light;
        padding:0 10px;
        
        @include max-width($width-medium){
            padding:0 5px;
        }
        @include max-width($width-medium){
            padding:0 2.5px;
        }
        .day-label {
            display:flex;
            justify-content: center;
            align-items:center;
            position: absolute;
            right: 6px;
            bottom: 6px;
            width:25px;
            height:25px;
            border-radius:100%;
            @include max-width($width-small){
                width:17px;
                height:17px;
                bottom:4px;
                right:4px;
                font-size:10px;
            }
        }
        .day-posts {
            width: calc(100% - 4px);
            height: 32px;
            margin: 6px 2px;
            padding: 6px;

            .desktop-posts{
                @include max-width($width-small){
                    display:none;
                }
            }
        }
        &.is-today{
            .day-label{
                color:white;
                background:var(--link-color-active);
            }
        }
    }
}
</style>
