<template>
    <masonry :cols="cols" ref="grid" class="catalog-grid">
        <catalog-tile 
            v-for="template in templates" :key="template._id"
            :template="template" 
            :favorites="favorites" 
            @add-favorite="addFavorite" 
            @remove-favorite="removeFavorite" 
        />
    </masonry>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import CatalogTile from './CatalogTile.vue';

import api from '@/helpers/api';
import templatesMixin from '@/mixins/templates';
import userMixin from '@/mixins/user';

export default {
    name: 'CatalogGrid',
    props: [ 'templates', 'page' ],
    mixins: [ templatesMixin, userMixin ],
    components:{
        InlineSvg,
        CatalogTile,
    },
    data () {
        return {
            favorites: [],
            reset: false,
            cols: {
                default: 4,
                1200: 3,
                1050: 2,
                850: 2,
                600: 1,
            },
        };
    },
    async mounted(){
        await this.ensureUser();

        if(this.user){
            this.getFavorites();
        }
    },
    methods: {
        async getFavorites(){
            if(this.user){
                await this.refreshUser();
                this.favorites = this.user.favorites;
            }
        },
        
        async addFavorite(id){
            await api.users.addFavorite(id);
            await this.getFavorites();
        },  
        
        async removeFavorite(id){
            await api.users.removeFavorite(id);
            await this.getFavorites();
        },

        checkFavorite(id){
            if(this.favorites.includes(id)){
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/colors';
.catalog-grid{
    opacity:1;
    &.hidden{
        opacity:0;
    }
}
</style>
