<template>
    <div class="color-selector">
        <template v-if="!free">
            <div v-for="(color, index) in currentColors" :key="color + index" class='color-container'>
                <label
                    @click.stop="selectColor(color, index)"
                    @contextmenu.prevent="$refs.menu[index].open"
                    class="color-option"
                    :class="disabled ? 'disabled' : ''"
                    :data-active="activeIndex == index"
                    :style="{ background: color }"
                >
                </label>
            
                <!-- Keep this in mind when moving to Vue 3 -->
                <context-menu :id="'ctx-' + id" ref="menu" v-if="editable">
                    <li @click="removeColor(index)">Remove</li>
                </context-menu>

                <color-popup
                    class="color-popup"
                    ref="picker"
                    v-if="(editable && activeIndex === index && showPopUp) || (free && activeIndex === index && showPopUp)"
                    :initial-color="currentColors[index]"
                    @hide="hide"
                    @update="updateColor($event, index)"
                >
                </color-popup>
            </div>
        </template>
        <template v-else>
            <div class="color-container">
                <label
                    @click.stop="selectColor(initialColor, 0)"
                    @contextmenu.prevent="$refs.menu[0].open"
                    class="color-option"
                    :class="disabled ? 'disabled' : ''"
                    :data-active="true"
                    :style="{ background: initialColor }"
                >
                </label>

                <context-menu :id="'ctx-' + id" ref="menu" v-if="editable">
                    <li @click="removeColor(index)">Remove</li>
                </context-menu>

                <color-popup
                    class="color-popup"
                    ref="picker"
                    v-if="(editable && activeIndex === 0 && showPopUp) || (free && activeIndex === 0 && showPopUp)"
                    :initial-color="initialColor"
                    :color-history="colorHistory"
                    @handle-color="$emit('handle-color', {color:$event})"
                    @hide="hide"
                    @update="updateColor($event, 0)"
                >
                </color-popup>
            </div>
        </template>
        <button v-if="editable" @click="addColor()" class="color-option">
            <img src="@/assets/img/plus.svg" />
        </button>
    </div>
</template>

<script>
import ContextMenu from 'vue-context-menu';
import ColorPopup from './ColorPopup.vue';
import { generateId } from '@/helpers/utils';


export default {
    name: 'colorPicker',
    components: { ContextMenu, ColorPopup },
    props: {
        id: { type: String, default: () => generateId(8) },
        editable: { type: Boolean, default: false },
        type: { type: String, default: 'Color' },
        initialColor: { type: String },
        saving: { type: Boolean },
        options: { type: Array },
        value: { type: String },
        free: { type: Boolean, default: false },
        colorHistory: { type: Array },
    },
    data() {
        return {
            activeIndex: this.options.indexOf(this.initialColor),
            currentColors: this.options,
            disabled: false,
            showPopUp: false,
        };
    },
    mounted(){
    },
    methods: {
        hide(){
            this.showPopUp = false;
        },

        selectColor(color, index) {
            if(color == 'rgba(255, undefined, undefined, 1)'){
                color = 'rgba(255, 255, 255, 1)';
                if(this.editable){
                    this.$set(this.currentColors, index, color);
                    this.$emit('colors', this.currentColors);
                }
            }
            this.activeIndex = index;
            this.showPopUp = true;
            this.$emit('set-color', {color: color});
        },

        updateColor(color, index) {
            this.activeColor = color;
            this.$set(this.currentColors, index, color);    
            this.$emit('colors', this.currentColors);
            this.$emit('set-color', {color: this.activeColor});
        },

        addColor(color = 'rgba(255, 255, 255, 1)') {
            this.currentColors.push(color);
            this.activeColor = color;
            this.activeIndex = this.currentColors.length - 1;
            this.$emit('colors', this.currentColors);
            this.$emit('set-color', {color: this.activeColor});
        },

        removeColor(index) {
            this.currentColors.splice(index, 1);
            this.activeColor = this.currentColors[0];
            this.activeIndex = 0;
            this.$emit('colors', this.currentColors);
            this.$emit('set-color', {color: this.activeColor});
        },
    },
    watch:{
        value(){
            if(this.value){
                this.activeIndex = this.options.indexOf(this.value);
            }else{
                this.activeIndex = 0;
            }
            this.activeColor = this.options[this.activeIndex];
            this.$emit('set-color', {color: this.activeColor});
        },
        options(){
            this.currentColors = this.options;
            if(this.editable == false){
                this.activeIndex = this.options.length - 1;
            }
        },
        saving(){
            if(this.saving && this.options.length > 0){
                this.selectColor(this.currentColors[0], 0);
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/colors';

.color-selector {
    display: flex;
    height: 40px;
    border: 1px solid var(--link-color-active);
    border-radius: 6px;
    padding: 9px;
    background: white;
    box-sizing: border-box;

    .color-container{
        position:relative;
        padding:unset;
        
        .color-popup{
            position:absolute;
            top:25px;
            z-index:10;
        }
    }

    .color-option {
        position: relative;
        display: flex;
        align-items:center;
        justify-content: center;
        height: 20px;
        width: 20px;
        margin: 0 10px 0 0;
        padding: 1px;
        background: none;
        outline: 3px solid $color-gray;
        outline-offset:2px;
        box-sizing: border-box;
        user-select: none;
        cursor:pointer;

        &:hover {
            outline-color: $color-red-light;
        }

        &[data-active='true'] {
            outline-color: $color-gray-dark;
        }

        &.disabled{
            pointer-events:none;
        }
    }
}



input[type='color'] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-appearance: none;
}

.ctx-menu li {
    padding-left: 8px;
    cursor: pointer;
}
</style>
