<template>
    <div class="dropdown">
        <div class="current" :class="{ open }" @click="open = !open">{{ current }}</div>
        <ul v-if="open" @click="open = false">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    props: [ 'current' ],
    data () { return {
        open: false,
    }; },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

.dropdown {
    position: relative;
    cursor: pointer;

    .current {
        width: 100%;
        height: 40px;
        padding: 9px 10px 6px;
        background: white;
        border: 1px solid $color-gray;
        border-radius: 6px;
        box-sizing: border-box;

        &.open {
            border-radius: 6px 6px 0 0;
        }
    }

    ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 260px;
        margin: 0;
        padding: 0;
        background: white;
        border: 1px solid $color-gray;
        border-radius: 0 0 6px 6px;
        list-style: none;
        overflow: scroll;
        box-sizing: border-box;
    }

    li {
        margin: 4px 0;
        padding: 4px 10px;
        display: flex;
        align-items: center;

        &:hover {
            background: $color-gray;
        }
    }
}
</style>
