<template>
    <aside>
        <div>
            <div  v-if="$route.name == 'projectEdit' || $route.name == 'projectNew'" class="fields-nav">
                <button v-if="hasEditable('text')" @click="$emit('set-active','text')" :class="active == 'text' ? 'active' : ''">Text</button>
                <button v-if="hasEditable('image')" @click="$emit('set-active','image')" :class="active == 'image' ? 'active' : ''">Image</button>
                <button v-if="hasEditable('color')" @click="$emit('set-active','color')" :class="active == 'color' ? 'active' : ''">Color</button>
            </div>
            <slot></slot>
        </div>
    </aside>
</template>

<script>

export default {
    props: {
        active: { type: String, default: '', required: false },
        editables: { required: false},  
    },
    name: 'Sidebar',
    methods:{
        hasEditable(type){
            if(type == 'text'){
                return this.editables.filter($el => $el.type == 'text' || $el.type == 'multiline').length > 0;
            }
            if(type == 'image'){
                return this.editables.filter($el => $el.type == 'image').length > 0;
            }
            if(type == 'color'){
                return this.editables.filter($el => $el.type == 'color').length > 0;
            }
            return false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

aside {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 350px;
    height: 100%;
    background: var(--template-side-bg);
    border-right:2px solid var(--template-side-border-color);
    overflow-y: auto;

    @include max-width($width-medium){
        width:100%;
        grid-row:2;
    }

    .fields-nav{
        display:flex;
        width:100%;
        justify-content: space-evenly;
        
        button{
            width:100%;
            background:unset;
            color: $color-black;
            border:unset;
            border-bottom:2px solid $color-gray;
            border-radius:0;
            box-sizing:border-box;
            transition:0.25s ease-in-out;

            &.active{
                color: var(--link-color-active);
                border-color: var(--link-color-active);
                width:100%;
            }
        }
    }

    div{
        position:relative;

        .editable-hitbox{
            height:100%;
            width:100%;
            position:absolute;
            top:0;
            left:0;
        }
    }
}
</style>
