import { render, staticRenderFns } from "./Dropload.vue?vue&type=template&id=60cba578&scoped=true&"
import script from "./Dropload.vue?vue&type=script&lang=js&"
export * from "./Dropload.vue?vue&type=script&lang=js&"
import style0 from "./Dropload.vue?vue&type=style&index=0&id=60cba578&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60cba578",
  null
  
)

export default component.exports