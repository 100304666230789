<template>
  <div>Logging out</div>
</template>

<script>
import api from '@/helpers/api';
export default {
    async mounted(){
        await api.auth.logout().then(()=>{
            this.$router.push('../');
            this.$router.go();
        });
    },
};
</script>

<style>

</style>
