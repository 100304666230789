import './assets/css/main.scss';
import 'vue-toastification/dist/index.css';
import './helpers/analytics';

import { debounce } from 'lodash';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueMasonry from 'vue-masonry-css';
import VueToastification from 'vue-toastification';

import App from './App.vue';
import router from './router';
import tenants from './tenants';

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueMasonry);
Vue.use(VueToastification, { timeout: false });

Vue.prototype.$global = Vue.observable({ user: null });
Vue.prototype.$breakPoints = {
    'min-width': 320,
    'width-small': 600,
    'width-medium': 900,
    'width-large': 1200,
    'width-huge': 1600,
};

Vue.prototype.$tenant = (() => {
    const tenantId = process.env.VUE_APP_TENANT_OVERRIDE || window.location.host.split('.')[0];
    return tenants[tenantId];
})();

Vue.prototype.$tenant?.stylesheet();

new Vue({
    router: router(Vue.prototype.$tenant),
    render: h => h(App),
    created () {
        this.networkErrorCaptured = debounce(this.networkErrorCaptured, 500);
    },
    errorCaptured (e, vm) {
        if (e.response) {
            this.networkErrorCaptured(e, vm);
        } else {
            throw e;
        }
    },
    methods: {
        networkErrorCaptured (e, vm) {
            if (e.response.status == 429) {
                vm.$toast.error('Too many requests, please try again in a minute!');
            } else {
                vm.$toast.error('Network error.');
            }
        },
    },
}).$mount('#app');


