<template>
    <div class="how-page">
        <div class="hero">
            <div class="hero-content">
                <h1 class="bolder">We're Real Estate marketing made&nbsp;easy</h1>
                <hr>
                <p>
                    Real Estate Creates is an easy-to-use tool that empowers agents with professional marketing and promotional material. 
                    Choose from a growing collection of professionally designed templates to produce your own customized listings, promotions, flyers, brochures, and social media posts on demand. 
                </p>
                <p>
                    It's quick and intuitive; simply upload your own photos and personalized text to the template of your choice to produce any given promotion. 
                    Reuse the same template time and again to present a unified brand image or explore various designs for your different promotional needs. With Real Estate Creates, you're in control.
                </p>
            </div>
            <div class="hero-video-container">
                <div class="hero-video">
                    <iframe 
                        src="https://www.youtube-nocookie.com/embed/XVURdhqWNPs" 
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
        <div class="get-started">
            <h1 class="bolder">
                The choice is simple
            </h1> 
            <p class="head bold">
                Real Estate Creates is the competitive edge you need to elevate your agency.
            </p>
            <div class="info-container">
                <div class="info-tile">
                    <inline-svg :src="require('@/assets/img/doityourself.svg')" />
                    <h6>EASY TO USE</h6>
                    <p>
                        It’s never been easier to personally promote your Real Estate brand by taking control of your own content. 
                        Create and share sleek professional promotions in a matter of minutes with no prior design experience or skill necessary.
                    </p>
                </div>
                <div class="info-tile">
                    <inline-svg :src="require('@/assets/img/savemoney.svg')" />
                    <h6>SAVE TIME & MONEY</h6>
                    <p>
                        In Real Estate, we know that time is money and Real Estate Creates will save you both. 
                        Maximize your profits by bypassing pricey marketing firms while delivering the same professional content to your market in the time it takes you to brew a cup of coffee.
                    </p>
                </div>
                <div class="info-tile">
                    <inline-svg :src="require('@/assets/img/templates.svg')" />
                    <h6>PRODUCE RESULTS</h6>
                    <p>
                        In an industry fraught with poor and out-dated design, let us help you market and promote yourself so that you can focus on what you do best: Real Estate. 
                        Our professionally designed templates will generate more clients and faster sales by bringing promotional excellence to your agency.
                    </p>
                </div>
            </div>
            <button @click="redirect">Get started for free!</button>
        </div>
        <!-- <Testimony /> -->
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import Testimony from '@/components/Testimony.vue';
export default {
    components: {
        InlineSvg,
        Testimony,
    },
    methods: {
        redirect(){
            this.$router.push({name: 'Catalog'});
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';
.bold{
    font-weight:500;
}
.bolder{
    font-weight:600;
}

.hero {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 32px 24px;

    @include max-width ($width-small) {
        padding: 16px 0;
    }

    > * {
        width: 50%;
        padding: 32px 52px;
        box-sizing: border-box;

        @include max-width ($width-large) {
            padding: 28px 24px;
        }

        @include max-width ($width-medium) {
            width: 100%;
            padding: 16px 0;
        }
    }

    .hero-video {
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .hero-content {
        h1 {
            margin: 0 0 10px;
        }

        h2 {
            font-weight: 300;
            margin: 8px 0 40px;
            text-transform: none;

            @include max-width ($width-large) {
                margin: 8px 0 24px;
            }
        }

        hr {
            margin:20px 0;
            width: 82px;
            height: 2px;
            background: var(--link-color);
        }

        .button {
            margin: 0 8px 6px 0;

            @include max-width($width-small){
                width:100%;
                text-align:center;
            }
        }

        p {
            margin: 24px 0 0;

            @include max-width ($width-large) {
                margin: 16px 0 0;
            }

            &.bolder{
                font-weight:500;
            }
        }
    }
}
.get-started{
    position:relative;
    padding:48px 0;
    text-align:center;

    @include max-width ($width-medium) {
        padding:48px 0;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        background: #f5f5f5;
        z-index: -1;
    }
    h1{
        color:var(--link-color-active);
        text-transform:uppercase;
        max-width:650px;
        margin-left:auto;
        margin-right:auto;
    }
    .head{
        font-weight:500;
        font-size:16px;
        line-height:24px;
        max-width:650px;
        margin:0 auto;
    }
    .info-container{
        display:flex;
        align-items:Center;
        justify-content:center;
        width:100%;
        margin-top:20px;

        @include max-width ($width-medium) {
            flex-direction:column;
            margin-top:40px;
        }
        .info-tile{
            display:grid;
            grid-template-rows:auto 50px 1fr;
            justify-items: center;
            align-items:center;
            margin:0 20px;
            max-width:300px;

            h6{
                margin:0;
            }
            p{
                margin:0;
                color:$color-gray-dark;
            }
            svg{
                height:100px;
            }
            @include max-width ($width-medium) {
                max-width:100%;
                margin-bottom:40px;
            }
        }
    }
    button{
        margin-top:40px;
        @include max-width ($width-medium) {
            max-width:100%;
            margin-top:20px;
        }
    }
}
</style>
