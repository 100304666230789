<template>
    <div v-if="visible">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        initialVisible: Boolean,
        dismissable: Boolean,
    },
    data(){
        return {
            visible: true,
        };
    },
    methods: {
        show () { 
            this.visible = true; 
        },
        hide () { 
            this.visible = false; 
        },
    },
};
</script>
