<template>
    <div v-if="user" class="favorites-container">
        <tile v-for="item in user.favorites" :key="item">
            <template #image><img :src="thumbs[item]" /></template>
            <template #smallBtn>
                <button class="smallBtn" @click="removeFavorite(item)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6329 6.64686C21.3187 5.91945 20.8657 5.26027 20.2993 4.70624C19.7324 4.15055 19.064 3.70895 18.3305 3.40545C17.5699 3.0895 16.7541 2.92778 15.9305 2.92967C14.7751 2.92967 13.6477 3.24608 12.668 3.84374C12.4337 3.9867 12.211 4.14374 12.0001 4.31483C11.7891 4.14374 11.5665 3.9867 11.3321 3.84374C10.3524 3.24608 9.22506 2.92967 8.06959 2.92967C7.23756 2.92967 6.43131 3.08905 5.66959 3.40545C4.93365 3.71014 4.27037 4.14842 3.70084 4.70624C3.13365 5.25965 2.68056 5.91898 2.36725 6.64686C2.04147 7.40389 1.87506 8.2078 1.87506 9.03514C1.87506 9.81561 2.03444 10.6289 2.35084 11.4562C2.61569 12.1476 2.99537 12.8648 3.48053 13.589C4.24928 14.7351 5.30631 15.9305 6.61881 17.1422C8.79381 19.1508 10.9477 20.5383 11.0391 20.5945L11.5946 20.9508C11.8407 21.1078 12.1571 21.1078 12.4032 20.9508L12.9587 20.5945C13.0501 20.5359 15.2016 19.1508 17.379 17.1422C18.6915 15.9305 19.7485 14.7351 20.5173 13.589C21.0024 12.8648 21.3844 12.1476 21.6469 11.4562C21.9633 10.6289 22.1227 9.81561 22.1227 9.03514C22.1251 8.2078 21.9587 7.40389 21.6329 6.64686Z" fill="#25A1E8"/>
                    </svg>
                </button>
            </template>
            <template #cta>
                <a class="button" @click="onSelect(item)">
                    Start Project
                </a>
            </template>
        </tile>
    </div>
</template>

<script>

import AppButton from '@/components/AppButton.vue';
import Tile from '@/components/Tile.vue';
import api from '@/helpers/api.js';
import userMixin from '@/mixins/user';

export default {
    components: {
        AppButton,
        Tile,
    },

    mixins: [ userMixin ],

    data(){
        return {
            favorites: [],
            thumbs: {},
        };
    },

    async mounted(){
        await this.$nextTick();  
        await this.ensureUser();
        
        if (this.user) {
            this.initialize();
        } else {
            this.$router.push('/');
        }
    },

    methods: {
        //clean this all up
        async initialize(){
            this.populateImages();
        },

        async removeFavorite(id){
            await api.users.removeFavorite(id);
            this.initialize();
        },

        onSelect (item) {
            this.$router.push('/template/' + item + '/edit');
        },

        async populateImages () {
            for (const favorite of this.user.favorites) {
                new Promise((resolve) => {
                    resolve(api.templates.getById(favorite));
                }).then((response)=>{
                    this.$set(this.thumbs, favorite, response.data.thumbUrl);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils';

    .favorites-container{
        display:flex;
        flex-wrap:wrap;
        width:100%;
        padding-top:25px;
        padding-bottom:35px;

        @include max-width ($width-small) {
            justify-content: center;
        }
    }
</style>
