<template>
    <ul class="checklist">
        <slot></slot>
    </ul>
</template>

<script>

export default {
    name: 'checklist-view',
};
</script>

<style lang="scss" scoped>
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

li {
    display: block;
    margin: 5px 0;

    span {
        font-size: 14px;
        line-height: 21px;
        vertical-align: 2px;
        margin: 0 10px;
    }
}
</style>
