import axios from 'axios';

export const BASE_URL = process.env.VUE_APP_IS_STAGING ?
    process.env.VUE_APP_API_STAGING_URL : process.env.VUE_APP_API_BASE_URL;

const http = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

export default {
    auth: {
        register: (user) => http.post('/auth/register', user),
        login: (user) => http.post('/auth/login', user),
        logout: () => http.get('/auth/logout'),
        google: BASE_URL + '/auth/google?origin=' + encodeURIComponent(window.location.origin),
        googleCallback: query =>
            http.get('/auth/google/callback' + query + '&origin=' + encodeURIComponent(window.location.origin)),
        facebook: BASE_URL + '/auth/facebook?origin=' + encodeURIComponent(window.location.origin),
        facebookCallback: query => http.get('/auth/facebook/callback' + query),
        passwordReset: email => http.post('/auth/password-reset', { email }),
        passwordResetConfirm: (key, password) => http.post('/auth/password-reset/confirm', { key, password }),
    },
    categories: {
        create: category => http.post('/categories', category),
        get: (q) => http.get('/categories'+ ((q && ('?' + query(q))) || '')),
        addSubcategory: (categoryId, subcategoryId) =>
            http.post(`/categories/${categoryId}/categories`, { subcategoryId }),
    },
    contact:{
        sendEmail: (data) => http.post('/contact', data),
    },
    dimensions: {
        create: dimension => http.post('/dimensions', dimension),
        get: () => http.get('/dimensions'),
        getById: (id) => http.get('/dimensions/' + id),
    },
    users: {
        get: (id) => http.get('/users/' + id),
        update: (user) => http.put('/users/me', user),
        addFavorite: (id) => http.post('/users/me/favorites/' + id),
        removeFavorite: (id) => http.delete('/users/me/favorites/' + id),
        verifyEmail: () => http.post('/users/me/email/verify'),
        verifyEmailConfirm: (key) => http.post('/users/me/email/verify/confirm', { key }),
        addColor: (color) => http.post('/users/me/color-history/', { color }),
    },
    templates: {
        create: template => http.post('/templates', template),
        update: (id, template) => http.put('/templates/' + id, template),
        incrementDownloads: (id) => http.put('/templates/' + id + '/download'),
        delete: (id) => http.delete('/templates/' + id),
        get: (page, limit, query) => http.get('/templates/' + page + '/' + limit + '/?' + query),
        getCount: (query) => http.get('/templates/count?' + query),
        getById: id => http.get('/templates/' + id),
    },
    projects:{
        get: (q) => http.get('/projects' + ((q && ('?' + query(q))) || '')),
        save: project => http.post('/projects/', project),
        update: (id, project) => http.put('/projects/' + id, project),
        open: id => http.get('/projects/' + id),
        delete: id => http.delete('/projects/' + id),
        getShared: (id, shareKey) => http.get('/projects/' + id + '/sharekey/' + shareKey),
        shareKey: (id, data) => http.post('/projects/' + id + '/sharekey/', data),
        shareWith: (id, data) => http.post('/projects/' + id + '/sharewith/', data),
        removeShareWith: (id, userID) => http.delete('/projects/' + id + '/sharewith/' + userID),
        removeKey: (id, key) => http.delete('/projects/' + id + '/sharekey/' + key),

    },
    purchases: {
        get: () => http.get('/purchases'),
        start: (templateId, projectId) => http.post('/purchases/template/' + templateId, { projectId }),
        getForTemplate: (templateId) => http.get('/purchases/template/' + templateId),
        update: (id, data) => http.put('/purchases/' + id, data),
    },
    social: {
        post: (post) => http.post('/social', post),
        getAccounts: () => http.get('/social/accounts'),
        link: () => http.get('/social/link'),
        getPosts: (q) => http.get('/social/posts?' + query(q)),
        deletePost: (id) => http.delete('/social/posts/' + id),
    },
    subscription: {
        subscribe: (plan) => http.post('/subscription', { plan }),
        unsubscribe: () => http.delete('/subscription'),
    },
};

export function form (data) {
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }
    return formData;
}

export function search (data) {
    const params = [];

    for (const key in data) {
        const values = data[key];

        if (Array.isArray(values)) {
            for (const value of values) {
                params.push([ key, value ]);
            }
        } else {
            params.push([ key, values ]);
        }
    }

    return new URLSearchParams(params);
}

export function query (data) {
    return search(data).toString();
}
