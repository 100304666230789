<template>
    <div class="image-carousel">
        <button v-if="srcs.length > 1" class="previous img-button" @click="previous()">
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px_dark.svg')" />
        </button>
        <img v-for="(src, i) in srcs" :key="src" v-show="i == current" ref="img" :src="src" :width="width">
        <button v-if="srcs.length > 1" class="next img-button" @click="next()">
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px_dark.svg')" />
        </button>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    props: [ 'srcs', 'width' ],
    components: { InlineSvg },
    data () { return {
        current: 0,
    }; },
    methods: {
        previous () {
            if (this.current == 0) {
                this.current = this.srcs.length - 1;
            } else {
                this.current -= 1;
            }
        },

        next () {
            if (this.current == this.srcs.length - 1) {
                this.current = 0;
            } else {
                this.current += 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.image-carousel {
    position: relative;
}

img:not([width]) {
    width: 100%;
}

.img-button {
    display: block;
    position: absolute;
    height: 32px;
    bottom: 12px;
    background: white;
    border-radius: 50%;
}

.img-button svg {
    width: 32px;
    height: 32px;
}

.previous {
    left: 12px;
}

.next {
    right: 12px;
    transform: scaleX(-1);
}
</style>
