<template>
    <div class="error">
        <h1>404</h1>
        <h3>Page you were looking for was not found</h3>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
    .error{
        text-align:center;
    }
</style>
