<template>
    <popup class="popup-content" title="Post" @close="$emit('close')">
        <div class="post-content">
            <image-carousel ref="thumb" :srcs="mediaUrls" width="156" />
            <div class="textarea">
                <textarea v-model="text" placeholder="Write your post here" :disabled="post"></textarea>
            </div>
        </div>
        <hr>

        <template v-if="!post">
            <p>Choose social networks where you want to publish your post</p>
            <ul v-if="hasAccounts">
                <li v-for="(account, platform) in accounts" :key="platform" :class="{ disabled: account.disabled }">
                    <checkbox class="checkbox" :value="platforms.includes(platform)" @input="!account.disabled && onSelectPlatform(platform)" :enabled="!account.disabled" />
                    <div class="account" @click="!account.disabled && onSelectPlatform(platform)">
                        <div class="platform">{{ startCase(platform) }}</div>
                        <div class="display-name">{{ account.displayName }}</div>
                        <div class="username">{{ account.username }}</div>
                    </div>
                    <div v-if="account.disabled" class="disabled-message">
                        {{ account.disabled }}
                    </div>
                </li>
            </ul>
            <p v-else-if="accounts != null" class="error">
                You don't have any linked social media accounts! Please go to your <a href="/user/profile" target="_blank">user profile</a> and link at least one social media account in order to post.
            </p>

            <hr>

            <p>Choose a time when you want your post published</p>
            <radio-picker
                design="traditional"
                :options="[ 'now', 'later' ]"
                :selected="schedule ? 'later' : 'now'"
                @select="onSelectSchedule"
            />

            <date-picker v-if="schedule" v-model="schedule" mode="dateTime" :min-date="minDate">
                <template v-slot="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" />
                </template>
            </date-picker>

            <button @click="onPost" :disabled="!platforms.length || posting">
                {{ posting ? 'Posting...' : 'Confirm' }}
            </button>
        </template>

        <p v-if="post" class="success">
            Your post was successfully {{ schedule ? 'scheduled.' : 'made:' }}
            <ul>
                <li v-for="post in post.posts" :key="post.id">
                    {{ startCase(post.platform) }}
                    -&nbsp;
                    <a :href="post.url" target="_blank">View Post</a>
                </li>
            </ul>
        </p>
    </popup>
</template>

<script>
import { isEmpty, startCase } from 'lodash';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import Checkbox from '@/components/Checkbox.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import Popup from '@/components/Popup.vue';
import RadioPicker from '@/components/RadioPicker.vue';
import analytics from '@/helpers/analytics';
import api, { form } from '@/helpers/api';
import { date } from '@/helpers/utils';

export default {
    props: [ 'template', 'media', 'mediaUrls' ],
    components: { Checkbox, DatePicker, ImageCarousel, Popup, RadioPicker },
    data () { return {
        text: '',
        accounts: null,

        platforms: [],
        minDate: date((date) => { date.setHours(date.getHours() + 1); }),
        schedule: null,

        posting: false,
        post: null,
    }; },
    async mounted () {
        this.accounts = (await api.social.getAccounts()).data;

        // If there's just one, we can go ahead and pre-select it
        const platforms = Object.keys(this.accounts);
        if (platforms.length == 1) {
            this.platforms = platforms;
        }

        await this.$nextTick();
        this.filterAccounts();
    },
    computed: {
        hasAccounts () {
            return !isEmpty(this.accounts);
        },
    },

    methods: {

        filterAccounts () {
            if (!this.accounts.instagram && !this.accounts.instagram.disabled) { return; }

            for (const $img of this.$refs.thumb.$refs.img) {
                if ($img.naturalWidth) {

                    const ratio = $img.naturalWidth / $img.naturalHeight;
                    
                    if (ratio < 0.8 || ratio > 1.91) {
                        this.accounts.instagram.disabled = 'This aspect ratio is not accepted by Instagram as a post.';
                        
                        const i = this.platforms.indexOf('instagram');
                        this.platforms.splice(i, 1);

                        break;
                    }
                } else {
                    $img.addEventListener('load', this.filterAccounts, { once: true });
                }
            }
        },

        onSelectPlatform (platform) {
            const index = this.platforms.indexOf(platform);

            if (index >= 0) {
                this.$delete(this.platforms, index);
            } else {
                this.platforms.push(platform);
            }
        },

        onSelectSchedule (option) {
            if (option == 'later') {
                this.schedule = this.minDate;
            } else {
                this.schedule = null;
            }
        },

        startCase,

        async onPost () {
            this.posting = true;

            const data = {
                text: this.text,
                platformsJson: JSON.stringify(this.platforms),
                mediaCount: this.media.length,
            };

            if (this.schedule) { data.schedule = this.schedule.toISOString(); }

            const upload = form(data);

            for (let i = 0; i < this.media.length; i++) {
                upload.append('media' + i, this.media[i]);
            }

            this.post = (await api.social.post(upload)).data;
            this.posting = false;

            analytics.templates.post({
                id: this.template._id, name:
                this.template.name,
                platforms: this.platforms,
                date: (this.schedule && this.schedule.toISOString()) || new Date().toISOString(),
            });
        },
    },
};

</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

.popup-content {
    padding-bottom: 16px;
}

.post-content {
    display: flex;
    justify-content: stretch;
    margin: 0 0 40px;

    .textarea {
        margin-left: 24px;
        flex-grow: 1;
    }

    textarea {
        display: block;
        width: 100%;
        height: 100%;
        resize: none;
    }
}

hr {
    margin: 20px 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        position: relative;
        display: flex;
        align-items: flex-start;
        cursor: pointer;

        &.disabled {
            cursor: unset;
            color: $color-gray-dark;
        }

        .disabled-message {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            max-width: 200px;
            padding: 4px 6px;
            background: white;
            border: 1px solid $color-gray-dark;
            border-radius: 4px;
            font-size: 12px;
        }

        &.disabled:hover .disabled-message {
            display: block;
        }
    }

    .checkbox {
        margin-top: 2px;
    }

    .account {
        display: inline-block;
        margin: 0 8px;

        .platform {
            font-weight: bold;
        }

        .display-name {
            font-size: 12px;
        }

        .username {
            font-size: 12px;
            font-style: italic;
        }
    }
}

button {
    width: 100%;
    margin: 24px 0 0;
}
</style>
