<template>
    <div class="template-carousel">
        <button class="control-left" @click="arrowClicked('left')">
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px.svg')" ref="leftArrow" id="leftArrow" :class="activeSlideIndex > 0 ? 'active' : ''" />
        </button>
        <div class="template-carousel-wrapper">
            <ul ref="carousel" class="template-carousel-inner">
                <li 
                    :class="activeSlideIndex === index ? 'active' : ''" 
                    @click="$emit('setActive', index)" 
                    v-for="(slide, index) in svgContainer" 
                    :key="index"
                >
                    <template-carousel-tile :svgContainer="svgContainer" :index="index" :activeSlideIndex="activeSlideIndex" />
                </li>
            </ul>
        </div>
        <button class="control-right" @click="arrowClicked('right')">
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px.svg')" ref="rightArrow" id="rightArrow" :class="activeSlideIndex < svgContainer.length - 1 ? 'active' : ''" />
        </button>
        <slot>
        </slot>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import TemplateCarouselTile from '@/components/TemplateCarouselTile.vue';

export default {

    props: [ 'activeSlideIndex' ,'svgContainer', 'windowWidth' ],

    components: {
        InlineSvg,
        TemplateCarouselTile,
    },

    computed:{
        list: function(){ 
            return this.svgContainer;
        },

        isMobile(){
            if(this.windowWidth < this.$breakPoints['width-small']){
                return true;
            }
            return false;
        },
    },
    data () { 
        return {
            
        };
    },
    mounted () {
    },

    methods:{
        arrowClicked(dir = ''){
            let index = this.activeSlideIndex;
            if(dir == 'left'){
                if(index-- > 0){
                    if(this.isMobile){
                        this.$refs.carousel.style.transform = `translateX(-${index * 110}%)`;
                    }else{
                        this.$refs.carousel.style.transform = 'translateX(0%)';
                    }
                    this.$emit('setActive', index);
                }
            }else if(dir == 'right'){
                if(index++ < this.svgContainer.length - 1){
                    if(this.isMobile){
                        this.$refs.carousel.style.transform = `translateX(-${index * 110}%)`;
                    }else{
                        this.$refs.carousel.style.transform = 'translateX(0%)';
                    }
                    this.$emit('setActive', index);
                }
            }else{
                if(this.isMobile){
                    this.$refs.carousel.style.transform = `translateX(-${index * 110}%)`;
                }else{
                    this.$refs.carousel.style.transform = 'translateX(0%)';
                }
            }
        },
        setImage(index){            
            return this.svgContainer[index].outerHTML;
        },
    },
    watch:{
        svgContainer(){
            this.arrowClicked();
        },
        windowWidth(){
            if(!this.isMobile){
                this.$refs.carousel.style.transform = 'translateX(0%)';
            }else{
                this.$refs.carousel.style.transform = `translateX(-${this.activeSlideIndex * 100}%)`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

.template-carousel {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;

    @include max-width($width-small){
        display:grid;
        grid-template-columns:auto 1fr auto;
        align-items:center;
        position:relative;
    }
    .control-left{
        justify-self:flex-end;
    }
    .control-right{
        justify-self:flex-start;
    }
    .control{
        width:100%;
        flex-basis:100%;

        @include max-width($width-small){
            grid-column:1 / span 3;
            margin:20px
        }
        
        &.control-add{
            background: var(--link-color-active);
            border-radius:100%;
            border:2px solid $color-gray;
            color:$color-gray;
            width:40px;
            height:40px;
            flex-basis:unset;
            svg{
                rect{
                    fill:#fff;
                    stroke:#fff;
                }
            }
        }
    }
    .add-container{
        display:flex;
        align-items:center;
        justify-content: center;
        grid-column: 1 / span 3;
    }
    button{
        width:unset;
        height:unset;
        background:unset;
        border:unset;
        margin:unset;
        padding:unset;

        #leftArrow, #rightArrow{
            &.active{
                fill:var(--link-color);
            
                path{
                    fill:white;
                }

            }
        }
        #leftArrow{
            margin-right:20px;
            align-self:flex-end;
            
        }
        #rightArrow{
            margin-left:20px;
            transform:rotate(-180deg);
        }
    }
   
    .template-carousel-wrapper{
        display:flex;
        align-items:center;
        justify-content: center;
        overflow:hidden;
        
        @include max-width($width-small){
            position:relative;
            width:100%;
            overflow:hidden;
            box-sizing: border-box;
        }
        .template-carousel-inner {
            display: flex;
            flex-wrap: nowrap;
            list-style:none;
            padding:0;
            width:100%;
            gap:20px;
            
            @include max-width($width-small){
                gap:10%;
                margin:0;
                transition:transform 0.25s ease-in-out;

            }
            li {
                width:100%;
                height:100%;
                padding:10px;
                align-items: center;
                background: white;
                font-size: 14px;
                box-sizing: border-box;
                border:2px solid transparent;
                border-radius:6px;
                transition:border-color 0.2s ease-in-out;
                cursor:pointer;

                @include max-width($width-small){
                    flex: 0 0 auto; 
                }

                &.active, &:hover{
                    border-color: var(--link-color-active);
                }
                div{
                    height:150px;
                    width:150px;
                    pointer-events:None;
                    margin:0 auto;
                }
            }
        }
    }
}
</style>
