<template>
    <div>
        <button class="bug-button img-button" @click="open = true">
            <img src="@/assets/img/bug.svg">
        </button>
        <popup title="Found a bug?" v-if="open" @close="open = false">
            <form
                @submit.prevent="submit"
                enctype="multipart/form-data"
                target="bug-frame"
            >
                <textarea v-model="description" name="description">Briefly describe the problem you found...</textarea>
                <label for="file">Upload a screenshot:</label>
                <input id="file" name="file" type="file" ref="file" accept="image/*">
                <button>{{ submitting ? 'Submitting...' : 'Submit' }}</button>
            </form>
            <iframe name="bug-frame"></iframe>
        </popup>
    </div>
</template>

<script>
import axios from 'axios';

import Popup from './Popup.vue';

export default {
    components: { Popup },

    data () { return {
        submitting: false,
        open: false,
        description: '',
        formUrl: process.env.VUE_APP_BUG_HOOK,
    }; },

    methods: {
        async submit () {
            if (this.submitting) { return; }
            this.submitting = true;

            const data = new FormData();
            data.append('description', this.description + `\n${window.location.href}`);
            
            const file = this.$refs.file.files[0];
            file && data.append('file', file);

            await axios.post(process.env.VUE_APP_BUG_HOOK, data);

            this.submitting = false;
            this.open = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

.bug-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 42px;
    height: 42px;
    padding: 8px;
    z-index: 10;
    background: $color-red-light;
    border-radius: 50%;
}

textarea {
    width: 100%;
    min-height: 80px;
}

input {
    border: none;
}

iframe {
    display: none;
}

</style>
