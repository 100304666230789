<template>
    <div class="password-reset-page auth-page">
        <h1>Password Reset</h1>

        <label>
            <img src="@/assets/img/Lock.svg" />
            <span>Password</span>
            <span v-if="passwordStatus == 'invalid'" class="error">
                &nbsp;- Password length must be at least 8 characters long
            </span>
        </label>
        <input
            v-model="password"
            :class="passwordStatus"
            ref="password"
            type="password"
            name="password"
            placeholder="New Password"
        />

        <label>
            <img src="@/assets/img/Lock.svg" />
            <span>Confirm Password</span>
            <span v-if="confirmPasswordStatus == 'invalid'" class="error">&nbsp;- Passwords must match</span>
        </label>
        <input
            v-model="confirmPassword"
            :class="confirmPasswordStatus"
            type="password"
            placeholder="Confirm Password"
        />

        <button :disabled="!valid" @click="onChange">Change Password</button>
    </div>
</template>

<script>
import api from '@/helpers/api';
export default {

    data () { return {
        password: '',
        passwordStatus: '',
        confirmPassword: '',
        confirmPasswordStatus: '',
    }; },

    methods: {
        async onChange () {
            try {
                await api.auth.passwordResetConfirm(this.$route.query.key, this.password);
                this.$toast('Password changed. Please log in.', { timeout: false });
                this.$router.push('/');
            } catch (e) {
                console.error(e);
                this.$toast.error('Could not change password.', { timeout: false });
            }
        },
    },

    computed: {
        valid () {
            return this.passwordStatus == 'valid' && this.confirmPasswordStatus == 'valid';
        },
    },

    watch: {
        password () {
            this.passwordStatus = this.password.length >= 8 ? 'valid' : 'invalid';
        },

        confirmPassword () {
            this.confirmPasswordStatus = this.password == this.confirmPassword ? 'valid' : 'invalid';
        },
    },
};
</script>

<style lang="scss" scoped>
.password-reset-page {
    width: 100%;
    max-width: 600px;
    margin: 48px auto;

    h1 {
        margin: 0 0 48px;
    }
}
</style>
