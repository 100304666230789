<template>
    <div class="category-carousel">
        <button class="control img-button" :class="{ enabled: prevControlEnabled }" @click="onPrev">
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px.svg')" aria-label="Previous" />
        </button>
        <ul class="category-carousel-slider" ref="slider" @scroll.passive="onScroll">
            <li v-for="category in categories" :key="category._id">
                <router-link :to="`/template/catalog?categories=${category._id}`">
                    <category-icon :icon="category.icon" />
                    <span>{{ category.name }}</span>
                </router-link>
            </li>
        </ul>
        <button class="control img-button control-next" :class="{ enabled: nextControlEnabled }" @click="onNext">
            <inline-svg :src="require('@/assets/img/arrow_left_circled_48px.svg')" aria-label="Next" />
        </button>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import CategoryIcon from '@/components/CategoryIcon.vue';
import api from '@/helpers/api';
import { filterEmpty } from '@/helpers/categories';

export default {

    props: [ 'media' ],

    components: { CategoryIcon, InlineSvg },

    data () { return {
        categories: [],
        scrollLeft: 0,
        scrollWidth: 0,
        clientWidth: 0,
    }; },

    async mounted () {
        this.categories = (await api.categories.get()).data;
        await this.$nextTick();
        this.onScroll({ target: this.$refs.slider });

        this.categories = await filterEmpty(this.categories, { media: this.media });
    },

    methods: {
        onScroll (e) {
            this.scrollLeft = e.target.scrollLeft;
            this.scrollWidth = e.target.scrollWidth;
            this.clientWidth = e.target.clientWidth;
        },

        onPrev () {
            this.$refs.slider.scrollTo({ left: this.scrollLeft - (this.clientWidth * 0.75), behavior: 'smooth' });
        },

        onNext () {
            this.$refs.slider.scrollTo({ left: this.scrollLeft + (this.clientWidth * 0.75), behavior: 'smooth' });
        },
    },

    computed: {

        prevControlEnabled () {
            return this.scrollLeft > 0;
        },

        nextControlEnabled () {
            return this.scrollLeft < (this.scrollWidth - this.clientWidth);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

.category-carousel {
    display: flex;
    align-items: center;

    .control {
        cursor: unset;

        &.enabled {
            cursor: pointer;
        }
    }
}

.category-carousel-slider {
    display: flex;
    margin: 0 24px;
    padding: 24px 0;
    list-style: none;
    overflow: auto;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    li {
        width: 146px;
        height: 146px;
        margin: 0 10px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: white;
        font-size: 14px;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
            border: 2px solid var(--link-color-active);

            a {
                color: var(--link-color-active);
            }
        }

        a {
            display: block;
            text-decoration: none;
            color: $color-black;
        }

        span {
            display: block;
            padding: 8px 4px 0;
        }
    }
}

.control {
    background: none;

    &.control-next {
        transform: scaleX(-1);
    }
}

</style>

<style lang="scss">
@import '@/assets/css/colors';

.category-carousel {

    .control {

        &.enabled svg {

            circle {
                stroke: $color-gray-medium;
            }

            path {
                fill: $color-gray-medium;
            }
        }
    }
    
    li:hover svg * {
        fill: var(--link-color-active);
    }
}
</style>
