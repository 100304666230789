<template>
    <div>
        <button class="menu-button" @click="menuClicked">
            <div class="menu-category" :class="menuOpen ? 'open' : ''">
                View All {{ category }}
            </div>
            <inline-svg :src="require('@/assets/img/arrow_down_16px.svg')" :class="menuOpen ? 'flip' : ''" />
        </button>
        <div class="menu" :class="menuOpen ? '' : 'closed'">
            <router-link
                @click.native="$emit('close-menu')"
                class="category"
                :to="category == 'Social Media' || category == 'Print' ? `/template/catalog?media=${media}` : `/template/catalog?media=${media}&categories=${category._id}`"
            >
                {{ category }}
            </router-link>
            <router-link
                @click.native="$emit('close-menu')"
                v-for="category in categories"
                :key="category._id"
                class="category"
                :to="`/template/catalog?media=${media}&categories=${category._id}`"
            >
                {{ category.name }}
            </router-link>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    props:[ 'category', 'categories', 'media' ],

    components:{
        InlineSvg,
    },

    data:()=>{
        return{
            menuOpen:false,
        };
    },

    methods:{
        menuClicked(){
            this.menuOpen = !this.menuOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

button{
    display:flex;
    background:unset;
    border:unset;
    padding:10px;
    color:black;
    width:100%;
    border-radius:0;
}
.menu-button{
    border: 1px solid var(--background-color);

    .menu-category{
        flex-grow:1;
        display:flex;
        background:unset;
        border:unset;
        padding:0;
        margin:0;
        color:black;
        height:100%;

        &.open{
            color: var(--link-color-active);
        }

        button{

            &:active, &:hover{
                color: var(--link-color-active);
            }
        }
    }
    svg{
        justify-self:flex-end;
        align-self:center;
        transition:transform 0.15s ease-in-out;

        &.flip{
            transform:rotate(180deg);
        }
    }
}
.menu{
    overflow:hidden;
    a{
        display:flex;
        background:unset;
        border:unset;
        padding:10px;
        color:black;
        width:100%;
        border-radius:0;
        font-size:14px;
        font-weight:500;
    }
    &.closed{
        height:0;
    }
}
</style>
