<template>
    <button>
        <div class="icon-background">
            <inline-svg :src="iconSrc" alt="" />
        </div>
        <span><slot></slot></span>
    </button>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    components:{
        InlineSvg,
    },
    props: {
        icon: String,
    },
    computed: {
        iconSrc () { return {
            collab: require('@/assets/img/collab_24px_blue.svg'),
            delete: require('@/assets/img/icon_delete_24px.svg'),
            download: require('@/assets/img/download_24px.svg'),
            save: require('@/assets/img/Save.svg'),
            share: require('@/assets/img/share_24px.svg'),
            templates: require('@/assets/img/templates_24px_blue.svg'),
        }[this.icon]; },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

button {
    display: flex;
    appearance: none;
    margin: 0 12px;
    padding: 0 12px;
    align-items: center;
    background: none;
    border: none;

    &:hover span {
        text-decoration: underline;
    }

    span {
        color: var(--link-color);
        font-size: 16px;
        font-weight: 600;

        @include max-width($width-medium){
            display:none;
        }
    }
}

.icon-background {
    position: relative;
    width: 36px;
    height: 36px;
    margin: 0 4px;
    background: var(--template-nav-bg);
    border-radius: 50%;
    overflow: none;


    @include max-width($width-medium){
        width:25px;
        height:25px;
        padding:5px;
        box-sizing:border-box;
    }

    svg {
        fill:var(--link-color-active);

        @include center-absolute;

        @include max-width($width-small){

        }
    }
}
</style>
