import Vue from 'vue';
import VueRouter from 'vue-router';
import tenants from './tenants';

import api from '@/helpers/api';
import LogoutPage from '@/views/auth/logout/LogoutPage.vue';
import PasswordResetPage from '@/views/auth/password-reset/PasswordResetPage.vue';
import ContactPage from '@/views/ContactPage.vue';
import HomePage from '@/views/HomePage.vue';
import HowItWorksPage from '@/views/HowItWorksPage.vue';
import PageNotFound from '@/views/pagenotfound/PageNotFound.vue';
import PlanConfirmPage from '@/views/plans/PlanConfirmPage.vue';
import PlansPage from '@/views/plans/PlansPage.vue';
import PrivacyPage from '@/views/PrivacyPage.vue';
import RedirectPage from '@/views/RedirectPage.vue';
import CatalogPage from '@/views/templates/catalog/CatalogPage.vue';
import TemplateCreatePage from '@/views/templates/create/TemplateCreatePage.vue';
import TemplateDebug from '@/views/templates/TemplateDebug.vue';
import TemplateEditPage from '@/views/templates/edit/TemplateEditPage.vue';
import TemplateProjectPage from '@/views/templates/projects/TemplateProjectPage.vue';
import TermsPage from '@/views/TermsPage.vue';
import FavoritesPage from '@/views/user/favorites/FavoritesPage.vue';
import ProfilePage from '@/views/user/profile/ProfilePage.vue';
import ProjectPage from '@/views/user/projects/ProjectPage.vue';
import PurchasedPage from '@/views/user/purchased/PurchasedPage.vue';
import SocialPostsDayPage from '@/views/user/social/SocialPostsDayPage.vue';
import SocialPostsPage from '@/views/user/social/SocialPostsPage.vue';
import UserPage from '@/views/user/UserPage.vue';

Vue.use(VueRouter);

export default ($tenant) => {

    const routes = [
        { path: '/', component: $tenant?.home || HomePage, name: 'Home' },
        { path: '/contact', component: ContactPage, name: 'Contact' },
        { path: '/how-it-works', component: HowItWorksPage, name: 'How it works'},
        { path: '/privacy', component: PrivacyPage, name: 'Privacy' },

        { path: '/terms/', component: TermsPage, name: 'Terms'},
        { 
            path: '/template/create', 
            component: TemplateCreatePage, 
            meta: { fullscreen: true },
            beforeEnter: beforeEnterCheckRole('admin'),
        },
        {
            name: 'TemplateCreateExisting',
            path: '/template/:id/create',
            component: TemplateCreatePage,
            meta: { fullscreen: true },
            props: true,
            beforeEnter: beforeEnterCheckRole('admin'),
        },
        {
            name: 'TemplateDebug',
            path: '/template/debug',
            component: TemplateDebug,
            beforeEnter: beforeEnterCheckRole('admin'),
        },
        {
            name: 'projectsPage', 
            path: '/template/:id/edit/', 
            component: TemplateProjectPage, 
            meta: { fullscreen: true }, props: true, 
        },
        {
            name: 'projectNew',
            path: '/template/:id/edit/project/new',
            component: TemplateEditPage,
            meta: { fullscreen: true }, props: true,
        },
        {
            name: 'projectEdit',
            path: '/template/:id/edit/project/:projectId',
            component: TemplateEditPage,
            meta: { fullscreen: true }, props: true,
        },
        {
            name: 'projectShareEdit',
            path: '/template/:id/edit/project/:projectId/:shareKey',
            component: TemplateEditPage,
            meta: { fullscreen: true }, props: true,
        },
        { path: '/template/catalog/', name: 'Catalog', component: CatalogPage },
        { path: '/auth/:provider/callback', name: 'Redirect', component: RedirectPage, props: true },
        { path: '/auth/password-reset', name: 'PasswordResetPage', component: PasswordResetPage },
        { path: '/plans', name: 'Plans', component: PlansPage },
        { path: '/plans/confirm', name: 'PlansConfirm', component: PlanConfirmPage },
        { path: '/logout', name: 'Logout', component: LogoutPage },
        { 
            path: '/user/', 
            name: 'UserPage', 
            component: UserPage,
            children: [
                {
                    name: 'profile',
                    path: 'profile',
                    component: ProfilePage,
                },
                {
                    name: 'profileSettings',
                    path: 'profileSettings',
                    component: ProfilePage,
                },
                {
                    name: 'projects',
                    path: 'projects',
                    component: ProjectPage,
                },
                {
                    name: 'favorites',
                    path: 'favorites',
                    component: FavoritesPage,
                },
                {
                    name: 'purchased',
                    path: 'purchased',
                    component: PurchasedPage,
                },
                { name: 'social', path: 'social', component: SocialPostsPage },
                { name: 'social-day', path: 'social/:day', component: SocialPostsDayPage, props: true },
            ] ,
        },
        { path: '*', name:'PageNotfound', component: PageNotFound },
    ];

    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior (to, from, saved) {
            if (saved) {
                return saved;
            } else {
                Vue.nextTick(() => document.body.scrollTo(0, 0));
            }
        },
    });

    function beforeEnterCheckRole (role) { return async (to, from, next) => {
        try {
            const response = await api.users.get('me');

            if (response.status == 200 && response.data.role === role) {
                next();
            } else {
                next(false);
            }
        } catch (e) {
            next(false);
        }
    }; }

    return router;
};
