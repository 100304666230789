import fitImage from '@/helpers/fit-image';
import { awaitImageLoad, generateId } from '@/helpers/utils';
import { getBBox } from './svg-tools';

export default class ImageHandler{
    constructor ($el, editable, $svg, $template, slideIndex, handleDrag) {
        this.$el = $el;
        this.$template = $template;
        this.editable = editable;
        this.slideIndex = slideIndex;

        this.$svg = $svg;
        this.width = this.$svg.viewBox.baseVal.width;
        this.height = this.$svg.viewBox.baseVal.height;
        this.isLoaded = false;
        this._dragListener;
        this._endListener;
        this.imageDrag = false;
        this.offset = {};
        this.handleDrag = handleDrag;
        this.promise = this.reduceImageSize(this.$el.getAttribute('xlink:href'));
    }

    getMatrix () {
        // Hopefully a cross browser way to get the right kind of matrix that we need

        // Does NOT work in Safari / Firefox, always returns 'none'
        // const domMatrix = new DOMMatrix(window.getComputedStyle(this.$el).transform);

        // Should work, but a little weird to be directly accessing...doesn't account for parent transforms?
        // const baseMatrix = this.$el.transform.baseVal[0].matrix;

        // Directly computed, definitely works
        const screenMatrix = this.$svg.getScreenCTM().inverse().multiply(this.$el.getScreenCTM());

        return screenMatrix;
    }

    async reduceImageSize($image){
        const $imageFile = new Image();
        await awaitImageLoad($imageFile, $image);
        const srcBlob = await fetch($imageFile.src).then(res => res.blob());
        
        const resizedBlob = await fitImage(
            srcBlob,
            $imageFile,
            { targetWidth: this.width, targetHeight: this.height },
        );
        
        const matrix = this.getMatrix();
        this.width = this.$el.getBBox().width * Math.abs(matrix.a);
        this.height = this.$el.getBBox().height * Math.abs(matrix.d);
        let transform = `translate(${matrix.e}, ${matrix.f})`;
        let matrixchanged = false;
        if (matrix.a < 0) {
            matrix.a = -1;
            matrixchanged = true;
        } else {
            matrix.a = 1;
        }
        if (matrix.d < 0) {
            matrix.d = -1;
            matrixchanged = true;
        } else {
            matrix.d = 1;
        }
        if (matrixchanged) {
            transform += ` matrix(${matrix.a} 0 0 ${matrix.d} 0 0)`;
        }

        if (!this.$el.hasAttribute('data-initial-x')) {
            this.$el.dataset.initialX = this.$el.hasAttribute('x') ? this.$el.getAttribute('x') : 0;
        }
        if (!this.$el.hasAttribute('data-initial-y')) {
            this.$el.dataset.initialY = this.$el.hasAttribute('y') ? this.$el.getAttribute('y') : 0;
        }
        if (!this.$el.hasAttribute('data-initial-width')) {
            this.$el.setAttribute('width', this.width);
            this.$el.dataset.initialWidth = this.width;
        } else {
            this.$el.setAttribute('width', this.$el.getAttribute('data-initial-width'));
        }
        if (!this.$el.hasAttribute('data-initial-height')) {
            this.$el.setAttribute('height', this.height);
            this.$el.dataset.initialHeight = this.height;
        } else {
            this.$el.setAttribute('height', this.$el.getAttribute('data-initial-height'));
        }
        if (resizedBlob) {
            //Check for prescale can reuse to find other things
            if (this.$el.hasAttribute('transform')) {
                let splitTransform = this.$el.getAttribute('transform').split(/\s/);
                let rebuilt = [];
                let partition = '';
                for (let section of splitTransform) {
                    partition += section;
                    if (section.includes(')')) {
                        partition = partition.trim();
                        rebuilt.push(partition);
                        partition = '';
                    }
                    partition += ' ';
                }
                const foundScale = rebuilt.find(e => e.includes('scale'));
                if(foundScale){
                    this.$el.dataset.scale = foundScale.replace(/[scale, (, )]+/g, '');
                }
            }

            this.$el.setAttribute('transform', transform);

            const reader = new FileReader;

            let promise = new Promise(resolve =>{
                reader.onload = async () => {

                    await this.changeImage({value: reader.result});
                    resolve();
                };
                reader.readAsDataURL(resizedBlob);
            });
            await promise;
        } else {
            //No resize needed, just make sure element is using the right image.
            this.$el.setAttribute('transform', transform);
            this.changeImage({value: $image});
        }

    }
    getCoords(){
        if (this.imageDrag){
            return this.editable.coords;
        }
        return false;
    }
    async changeImage ($event) { 

        this.$el.setAttribute('xlink:href', $event.value);
        // this.zoomImage();
        this.isDirty = true;
        this.isLoaded = true;
        await this.prepareImage();
        await this.zoomImage({zoom: this.editable.zoom, oldZoom: 100});

        if (this.editable.coords || $event.coords){
            let coords = this.editable.coords ? this.editable.coords : $event.coords;
            this.$el.dataset.ogX = coords.x;
            this.$el.dataset.ogY = coords.y;
            this.$el.setAttribute('x', coords.x);
            this.$el.setAttribute('y', coords.y);
        } else {
            this.$el.dataset.ogX = 0;
            this.$el.dataset.ogY = 0;
        }
        this.handleDrag(this.editable, this.$el);
    }

    async prepareImage(){
        const $parent = this.$el.parentNode;
        const $imageFile = new Image();
        $imageFile.src = this.$el.getAttribute('xlink:href');

        return new Promise((resolve) => {
            $imageFile.onload = async ()=>{
                if (!$parent.hasAttribute('clip-path') && this.$el.dataset.fixedImage == undefined) {
                    //remake the element with clip-path
                    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    const gContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    const clipPath = document.createElementNS('http://www.w3.org/2000/svg','clipPath');
                    const rect = document.createElementNS('http://www.w3.org/2000/svg','rect');
                    const rectId = generateId(10, 'SVGID_') + '_';
                    const clipId = generateId(50, 'SVGID_') + '_';
                    const matrix = this.getMatrix();
                    const x = matrix.e;
                    const y = matrix.f;
                    const width = this.$el.getAttribute('data-initial-width');
                    const height = this.$el.getAttribute('data-initial-height');
                    //set up rect
                    rect.id = rectId;
                    rect.setAttribute('width', width);
                    rect.setAttribute('height', height);
                    let matrixchanged = false;
                    if (matrix.a < 0) {
                        matrix.a = -1;
                        rect.setAttribute('x', -x);
                        matrixchanged = true;
                    } else {
                        matrix.a = 1;
                        rect.setAttribute('x', x);
                    }
                    if (matrix.d < 0) {
                        matrix.d = -1;
                        rect.setAttribute('y', -y);
                        matrixchanged = true;
                    } else {
                        matrix.d = 1;
                        rect.setAttribute('y', y);
                    }
                    if (matrixchanged) {
                        rect.setAttribute('transform', `matrix(${matrix.a} 0 0 ${matrix.d} 0 0)`);
                    }
                    //create clipPath
                    clipPath.id = clipId;
                    clipPath.appendChild(rect);
                    //link
                    g.setAttribute('clip-path', `url(#${clipId})`);
                    g.appendChild(this.$el.cloneNode(true));
                    gContainer.appendChild(clipPath);
                    gContainer.appendChild(g);
                    this.$el.parentNode.replaceChild(gContainer, this.$el);
                    this.$el = $parent.querySelector(`[id='${this.$el.id}']`);
                    this.$el.dataset.fixedImage = true;
                    
                } else {
                    const id = $parent.getAttribute('clip-path').replace('url(#', '').slice(0, -1);
                    const $clipPath = this.$template.querySelector(`[id=${id}]`);
                    const shape = this.findClipPath($clipPath);
                    shape.id = generateId(10, 'SVG_') + '_';
                    $clipPath.appendChild(shape);
                }

                await this.resizeImage();
                resolve(this.$el);
            };
        });           
    } 

    findClipPath($clipPath){
        //check if its using "use"
        let shape = '';
        if ($clipPath.querySelector('use')) {
            const $use = $clipPath.querySelector('use');
            const $id = $use.getAttribute('xlink:href').slice(1);
            //grab rect
            shape = this.$template.querySelector(`[id='${$id}']`);
            //$use no longer needed
            shape.remove();
            $use.remove();
        } else {
            shape = this.getClipShape();
        }
        return shape;
    }

    getClipShape () {
        const $parent = this.$el.parentNode;
        // url(#SVGID_00000162324847891853318940000003102386888964096671_)
        // extracts just "SVGID_00000162324847891853318940000003102386888964096671_"
        const clipPathId = $parent.getAttribute('clip-path').replace('url(#', '').slice(0, -1);
        return this.$template.querySelector(`[id='${clipPathId}']`).children[0];
    }

    async resizeImage(){
        const matrix = this.getMatrix();
        const $imageFile = new Image();
        $imageFile.src = this.$el.getAttribute('xlink:href');
        return new Promise((resolve) => {
            $imageFile.onload = ()=>{
                if (!this.$el.hasAttribute('data-og-width')) {
                    this.$el.dataset.ogWidth = $imageFile.naturalWidth;
                }
                if (!this.$el.hasAttribute('data-og-height')) {
                    this.$el.dataset.ogHeight = $imageFile.naturalHeight;
                }

                let transform = `translate(${matrix.e}, ${matrix.f})`;
                let matrixchanged = false;
                if(matrix.a < 0){
                    matrix.a = -1;
                    matrixchanged = true;
                }else{
                    matrix.a = 1;
                }
                if(matrix.d < 0){
                    matrix.d = -1;
                    matrixchanged = true;
                }else{
                    matrix.d = 1;
                }
                if(matrixchanged){
                    transform += ` matrix(${matrix.a} 0 0 ${matrix.d} 0 0)`;
                }
                // const scale = this.getScale();
                this.$el.setAttribute('transform', transform);
                resolve();
            };
        });
    }

    getScale(){
        let scale = 1;
        if (this.$el.hasAttribute('data-scale')) {
            scale = this.$el.getAttribute('data-scale');
        } else {
            scale = this.$el.transform.baseVal[1] ? this.$el.transform.baseVal[1].matrix.a : 1;
            this.$el.dataset.scale = scale;
        }
        return scale;
    }

    determineShapeSize ($shape) {
        switch ($shape.nodeName) {
            case 'rect': return { 
                w: $shape.getAttribute('width'), 
                h: $shape.getAttribute('height'), 
            };
            case 'circle': return { 
                w: this.$el.getAttribute('data-initial-width'), 
                h: this.$el.getAttribute('data-initial-height'),
            };
            case 'path': return { 
                w: $shape.getBBox().width, 
                h: $shape.getBBox().height,
            };
        }
    }

    zoomImage($event){
        const zoom = $event.zoom / 100;  

        //consider removing this after we know we dont need it.
        // const $parent = this.$el.parentNode;
        // const id = $parent.getAttribute('clip-path').replace('url(#', '').slice(0, -1);
        // const $shape = this.$template.querySelector(`[id=${id}]`).children[0];
        // const $size = this.determineShapeSize($shape);
        // const scaleW = $size.w / this.$el.getAttribute('data-og-width');
        // const scaleH = $size.h / this.$el.getAttribute('data-og-height');
        // const scale = scaleW > scaleH ? scaleW : scaleH;

        const width = this.$el.getAttribute('data-initial-width');
        const height = this.$el.getAttribute('data-initial-height');
        this.$el.setAttribute('width', width * zoom);
        this.$el.setAttribute('height', height * zoom);

        const offsetW = (width * zoom) - (width * ($event.oldZoom / 100));
        const offsetH = (height * zoom) - (height * ($event.oldZoom / 100));
        const x = this.$el.getAttribute('x') ? parseFloat(this.$el.getAttribute('x')) - 0 : 0;
        const y = this.$el.getAttribute('y') ? parseFloat(this.$el.getAttribute('y')) - 0 : 0;
        this.$el.setAttribute('x', x - (offsetW ? offsetW / 2 : 0));
        this.$el.setAttribute('y', y - (offsetH ? offsetH / 2 : 0)); 

        return {
            coords: {
                x: parseFloat(this.$el.getAttribute('x')),
                y: parseFloat(this.$el.getAttribute('y')),
            },
        };
    }

    zoomOverlayStart(){
        const $image = this.$template.querySelector(`#${this.editable.id}`);
        const $shape = this.getClipShape($image, this.$template);

        const $overlay = this.imgZoomOutlineCreate();
        this.imgZoomOutlineUpdate($overlay, $image);
        this.imgCreateDragOutline($shape);
    }

    zoomOverlayMove(){
        if (document.body.querySelector('#imageBox')) {
            const $overlay = document.body.querySelector('#imageBox');
            const $image = document.body.querySelector(`#${this.editable.id}`);

            this.imgZoomOutlineUpdate($overlay, $image);
        }
    }

    zoomOverlayEnd(){
        if (document.body.querySelector('#imageBox')) {
            document.body.querySelector('#imageBox').remove();
        }

        for (const dragbox of document.querySelectorAll('.img-dragbox')) {
            dragbox.remove();
        }
    }

    mousePosition(e){
        const clientX = ((e.touches && e.touches[0]) || e).clientX;
        const clientY = ((e.touches && e.touches[0]) || e).clientY;

        const CTM = this.$el.getScreenCTM();
        return {
            x: (clientX - CTM.e) / CTM.a,
            y: (clientY - CTM.f) / CTM.d,
        };

    }

    startDrag ($event) {
        if ($event.target.id == this.editable.id && !this.imageDrag) {
            this.imageDrag = true;
            const $shape = this.getClipShape();
            this.imgCreateDragOutline($shape);
            this.imgZoomOutlineCreate();

            this._dragListener = ($event) => {
                this.dragging($event);
            };

            document.addEventListener('mousemove', this._dragListener);
            document.addEventListener('touchmove', this._dragListener);

            this.offset = this.mousePosition($event);
            this.offset.x -= parseFloat(this.$el.getAttributeNS(null, 'x'));
            this.offset.y -= parseFloat(this.$el.getAttributeNS(null, 'y'));
            $event.preventDefault();
        }
    }

    dragging ($event){
        if (this.imageDrag && this.editable.id == this.$el.id) {
            // $event.preventDefault();
            let coords = this.mousePosition($event);
            let x = coords.x - this.offset.x;
            let y = coords.y - this.offset.y;
            this.$el.setAttributeNS(null, 'x', x);
            this.$el.setAttributeNS(null, 'y', y);
            this.editable.coords = { x: x, y: y};
            this.imgZoomOutlineUpdate();
        }
    }

    endDrag($event){
        for (const dragbox of document.querySelectorAll('.img-dragbox')) {
            dragbox.remove();
        }

        if (document.body.querySelector('#imageBox')) {
            document.body.querySelector('#imageBox').remove();
        }

        this.imageDrag = false;
        if (this.$el.id == this.editable.id) {
            this.$el.dataset.ogX = parseFloat(this.$el.getAttributeNS(null, 'x') / (this.editable.zoom / 100));
            this.$el.dataset.ogY = parseFloat(this.$el.getAttributeNS(null, 'y') / (this.editable.zoom / 100));
            this.$el.dataset.zoomX = parseFloat(this.$el.getAttributeNS(null, 'x'));
            this.$el.dataset.zoomY = parseFloat(this.$el.getAttributeNS(null, 'y'));
            document.removeEventListener('mousemove', this._dragListener);
            document.removeEventListener('touchmove', this._dragListener);
            // $event.preventDefault();

            return this.editable.coords;
        }
    }

    imgCreateDragOutline ($shape) {
        const $overlay = document.createElement('div');
        $overlay.classList.add('img-dragbox');
        $overlay.style.border = '5px solid red';
        $overlay.style.position = 'absolute';
        $overlay.style.opacity = 0.5;

        // Scale based on svg size
        if ($shape.nodeName == 'circle') {        
            $overlay.style.borderRadius = '50%';
        }

        //Check if theres a scale applied
        // const ogScale = this.$el.hasAttribute('data-scale') ? this.$el.getAttribute('data-scale') : 1;
        
        $overlay.style.left = $shape.getBoundingClientRect().x + 'px';
        $overlay.style.top = $shape.getBoundingClientRect().y + 'px';
        $overlay.style.width = ($shape.getBoundingClientRect().width) + 'px';
        $overlay.style.height = ($shape.getBoundingClientRect().height) + 'px';

        $overlay.style.boxSizing = 'border-box';
        document.body.append($overlay);
    }

    imgZoomOutlineCreate () {
        const $overlay = document.createElement('div');

        $overlay.id = 'imageBox';
        $overlay.style.border = '5px solid #25a1e8';
        $overlay.style.opacity = 0.5;
        $overlay.style.position = 'absolute';
        $overlay.style.boxSizing = 'border-box';

        document.body.append($overlay);

        return $overlay;
    }

    imgZoomOutlineUpdate () {
        const $overlay = document.body.querySelector('#imageBox');
        const imgBBox = this.$el.getBoundingClientRect();

        $overlay.style.width = imgBBox.width + 'px';
        $overlay.style.height = imgBBox.height + 'px';
        $overlay.style.left = imgBBox.x + 'px';
        $overlay.style.top = imgBBox.y + 'px';
    }

    resetImagePosition($event) {
        this.zoomImage({zoom: $event.zoom, oldZoom: $event.oldZoom});
        this.$el.setAttribute('x', this.$el.getAttribute('data-initial-x'));
        this.$el.setAttribute('y', this.$el.getAttribute('data-initial-y'));
    }
}
