<template>
    <div class="textContainer">
        {{ title }}
        <input type="text" :value="text" @input="$emit('text-input', $event.target.value)" />
    </div>
</template>

<script>

export default {
    name: 'EditorText',
    props: {
        title: { type: String, default: 'Value' },
        text: { type: String },
    },
};
</script>

<style lang="scss">
.textContainer {
    input {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;
    }
}
</style>
