<template>
  <div v-if='user' class="profileContainer">
        <div class="profile-item">
            <h5>Profile Settings</h5>
            <h6>Account Details</h6>
            <div class="profile-row">

                <div class="account-container">
                    <span class="smallH">Your Email Address</span>

                    <span v-if='!editingEmail' class="userInfo">{{ user.email }}</span>
                    <input v-if="editingEmail" type='email' v-model='newEmail' class="userInfo" />

                    <span v-if="!editingEmail" class="email-verified">
                        {{ verified ? 'Verified' : 'Not Verified' }}
                    </span>

                    <button class="button-inverted" v-if='!editingEmail' @click='editingEmail = true'>Edit</button>
                    <button
                        v-if="!user.emailVerified && !sentVerification && !editingEmail"
                        class="button-inverted"
                        @click="verifyEmail"
                    >
                        Verify
                    </button>
                </div>
                
                <div class="account-container">
                    <span class="smallH">{{ editingPassword ? 'New Password' : 'Your Password' }}</span>

                    <span class="userInfo" v-if='!this.editingPassword'>Last Updated - {{lastModified}}</span>
                    <input v-if="this.editingPassword" type='password' v-model='newPassword' class="userInfo" />
    
                    <button class="button-inverted" v-if='!editingPassword' @click='editingPassword = true'>
                        Change
                    </button>
                </div>

                <div v-if="editingEmail || editingPassword" class="account-container">
                    <span class="smallH">Current Password</span>
                    <input type="password" v-model="currentPassword" class="userInfo">
                </div>
            </div>
        </div>
        <div class="profile-item profile-right">
            <h5>Account Status</h5>
            <div class="status-container">
                <span class="role">{{roleName}}</span>
                <div v-if="user.role === 'freeUser'" class="pro-container">
                    <span>Access to all templates!</span>
                    <router-link class="try-pro" to="/plans">Try Pro!</router-link>
                </div>
            </div>
        </div>

        <div class="profile-item profile-wide">
            <h5>Email Notifications</h5>
            <div class="profile-row email-check">
                <input name="newsLetter" v-model="user.newsLetter" type="checkbox">
                <span>New templates &amp; discounts newsletter</span>
            </div>
            <div name="downloadToEmail"  class="profile-row email-check">
                <input name="downloadToEmail" v-model="user.downloadToEmail" type="checkbox">
                <span>Send download files on email</span>
            </div>
        </div>
        <div class="profile-item profile-wide profile-last">
            <button @click="saveUser">Save Changes</button>
            <LogoutButton :disableSVG='true' />
        </div>

        <div class="profile-item profile-wide">
            
            <h5>Social Profiles</h5>
            
            <div class="profile-row">

                <div class="social-container">
                    <h6>Instagram</h6>
                    <template v-if="accounts.instagram">
                        <div><b>{{ accounts.instagram.displayName || 'Connected' }}</b></div>
                        <div v-if="accounts.instagram.username">{{ accounts.instagram.username }}</div>
                    </template>
                    <div v-else>Not Connected</div>
                </div>

                <div class="social-container">
                    <h6>Facebook</h6>
                    <template v-if="accounts.facebook">
                        <div><b>{{ accounts.facebook.displayName || 'Connected' }}</b></div>
                        <div v-if="accounts.facebook.username">{{ accounts.facebook.username }}</div>
                    </template>
                    <div v-else>Not Connected</div>
                </div>

                <div class="social-container">
                    <h6>Twitter</h6>
                    <template v-if="accounts.twitter">
                        <div><b>{{ accounts.twitter.displayName || 'Connected' }}</b></div>
                        <div v-if="accounts.twitter.username">{{ accounts.twitter.username }}</div>
                    </template>
                    <div v-else>Not Connected</div>
                </div>
            </div>
    
            <button
                class="button-inverted"
                @click="connectSocial"
                :disabled="connectingSocial || user.role == 'freeUser'"
            >
                Manage Social Accounts
            </button>
            <p v-if="user.role == 'freeUser'">
                Social media features are only for <router-link to="/plans">Pro Users</router-link>.
            </p>
        </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import LogoutButton from '@/components/LogoutButton.vue';
import api from '@/helpers/api';
import userMixin from '@/mixins/user';

export default {
    components: { LogoutButton },

    mixins: [ userMixin ],

    data(){
        return{
            newEmail: null,
            editingEmail: false,
            sentVerification: false,

            newPassword: null,
            editingPassword: false,

            currentPassword: null,

            connectingSocial: false,
            accounts: {},
            verified: false,
        };
    },

    async mounted () {
        await this.$nextTick();        
        await this.ensureUser();

        if (this.user) {
            this.initialize();
        } else {
            this.$router.push('/');
        }
    },

    methods: {
        async initialize(){
            this.accounts = (await api.social.getAccounts()).data;
            if (this.$route.query.verifyemail) {
                this.verified = await api.users.verifyEmailConfirm(this.$route.query.verifyemail).data;
            }
        },

        async verifyEmail () {
            await api.users.verifyEmail();
            this.sentVerification = true;
            this.$toast('Verification email sent. Please check your inbox.');
        },

        async saveUser () {

            const data = {
                newsLetter: this.user.newsLetter,
                downloadToEmail: this.user.downloadToEmail,
            };

            if (this.newEmail) { data.email = this.newEmail; }
            if (this.newPassword) { data.password = this.newPassword; }

            if (this.newEmail || this.newPassword) {
                if (this.currentPassword) {
                    data.currentPassword = this.currentPassword;
                } else {
                    return this.$toast.error(
                        'Enter your current password in order to change email or password!',
                        { timeout: false },
                    );
                }
            }

            try {
                this.user = (await api.users.update(data)).data;
                this.editingEmail = false;
                this.editingPassword = false;
                this.newEmail = null;
                this.newPassword = null;
                this.currentPassword = null;
            } catch (e) {
                console.error(e);

                if (e.response.status == 403) {
                    this.$toast.error('Your current password is incorrect.');
                } else if (e.response.status == 400) {
                    this.$toast.error('That email is already in use.');
                } else {
                    this.$toast.error('Could not update profile information: ' + e);
                }
            }
        },

        async connectSocial () {
            this.connectingSocial = true;
            const url = (await api.social.link()).data;
            window.open(url);
            this.connectingSocial = false;
        },
    },

    computed: {
        lastModified () {
            return dayjs(this.user.lastModified).format('MMMM D, YYYY h:mm a');
        },

        roleName () {
            switch (this.user.role) {
                case 'proUser': return 'Pro Account';
                case 'admin': return 'Admin Account';
                default: return 'Free Account';
            }
        },
    },
};
</script>

<style lang='scss' scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

    .profileContainer{
        display:grid;
        grid-template-columns: 0.5fr 0.1fr 0.4fr;
        width:100%;
        margin-bottom:80px;
        @include max-width ($width-medium) {
            display:flex;
            flex-direction:column;
        }
        .profile-item{
            width:100%;
        }
        .success{
            color:green;
        }

        .error{
            color:red;
        }

        h5{
            margin:unset;
            margin-top:25px;
           
            @include max-width ($width-small) {
                margin-top:10px;
            }
        }

        .profile-row{
            display:flex;
            flex-direction:column;
            grid-gap:16px;
            margin-top:24px;
            margin-bottom:16px;

            @include max-width ($width-small) {
                margin:0;
            }

            input{
                height:unset;
            }

            .smallH{
                font-size:16px;
                font-weight:500;
                color:#1B1919;
            }
            .account-container{
                display:grid;
                grid-template-columns:1fr auto;
                grid-template-rows:auto auto;
                grid-column:3;

                @include max-width ($width-small) {
                    grid-template-columns:1fr;
                }
                .userInfo{
                    grid-row:2;
                    font-size: 14px;
                }

                .email-verified {
                    grid-row: 3;
                    font-size: 14px;
                }
                button{
                    display:block;
                    grid-row:1 / span 2;
                }

                input{
                    margin-top:10px;
                }

                .account-buttons{
                    display:flex;
                    grid-row:2;
                    align-items:flex-end;
                    @include max-width ($width-small) {
                        grid-row: 3;
                    }
                    button{
                        margin-left:10px;

                        @include max-width ($width-small) {
                            margin-left:0;
                            margin-top:10px;
                            margin-right:10px;
                        }
                    }
                }

                
            }
            .social-container{
                display:flex;
                flex-direction:column;
                flex-grow:1;

                @include max-width ($width-small) {
                    min-width:100%;
                    box-sizing:border-box;
                }
                button{
                    max-width:250px;
                    margin-top:8px;
                }
            }
        }
        .status-container{
            padding-top:15px;
            box-sizing:border-box;
            width:100%;

            .role{
                color:var(--link-color);
                font-weight:600;
                font-size:18px;
            }
            .pro-container{
                display:flex;
                text-align:center;
                flex-direction:column;
                border:2px solid var(--link-color);
                border-radius:2px;
                max-width:212px;
                padding:14px;
                font-size:14px;
                margin-top:15px;

                @include max-width ($width-small) {
                    min-width:100%;
                    box-sizing:border-box;
                }
                
                .try-pro{
                    font-size:18px;
                    font-weight:600;
                    color:#E46161;
                    margin-top:5px;
                }
            }
        }
        .profile-right{
            grid-column:3;
        }
        .profile-wide{
            grid-column: 1 / span 3;

            .profile-row{
                flex-direction:row;

                @include max-width ($width-small) {
                    flex-direction:column;
                }
                &.email-check{
                    @include max-width ($width-small) {
                        margin-top:10px;
                        flex-direction:row;
                    }
                }
            }
            button{
                @include max-width ($width-small) {
                    margin-top:10px;
                }
            }
        }
        .profile-last{
            margin-top:32px;

            button{
                margin-right:16px;
                @include max-width ($width-small) {
                    margin-top:0;
                }
            }
            @include max-width ($width-small) {
                margin-top:16px;
            }
        }
    }
</style>
