<template>
    <div :id="'editable-field-' + elementId" @click="$emit('editable-click', $event)" v-if="checkType" class="editable" :class="{ active }">
        <h4 class="type"><span class="index">{{ index + 1 }}</span>{{ typeName }}</h4>
        <div v-if="type == 'text' || type == 'multiline'" class="toggle-container">
            <label>Is Multiline - </label>
            <div v-on:click="handleToggle" :class="type == 'text' ? '' : 'checked'" class="toggle">
                <div :class="type == 'text' ? '' : 'checked'" class="round"></div>
            </div>
        </div>
        <label>Label:</label>
        <input :value="value" @input="$emit('input', $event.target.value)">
        <template v-if="type != 'image'">
            <label>Fill:</label>
            <color-selector 
                @set-color="$emit('set-color', {type:'fill', color:$event.color})"
                @colors="$emit('updateFill', $event)" 
                v-if="type == 'text' || type == 'color' || type =='multiline'"
                :editable="true"
                :initialColor="fillColor"
                :saving="saving"
                :options="fillOptions"
            />
            <label>Stroke:</label>
            <color-selector 
                @set-color="$emit('set-color', {type:'stroke', color:$event.color})"
                @colors="$emit('updateStroke', $event)" 
                v-if="type == 'text' || type == 'color' || type =='multiline'"
                :editable="true"
                :initialColor="strokeColor"
                :saving="saving"
                :options="strokeOptions"
            />
        </template>
        <flag-selector v-if="checkFlags" :flags="flags" @select="onSelectFlag" :editor='false' />
        <label>Opacity</label>
        <opacity-picker :initialOpacity="initialOpacity" @update-opacity="$emit('update-opacity', $event)" />

        <template v-if="type == 'text' || type =='multiline'">

            <label>Align:</label>
            <radio-picker
                :options="[ 'start', 'middle', 'end' ]"
                :selected="align"
                design="mini"
                @select="$emit('set-align', $event)"
            />
            <button @click="$emit('force-align')">Force Align</button>

            <label>Letter Spacing:</label>
            <letter-spacing-editor :elementId="elementId" />

            <label>Maximum Characters:</label>
            <input :value="limit" @input="$emit('input-limit', $event.target.value)" type="number">
        </template>

        <button class="img-button delete-button" @click="$emit('delete')"><inline-svg :src="require('@/assets/img/icon_delete_24px.svg')" width="24" /></button>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import ColorSelector from '@/components/ColorSelector.vue';
import FlagSelector from '@/components/FlagSelector.vue';
import OpacityPicker from '@/components/OpacityPicker.vue';

import LetterSpacingEditor from './LetterSpacingEditor.vue';
import RadioPicker from '@/components/RadioPicker.vue';

export default {
    props: [
        'elementId',
        'index',
        'active',
        'type',
        'value',
        'flags',
        'fillColor',
        'fillOptions',
        'strokeColor',
        'strokeOptions',
        'saving',
        'limit',
        'align',
        'initialOpacity',
    ],

    components: { ColorSelector, FlagSelector, InlineSvg, LetterSpacingEditor, OpacityPicker, RadioPicker },

    computed: {
        checkType(){
            if(this.active === this.type || window.innerWidth >= 720){
                return true;
            }
            return false;
        },
        checkFlags(){
            if(this.flags){
                if(Object.keys(this.flags).length > 0){
                    return true;
                }
            }
            return false;
        },
        typeName () {
            return {
                color: 'Color',
                image: 'Image',
                text: 'Text',
                multiline: 'Multi-Line Text',
            }[this.type];
        },
    },

    methods: {

        onSelectFlag (flag) {
            this.$set(this.flags, flag, !this.flags[flag]);
            if (this.flags[flag]) {
                this.$emit('flag-add', flag);
            } else {
                this.$emit('flag-remove', flag);
            }
        },

        handleToggle(){
            if(this.type == 'text'){
                this.$emit('switch-type', 'multiline');
            }else{
                this.$emit('switch-type', 'text');
            }   
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
.editable {
    position: relative;
    margin-top: 20px;
    padding: 15px 0;

    &::after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 -18px;
        border: 3px solid var(--link-color-active);
        border-radius:3px;
        pointer-events: none;
    }

    &.active {
        
        &::after {
            display: block;
        }

        .type{
            .index{
            background:var(--link-color-active);
            }
        }
    }

    .delete-button {
        position: absolute;
        top: 10px;
        right: 0;
    }

    label {
        display: block;
        margin: 14px 0 6px;
    }

    input, div {
        margin-bottom: 6px;
        font-weight: 500;
    }

    input, .radio-picker {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;
    }

    button {
        margin: 0 0 8px;
        display: block;
        height: auto;
        padding: 6px 16px;
        font-size: 12px;
        line-height: 14px;
    }

    .type {
        margin: 0 0 4px;
        font-size: 16px;

        .index {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 4px;
            padding: 0 8px;
            border-radius: 50%;
            background: $color-red-light;
            color: white;
            font-size: 16px;
            line-height: 26px;
            vertical-align: 1px;
            box-sizing: border-box;
        }
    }

    .radio-picker {
        border: 1px solid $color-gray;
        background: white;
    }
}
</style>

<style lang="scss">
@import '@/assets/css/colors';
.template{
    .svg-container{
        &.disabled{
            height:0;
            width:0;
        }
    }
}
.editable {
    .delete-button svg {
        
        * {
            fill: $color-gray-dark;
        }

        &:hover * {
            fill: var(--link-color-active);
        }
    }
    .toggle-container{
        display:grid;
        padding:unset;
        grid-template-columns: auto 1fr;
        align-items:center;
        .toggle{
            position:relative;
            display:flex;
            width:40px;
            height:20px;
            background:$color-gray-dark;
            border-radius:30px;
            margin:0;
            margin-left:5px;
            padding:unset;
            cursor:pointer;
            .round{
                padding:unset;
                position:absolute;
                height:15px;
                width:15px;
                margin:0;
                left:2.5px;
                top:2.5px;
                pointer-events:none;
                transition:0.25s ease-in-out;
                background:white;
                border:unset;
                border-radius:100%;
                &.checked{
                    transform:translateX(20px);
                }
            }
            &.checked{
                background: var(--link-color-active);
            }
        }
    }
    
}
</style>
