<template>
    <div class="dropload" @click="$refs.input.click()" @drop="onDrop" @dragover="onDragover">
        <label for="upload">Drop file here to upload</label>
        <input id="dropload" ref="input" type="file" :accept="accept" @change="onChange">
    </div>
</template>

<script>
export default {
    props: [ 'accept' ],
    methods: {
        onDrop (e) {
            e.preventDefault();
            const file = this.getDroppedFile(e);
            this.$emit('drop', file);
        },

        onDragover (e) {
            e.preventDefault();
        },

        getDroppedFile (e) {
            if (e.dataTransfer.items) {
                for (const item of e.dataTransfer.items) {
                    if (item.kind === 'file') {
                        return item.getAsFile();
                    }
                }
            } else {
                return e.dataTransfer.files[0];
            }
        },

        onChange (e) {
            this.$emit('drop', e.target.files[0]);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.dropload {
    position: relative;
    min-height: 160px;
    min-width: 160px;
    border: 4px solid $color-gray-dark;
    border-radius: 16px;
    border-style: dashed;
}

label {
    @include center-absolute;
}

input {
    display: none;
}
</style>
