<template>
    <div class="redirect">
        Please wait while we log you in...
    </div>
</template>

<script>
import api from '@/helpers/api';

export default {
    name: 'RedirectPage',

    props: [ 'provider' ],

    async mounted () {

        const q = window.location.search;

        switch (this.provider) {
            case 'google': await api.auth.googleCallback(q); break;
            case 'facebook': await api.auth.facebookCallback(q); break;
        }

        if (this.$cookies.isKey('auth-path-restore')) {
            const route = this.$cookies.get('auth-path-restore');

            if (route.startsWith('/auth')) {
                this.$router.push('/');
            } else {
                this.$router.push(route);
            }

            window.location.reload();
        }
    },
};
</script>

<style>

</style>
