import { awaitImageLoad } from './utils';

export default async function fitImage (blob, $image, { targetWidth, targetHeight }) {

    let src = null;

    if (!$image) {
        src = URL.createObjectURL(blob);
        $image = new Image();
        await awaitImageLoad($image, src);
    }

    return new Promise(function (resolve) {
        const covered = cover({
            targetWidth,
            targetHeight,
            childWidth: $image.naturalWidth,
            childHeight: $image.naturalHeight,
        });

        if (!covered) { return resolve(null); }

        const { width, height } = covered;
    
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage($image, 0, 0, width, height);

        if (src) {
            URL.revokeObjectURL(src);
        }

        canvas.toBlob(resolve, blob.type);
    });
}

function cover ({ targetWidth, targetHeight, childWidth, childHeight }) {
    const targetAspectRatio = targetWidth / targetHeight;
    const childAspectRatio = childWidth / childHeight;

    let width = targetWidth;
    let height = targetHeight;

    if (childAspectRatio < targetAspectRatio) {
        if (childWidth <= width) {
            // Already fits
            return;
        } else {
            // Match height
            height = width / childAspectRatio;
        }
    } else {
        if (childHeight <= height) {
            // Already fits
            return;
        } else {
            width = height * childAspectRatio;
        }
    }

    return {
        width,
        height,
    };
}
