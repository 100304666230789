<template>
    <popup title="Purchase" @close="$emit('close')" class="purchase-popup">
        <h2>Please purchase this template in order to continue</h2>
        <div class="options">
            <div>
                <h3>One Time Purchase</h3>
                <p class="price">Purchase this template only in order to download it.</p>
                <div class="price">Price: {{ formatPrice(template.product.price) }}</div>
                <button @click="$emit('purchase')">Purchase</button>
            </div>
            <div>
                <h3>Pro Plan</h3>
                <p class="price">
                    For unlimited access to all paid templates and social media features, please take a look at the Pro Plans.
                </p>
                <router-link class="button" to="/plans">See available plans</router-link>
            </div>
        </div>
    </popup>
</template>

<script>
import Popup from '@/components/Popup.vue';
import templatesMixin from '@/mixins/templates';

export default {
    props: [ 'template' ],
    mixins: [ templatesMixin ],
    components: { Popup },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils';

.options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        width: 50%;
        box-sizing: border-box;

        @include max-width ($width-small) {
            width: 100%;
        }

        &:first-of-type {
            padding-right: 24px;
        }
    }
}

.price {
    margin-bottom: 24px;
    font-size: 16px;
}
</style>
