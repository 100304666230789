let imageDrag = false;
let offset = {};
let _dragListener = null;
let _endListener = null;

import fitImage from '@/helpers/fit-image';
import { awaitImageLoad, generateId } from '@/helpers/utils';

export default {
    methods: {
        async reduceImageSize($template){
            const $svg = $template.querySelector('svg');
            const $images = $template.querySelectorAll('image');

            const { width, height } = $svg.viewBox.baseVal;

            for(const $image of $images){

                const $imageFile = new Image();
                await awaitImageLoad($imageFile, $image.getAttribute('xlink:href'));
                const srcBlob = await fetch($imageFile.src).then(res => res.blob());

                const resizedBlob = await fitImage(
                    srcBlob,
                    $imageFile,
                    { targetWidth: width, targetHeight: height },
                );

                if (resizedBlob) {
                    const matrix = new DOMMatrix(window.getComputedStyle($image).transform);
                    const width = $image.getBBox().width * matrix.a;
                    const height = $image.getBBox().height * matrix.d;
                    $image.setAttribute('width', width);
                    $image.setAttribute('height', height);
                    $image.setAttribute('transform', `translate(${matrix.m41}, ${matrix.m42})`);

                    const reader = new FileReader;
                    reader.onload = async () => {
                        $image.setAttribute('xlink:href', reader.result);
                    };
                    reader.readAsDataURL(resizedBlob);
                }
            }
        },
        async reduceSingleImage($image, value){
            const $svg = this.svgContainer[this.activeSlideIndex];
            const { width, height } = $svg.viewBox.baseVal;

            const $imageFile = new Image();
            await awaitImageLoad($imageFile, value);
            const srcBlob = await fetch($imageFile.src).then(res => res.blob());

            const resizedBlob = await fitImage(
                srcBlob,
                $imageFile,
                { targetWidth: width, targetHeight: height },
            );

            if (resizedBlob) {
                const matrix = new DOMMatrix(window.getComputedStyle($image).transform);
                const width = $image.getBBox().width * matrix.a;
                const height = $image.getBBox().height * matrix.d;
                const scale = $image.transform.baseVal[1] ? $image.transform.baseVal[1].matrix.a : 1 ;
                $image.setAttribute('width', width);
                $image.setAttribute('height', height);
                $image.setAttribute('transform', `translate(${matrix.m41}, ${matrix.m42}) scale(${scale})`);
                return new Promise((resolve, reject) => {
                    const reader = new FileReader;
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(resizedBlob);
                });
            }
        },
        async prepareImage($image, slideIndex){
            const $template = this.$refs.template ? this.$refs.template.querySelectorAll('.svg-container')[slideIndex] : this.$refs.editor.querySelectorAll('.svg-container')[slideIndex];           
            const $parent = $image.parentNode;
            const $imageFile = new Image();
            $imageFile.src = $image.getAttribute('xlink:href');

            return new Promise((resolve) => {
                $imageFile.onload = async ()=>{
                    if(!$parent.hasAttribute('clip-path') && $image.dataset.fixedImage == undefined){
                        //remake the element with clip-path
                        const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                        const gContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                        const clipPath = document.createElementNS('http://www.w3.org/2000/svg','clipPath');
                        const rect = document.createElementNS('http://www.w3.org/2000/svg','rect');
                        const rectId = generateId(10, 'SVGID_') + '_';
                        const clipId = generateId(50, 'SVGID_') + '_';
                        const matrix = $image.transform.baseVal[0].matrix;
                        const x = matrix.e;
                        const y = matrix.f;
                        const width = $image.getBBox().width * matrix.a;
                        const height = $image.getBBox().height * matrix.d;
                        //set up rect
                        rect.id = rectId;
                        rect.setAttribute('x', x);
                        rect.setAttribute('y', y);
                        rect.setAttribute('width', width);
                        rect.setAttribute('height', height);
                        //create clipPath
                        clipPath.id = clipId;
                        clipPath.appendChild(rect);
                        //link
                        g.setAttribute('clip-path', `url(#${clipId})`);
                        g.appendChild($image.cloneNode(true));
                        gContainer.appendChild(clipPath);
                        gContainer.appendChild(g);
                        $image.parentNode.replaceChild(gContainer, $image);
                        $image = $parent.querySelector(`[id='${$image.id}']`);
                        $image.dataset.fixedImage = true;
                        
                    }else{
                        const id = $parent.getAttribute('clip-path').replace('url(#', '').slice(0, -1);
                        const $clipPath = $template.querySelector(`[id=${id}]`);
                        const shape = this.findClipPath($clipPath, $template, $image);
                        shape.id = generateId(10, 'SVG_') + '_';
                        $clipPath.appendChild(shape);
                    }
                    await this.resizeImage($image.getAttribute('xlink:href'), $image, $template);
                    resolve($image);
                };
            });            
        }, 

        findClipPath($clipPath, $template, $image){
            //check if its using "use"
            let shape = '';
            if($clipPath.querySelector('use')){
                const $use = $clipPath.querySelector('use');
                const $id = $use.getAttribute('xlink:href').slice(1);
                //grab rect
                shape = $template.querySelector(`[id='${$id}']`);
                //$use no longer needed
                shape.remove();
                $use.remove();
            }else{
                shape = this.getClipShape($image, $template);
            }
            return shape;
        },
        mousePosition(e, $el){
            var CTM = $el.getScreenCTM();
            return {
                x: (e.clientX - CTM.e) / CTM.a,
                y: (e.clientY - CTM.f) / CTM.d,
            };

        },
        startDrag (e, $template, $el, editable) {
            if(e.target.id == editable.id){
                const $image = $template.querySelector(`#${$el.id}`);
                const $shape = this.getClipShape($image, $template);
                this.imgCreateDragOutline($shape, $template);
                this.imgZoomOutlineCreate($template);

                _dragListener = (e) => {
                    this.dragging(e, $el, editable);
                };
                _endListener = (e) => {
                    this.endDrag(e, $template, $el, editable);
                };
                $template.addEventListener('mousemove', _dragListener);
                document.addEventListener('mouseup', _endListener);
                imageDrag = true;
                offset = this.mousePosition(e, $el);
                offset.x -= parseFloat($el.getAttributeNS(null, 'x'));
                offset.y -= parseFloat($el.getAttributeNS(null, 'y'));
                e.preventDefault();
            }
        },
        dragging (e, $el, editable){
            if(imageDrag && editable.id == $el.id){
                e.preventDefault();
                let coords = this.mousePosition(e, $el);
                let x = coords.x - offset.x;
                let y = coords.y - offset.y;
                $el.setAttributeNS(null, 'x', x);
                $el.setAttributeNS(null, 'y', y);
                this.$set(editable, 'coords', {x: x, y: y});
                const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
                const $image = $template.querySelector(`#${editable.id}`);
                this.imgZoomOutlineUpdate($template.querySelector('#imageBox'), $image, $template);
            }
        },
        endDrag(e, $template, $el, editable){
            for (const dragbox of document.querySelectorAll('.img-dragbox')) {
                dragbox.remove();
            }

            if($template.querySelector('#imageBox')){
                $template.querySelector('#imageBox').remove();
            }
            imageDrag = false;
            if($el.id == editable.id){
                $el.dataset.ogX = parseFloat($el.getAttributeNS(null, 'x') / (editable.zoom / 100));
                $el.dataset.ogY = parseFloat($el.getAttributeNS(null, 'y') / (editable.zoom / 100));
                $el.dataset.zoomX = parseFloat($el.getAttributeNS(null, 'x'));
                $el.dataset.zoomY = parseFloat($el.getAttributeNS(null, 'y'));
                $template.removeEventListener('mousemove', _dragListener);
                document.removeEventListener('mouseup', _endListener);
                e.preventDefault();
            }
        },

        imgCreateDragOutline ($shape, $template) {
            const $svg = $template.querySelector('svg');

            const $overlay = document.createElement('div');
            $overlay.classList.add('img-dragbox');
            $overlay.style.border = '5px solid red';
            $overlay.style.position = 'absolute';
            $overlay.style.opacity = 0.5;

            let rect;

            // Scale based on svg size
            const scale = $svg.getBoundingClientRect().width / $svg.viewBox.baseVal.width;
            if ($shape.nodeName == 'circle') {
                const cx = Number($shape.getAttribute('cx'));
                const cy = Number($shape.getAttribute('cy'));
                const r = Number($shape.getAttribute('r'));

                rect = {
                    x: ((cx - r) * scale) - 2, // (center x - radius) * scale - 2 offset to make border cover edge
                    y: ((cy - r) * scale) - 2,
                    width: (r * 2 * scale) + 4, // radius * 2 * scale + 4 offset to make border cover edge
                    height: (r * 2 * scale) + 4,
                };
            
                $overlay.style.borderRadius = '50%';
            } else if ($shape.nodeName == 'rect') {
                const x = $shape.getBoundingClientRect().x;
                const y = $shape.getBoundingClientRect().y;
                const width = $shape.getBoundingClientRect().width;
                const height = $shape.getBoundingClientRect().height;
                rect = {
                    x: x - 2,
                    y: y - 2,
                    width: width + 4,
                    height: height + 4,
                };
            }
            $overlay.style.left = $shape.getBoundingClientRect().x + 'px';
            $overlay.style.top = $shape.getBoundingClientRect().y + 'px';
            $overlay.style.width = $shape.getBoundingClientRect().width + 'px';
            $overlay.style.height = $shape.getBoundingClientRect().height + 'px';

            $overlay.style.boxSizing = 'border-box';
            $template.append($overlay);
        },

        imgZoomOutlineCreate ($template) {
            const $overlay = document.createElement('div');

            $overlay.id = 'imageBox';
            $overlay.style.border = '5px solid #25a1e8';
            $overlay.style.opacity = 0.5;
            $overlay.style.position = 'absolute';
            $overlay.style.boxSizing = 'border-box';

            $template.append($overlay);

            return $overlay;
        },

        imgZoomOutlineUpdate ($overlay, $image, $template) {
            const imgBBox = $image.getBoundingClientRect();
            const templateBBox = $template.getBoundingClientRect();

            $overlay.style.width = imgBBox.width + 'px';
            $overlay.style.height = imgBBox.height + 'px';
            $overlay.style.left = imgBBox.x + 'px';
            $overlay.style.top = imgBBox.y + 'px';
        },

        getClipShape ($image, $template) {
            const $parent = $image.parentNode;
            // url(#SVGID_00000162324847891853318940000003102386888964096671_)
            // extracts just "SVGID_00000162324847891853318940000003102386888964096671_"
            const clipPathId = $parent.getAttribute('clip-path').replace('url(#', '').slice(0, -1);
            return $template.querySelector(`[id='${clipPathId}']`).children[0];
        },
        getScale($image){
            let scale = 1;
            if($image.hasAttribute('data-scale')){
                scale = $image.getAttribute('data-scale');
            }else{
                scale = $image.transform.baseVal[1] ? $image.transform.baseVal[1].matrix.a : 1;
                $image.dataset.scale = scale;
            }
            return scale;
        },

        async resizeImage($src, $image, $template){
            const $shape = this.getClipShape($image, $template);
            const shapeSize = this.determineShapeSize($shape);
            const matrix = $image.transform.baseVal[0].matrix;
            const $imageFile = new Image();
            $imageFile.src = $src;
            return new Promise((resolve) => {
                $imageFile.onload = ()=>{
                    const scaleW = shapeSize.w / $imageFile.naturalWidth;
                    const scaleH = shapeSize.h / $imageFile.naturalHeight;
                    const width = $imageFile.width * (scaleH > scaleW ? scaleH : scaleW);
                    const height = $imageFile.height * (scaleH > scaleW ? scaleH : scaleW);
                    $image.setAttribute('width', width);
                    $image.setAttribute('height', height);
                    $image.dataset.ogWidth = $imageFile.naturalWidth;
                    $image.dataset.ogHeight = $imageFile.naturalHeight;
                    let shapeX = parseFloat($shape.getAttribute('x'));
                    let shapeY = parseFloat($shape.getAttribute('y'));
                    if($shape.tagName == 'circle'){
                        shapeX = (parseFloat($shape.getAttribute('cx')) - parseFloat($shape.getAttribute('r')));
                        shapeY =  (parseFloat($shape.getAttribute('cy')) - parseFloat($shape.getAttribute('r')));
                    }
                    const scale = this.getScale($image);
                    $image.setAttribute('transform', `translate(${matrix.e}, ${matrix.f}) scale(${scale})`);
                    resolve();
                };
            });
        },

        determineShapeSize ($shape) {
            switch ($shape.nodeName) {
                case 'rect': return { w: $shape.getAttribute('width'), h: $shape.getAttribute('height') };
                case 'circle': return { w: $shape.getAttribute('r') * 2, h: $shape.getAttribute('r') * 2 };
            }
        },

        async changeImage ($el, $event) {
            //If initializing then use iterable slide index otherwise we are changing image of editable
            let slideIndex = $event.slideIndex !== undefined ? $event.slideIndex : this.activeSlideIndex;
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[slideIndex];
            let editable = this.slides[slideIndex].editable[$event.index];
            this.$set(editable, 'value', $event.value);
            $el.setAttribute('xlink:href', $event.value);
            this.$set(this.svgContainer, slideIndex, $template.querySelector('svg'));
            await this.prepareImage($el, slideIndex);
            $el = this.$refs.editor.querySelectorAll('.svg-container')[slideIndex].querySelector(`#${editable.id}`);
            if(editable.coords || $event.coords){
                let coords = editable.coords ? editable.coords : $event.coords;
                $el.dataset.ogX = coords.x;
                $el.dataset.ogY = coords.y;
                $el.setAttribute('x', coords.x);
                $el.setAttribute('y', coords.y);
            }else{
                $el.dataset.ogX = 0;
                $el.dataset.ogY = 0;
            }
            if(typeof editable.zoom === 'undefined'){
                this.$set(editable, 'zoom', 100);
            }
            this.zoomImage(editable, {zoom: editable.zoom, oldZoom: editable.zoom, index: $event.index}, slideIndex);
            this.isDirty = true;
        },

        zoomImage(e, $event, slideIndex = this.activeSlideIndex){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[slideIndex];
            const $image = $template.querySelector(`#${e.id}`);            
            const $parent = $image.parentNode;
            const id = $parent.getAttribute('clip-path').replace('url(#', '').slice(0, -1);
            const $shape = $template.querySelector(`[id=${id}]`).children[0];
            const zoom = $event.zoom / 100;  
            let scaleW = $shape.getAttribute('width') / $image.getAttribute('data-og-width');
            let scaleH = $shape.getAttribute('height') / $image.getAttribute('data-og-height');
            if($shape.nodeName == 'circle'){
                scaleW = (parseFloat($shape.getAttribute('r')) * 2) / $image.getAttribute('data-og-width');
                scaleH = (parseFloat($shape.getAttribute('r')) * 2) / $image.getAttribute('data-og-height');
            }
            let width = $image.getAttribute('data-og-width') * (scaleH > scaleW ? scaleH : scaleW);
            let height = $image.getAttribute('data-og-height') * (scaleH > scaleW ? scaleH : scaleW);
            let offsetW = width * zoom - width * ($event.oldZoom / 100);
            let offsetH = height * zoom - height * ($event.oldZoom / 100);
            $image.setAttribute('width', width * zoom);
            $image.setAttribute('height', height * zoom);
            const x = $image.getAttribute('x') ? parseFloat($image.getAttribute('x')) - 0 : 0;
            const y = $image.getAttribute('y') ? parseFloat($image.getAttribute('y')) - 0 : 0;
            $image.setAttribute('x', x - (offsetW ? offsetW / 2 : 0));
            $image.setAttribute('y', y - (offsetH ? offsetH / 2 : 0)); 

            const slide = this.slides[slideIndex];
            slide.editable[$event.index].coords = {
                x: parseFloat($image.getAttribute('x')),
                y: parseFloat($image.getAttribute('y')),
            }; 
            slide.editable[$event.index].zoom = $event.zoom;
        },
        zoomOverlayStart(e, $event){
            if($event.button == 0){
                const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
                const $image = $template.querySelector(`#${e.id}`);
                const $shape = this.getClipShape($image, $template);

                const $overlay = this.imgZoomOutlineCreate($template);
                this.imgZoomOutlineUpdate($overlay, $image, $template);

                this.imgCreateDragOutline($shape, $template);
            }
        },
        zoomOverlayMove(e, $event){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            if($template.querySelector('#imageBox')){
                const $overlay = $template.querySelector('#imageBox');
                const $image = $template.querySelector(`#${e.id}`);

                this.imgZoomOutlineUpdate($overlay, $image, $template);
            }
        },
        zoomOverlayEnd(e, $event){
            const $template = this.$refs.editor.querySelectorAll('.svg-container')[this.activeSlideIndex];
            if($template.querySelector('#imageBox')){
                $template.querySelector('#imageBox').remove();
            }

            for (const dragbox of document.querySelectorAll('.img-dragbox')) {
                dragbox.remove();
            }
        },
    },
};
