<template>
    <div class="template-catalog">
        <div :class="mobileFilters ? 'active' : ''" class="mobile-filters">
            <div @click="mobileFilters = false" class="overlay-filters"></div>
            <div class="filters">
                <div class="top-filters">
                    <h5>FILTERS</h5>
                    <img @click="mobileFilters = false" src="@/assets/img/Close.svg">
                </div>
                <div class="row-filter">
                    <button @click="categoryExpanded = !categoryExpanded" class="filter">
                        <span :class="this.categoryExpanded ? 'active' : ''">Category</span>
                        <button v-if="!this.categoryExpanded" class="img-button">
                            <img src="@/assets/img/plus.svg">
                        </button>
                        <button v-else class="img-button">
                            <inline-svg class="active" :src="require('@/assets/img/minus.svg')" />
                        </button>
                    </button>
                    <category-list 
                        :class="this.categoryExpanded ? 'active' : ''" 
                        :selected="selectedCategories" 
                        :categories="categories" 
                        @select="onSelectCategory" 
                    />
                </div>
                <div class="row-filter">
                    <button @click="dimensionExpanded = !dimensionExpanded" class="filter">
                        <span :class="this.dimensionExpanded ? 'active' : ''">Dimension</span>
                        <button v-if="!this.dimensionExpanded" class="img-button">
                            <img src="@/assets/img/plus.svg">
                        </button>
                        <button v-else class="img-button">
                            <inline-svg class="active" :src="require('@/assets/img/minus.svg')" />
                        </button>
                    </button>
                    <category-list 
                        :class="this.dimensionExpanded ? 'active' : ''" 
                        :selected="selectedDimensions" 
                        :categories="dimensions" 
                        @select="onSelectDimension" 
                    />
                </div>
                <div class="row-filter">
                    <button @click="typeExpanded = !typeExpanded" class="filter">
                        <span :class="this.typeExpanded ? 'active' : ''">Type</span>
                        <button v-if="!this.typeExpanded" class="img-button">
                            <img src="@/assets/img/plus.svg">
                        </button>
                        <button v-else class="img-button">
                            <inline-svg class="active" :src="require('@/assets/img/minus.svg')" />
                        </button>
                    </button>
                    <checklist-view :class="this.typeExpanded ? 'active' : ''">
                        <li><checkbox v-model="free" /><span>Free Download</span></li>
                    </checklist-view>
                </div>
                
                <div class="buttons-filter">
                    <app-button class="apply-filters">
                        Apply
                    </app-button>
                    <app-button class="reset-filters">
                        Reset
                    </app-button>
                </div>
            </div>
        </div>
        <div class="topContainer" ref="top">
            <div class="info">
                <div class="sort">
                    <span>Sort By: </span>
                    <div class="menu" @click="this.openSort">
                        <button id="sortBtn">{{ this.sortBy }}</button>
                        <div :class="this.sortVisible ? 'visible' : ''" class="options">
                            <button :class="this.sortBy === 'Popular' ? 'active-sort' : ''" @click="setSort('Popular')">
                                Popular
                            </button>
                            <button :class="this.sortBy === 'Newest' ? 'active-sort' : ''" @click="setSort('Newest')">
                                Newest
                            </button>
                            <button :class="this.sortBy === 'Oldest' ? 'active-sort' : ''" @click="setSort('Oldest')">
                                Oldest
                            </button>
                        </div>
                    </div>
                </div>
                <button @click="mobileFilters = true" class="filters">Filters</button>
                <div class="search">
                    <input
                        size="1"
                        name="search"
                        type="text"
                        placeholder="Search design templates, instagram posts, stores..."
                        v-model="search"
                        @keyup.enter="pushSearch"
                    />
                    <inline-svg :src="require('@/assets/img/search_24px_gray.svg')" />                   
                </div>
            </div>
        </div>
        <div class="mainContainer">
            <aside>
                <h3>Category</h3>
                <category-list :selected="selectedCategories" :categories="categories" @select="onSelectCategory" />

                <h3>Dimension</h3>
                <category-list :selected="selectedDimensions" :categories="dimensions" @select="onSelectDimension" />

                <h3>Type</h3>
                <checklist-view>
                    <li><checkbox v-model="free" /><span>Free Download</span></li>
                </checklist-view>
            </aside>
            <catalog-grid class="templates" :templates="templates" :page="currentPage" :windowWidth="windowWidth" />
        </div>
        <div class="bottomContainer">
            <div class="buttonContainer">
                <button
                    :class="currentPage === 1 ? 'disabled' : ''" 
                    @click="decrementPage" 
                    class="previous" 
                    :input="'< Previous Page'"
                >
                    Previous
                </button>
                <button 
                    :class="currentPage === pages ? 'disabled' : ''"
                    @click="incrementPage" 
                    :input="'Next Page >'"
                >
                    Next
                </button>
            </div>
            <div class="pagination-container">
                Page
                <input 
                    v-model='paginationInput' 
                    @keyup='validatePagination' 
                    ref='paginationInput' 
                    class='pagination-input' 
                    type='number'
                />
                of {{ pages === 0 ? 1 : pages }}
                <button class='pagination-go' :class='checkValidPage' @click='setPage'>Go</button>
            </div>
        </div>
    </div>
</template>

<script>
import { isEqual } from 'lodash';
import ClickOutside from 'vue-click-outside';
import InlineSvg from 'vue-inline-svg';

import AppButton from '@/components/AppButton.vue';
import CatalogGrid from '@/components/CatalogGrid.vue';
import CategoryList from '@/components/CategoryList.vue';
import Checkbox from '@/components/Checkbox.vue';
import ChecklistView from '@/components/ChecklistView.vue';
import api, { query } from '@/helpers/api';
import { arrayAsKeys, capitalizeFirst } from '@/helpers/utils';
import categoriesMixin from '@/mixins/categories';
import dimensionsMixin from '@/mixins/dimensions';

export default {
    name: 'CatalogPage',

    mixins: [ categoriesMixin(true, true, true), dimensionsMixin(true, true, true) ],

    props: [ 'type' ],

    components: {
        AppButton,
        CatalogGrid,
        CategoryList,
        Checkbox,
        ChecklistView,
        InlineSvg,
    },

    computed: {
        checkValidPage(){
            if(this.paginationInput <= this.pages && this.paginationInput >= 1){
                return;
            }
            return 'pagination-go-disabled';
        },
    },

    directives: { ClickOutside },

    data() {
        return {
            templates: [],
            free: false,
            sortBy: 'Newest',
            sortVisible: false,
            limit: 20,
            count: 0,
            currentPage: 0,
            pages: 0,
            mobileFilters: false,
            categoryExpanded: false,
            dimensionExpanded: false,
            typeExpanded: false,
            paginationInput: 1,
            search: '',
            searchActive: false,
            windowWidth: window.innerWidth,
        };
    },

    async mounted() {
        this.readQuery(this.$route.query);
        window.addEventListener('resize', this.onResize, true);
    },
    
    beforeRouteLeave(to, from, next){
        window.removeEventListener('resize', this.onResize, true);
        next();
    },
 
    methods: {
        onResize(){
            this.windowWidth = window.innerWidth;
        },

        clearSearch(){
            this.search = '';
        },
        toggleSearch(){
            this.menuActive = false;
            this.searchActive = !this.searchActive;
        },
        async pushSearch() {
            this.searchActive = false;
            this.menuActive = false;
            this.paginationInput = 1;
            const updated = Object.assign({}, this.$route.query, { q: this.search });
            this.$router.push({ path: this.$route.path, query: updated });
            this.refresh();
        },
        validatePagination(){
            if (this.paginationInput < 1) {
                this.paginationInput = 1;
            } else if (this.paginationInput > this.pages) {
                this.paginationInput = this.pages;
            }
        },

        async readQuery (query) {
            // First see if there's anything we can leech from the search term, and force an update of query
            const parsedSearch = this.parseSearch(query.q);
            if (parsedSearch) {
                return this.updateQuery(parsedSearch, query);
            }
            const page = parseInt(query.page) || 1;
            const categories = (() => {
                if (Array.isArray(query.categories)) {
                    return query.categories;
                } else if (query.categories) {
                    return [ query.categories ];
                }
            })();
            const dimensions = (() => {
                if (Array.isArray(query.dimensions)) {
                    return query.dimensions;
                } else if (query.dimensions) {
                    return [ query.dimensions ];
                }
            })();
            const free = query.free == 'true';

            this.suppressUpdate = true;

            this.currentPage = page;
            this.paginationInput = page;
            this.selectedCategories = arrayAsKeys(categories, true);
            this.selectedDimensions = arrayAsKeys(dimensions, true);
            this.free = free;
            this.sortBy = capitalizeFirst(query.sort) || this.sortBy;
            this.search = query.q;
            
            await this.$nextTick();

            this.suppressUpdate = false;

            this.refresh(query);
        },

        parseSearch (q) {
            if (!q) { return; }

            q = q.toLowerCase();

            const parsed = {};
            
            if (q.includes('customizable')) {
                q = q.replace('customizable', '');
                parsed.customizable = true;
            }

            if (q.includes('premium')) {
                q = q.replace('premium', '');
                parsed.free = 'false';
            }

            if (q.includes('free')) {
                q = q.replace('free', '');
                parsed.free = 'true';
            }

            if (q.includes('social') && !q.includes('print')) {
                q = q.replace('social', '');
                parsed.media = 'social';
            }

            if (!q.includes('social') && q.includes('print')) {
                q = q.replace('print', '');
                parsed.media = 'print';
            }

            if (Object.keys(parsed).length == 0) {
                return null;
            }

            q = q.trim();
            parsed.q = q || undefined;

            return parsed;
        },

        updateQuery (updates, current = this.$route.query) {
            if (this.suppressUpdate) { return; }
            const updated = Object.assign({}, current, updates);
            if (!isEqual(current, updated)) {
                this.$router.push({ path: this.$route.path, query: updated });
                this.refresh();
                this.refreshCategories();
                this.refreshDimensions();
            }
        },

        async findSearch (search = '') {
            search = search.toLowerCase();
            let q = {};

            q.search = search.trim();

            this.refresh(q);
        },
        decrementPage(){
            if (this.currentPage -1 >= 1) {
                this.currentPage -= 1;
                this.paginationInput = this.currentPage;
                this.refresh();
            }
        },
        incrementPage(){
            if (this.currentPage + 1 <= this.pages) {
                this.currentPage += 1;
                this.paginationInput = this.currentPage;
                this.refresh();
            }
        }, 
        setPage(){
            if (this.paginationInput <= this.pages && this.paginationInput >= 1) {
                this.currentPage = parseInt(this.paginationInput);
                this.refresh();
            }
        },
        
        async refresh (incoming = this.$route.query) {
            const q = {
                categories: Object.keys(this.selectedCategories),
                dimensions: Object.keys(this.selectedDimensions),
                sort: incoming.sort,
            };
            if (this.$tenant){ q.tenant = this.$tenant.id; }
            if (incoming.media) { q.media = incoming.media; }
            if (incoming.q) { q.search = incoming.q; }

            if (this.free) { q.premium = false; }

            let response = await api.templates.getCount(query(q));
            this.count = response.data;
            this.pages = Math.ceil(this.count / this.limit);
            if (this.currentPage > this.pages){
                this.currentPage = 1;
            }
            response = await api.templates.get(this.currentPage, this.limit, query(q));
            this.templates = response.data;
        },

        openSort() {
            this.sortVisible = !this.sortVisible;
        },

        setSort(value) {
            this.sortBy = value;
            this.currentPage = 1;
            this.refresh();
        },
    },

    watch: {
        selectedCategories () { this.updateQuery({ categories: Object.keys(this.selectedCategories) }); },
        selectedDimensions () { this.updateQuery({ dimensions: Object.keys(this.selectedDimensions) }); },
        currentPage () { this.updateQuery({ page: this.currentPage }); },
        free () { this.updateQuery({ free: this.free || undefined }); },
        sortBy () { this.updateQuery({ sort: this.sortBy.toLowerCase() }); },
        '$route.query' () {
            if(this.$route.query.q){
                this.search = this.$route.query.q;
            }else{
                this.search = '';
            }
            this.updateQuery({ q: this.search });
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/_utils.scss';
@import '@/assets/css/_colors.scss';

.template-catalog {
    position:relative;
    display: grid;
    grid-template-rows: auto 1fr auto;

    @include max-width($width-small){
        width:100%;
    }
    .mobile-filters{
        position:absolute;
        top:0;
        left:-16px;
        width:100vw;
        height:100%;
        z-index:16;
        opacity:0;
        pointer-events:none;
        transition:0.1s opacity ease-in-out;

        &.active{
            @include max-width($width-medium){
                opacity:1;
                pointer-events:unset;
            }
        }
        .overlay-filters{
            position:absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
            background:$color-black;
            opacity:0.5;
        }
        .filters{
            position:relative;
            z-index:2;
            background:$color-gray-bg;
            box-sizing:border-box;
            padding: 4px 8px 24px;
            
            .top-filters{
                display:flex;
                align-items:center;
                border-bottom:2px solid $color-gray-light;
                padding:10px 16px;
    
                h5{
                    margin:0;
                    flex-grow:1;
                }
            }

            .row-filter{
                border-bottom:2px solid $color-gray-light;

                .filter{
                    display:flex;
                    width:100%;
                    background:unset;
                    color:$color-black;
                    padding:10px 16px;
                    height:unset;

                    span{
                        text-align:left;
                        flex-grow:1;

                        &.active{
                            color:var(--link-color-active);
                        }
                    }

                    .img-button {
                        height: 17px;
                        align-self: center;
                    }

                    svg{
                        &.active{
                            stroke:var(--link-color-active);
                        }
                    }
                }

                .checklist{
                    max-height:0;
                    overflow:hidden;
                    transform-origin:top;
                    margin-bottom:10px;
                    padding:0 16px;

                    &.active{
                        max-height:100%;
                    }
                }
            }
            .buttons-filter{
                display:flex;
                margin-top:40px;
                margin-bottom:8px;
                width:100%;

                button{
                    margin:0 10px;
                    flex-grow:1;
                    font-weight:500;
                    border:2px solid;
                    border-color:var(--link-color);

                    &.reset-filters{
                        border-color:$color-gray;
                        color:$color-gray;
                        background:$color-gray-light;
                    }
                }
            }
        }
    }
    .topContainer {
        grid-row: 1;
        grid-column: 1/-1;
        text-align: left;
        margin-top: 60px;

        @include max-width($width-medium){
            width:100%;
            margin-top:32px;
        }


        .info {
            display: flex;
            flex-direction: row-reverse;
            width:100%;
            margin-bottom:15px;

            @include max-width($width-medium){
                flex-direction:column-reverse;
            }

            h3 {
                grid-column: 1 / -1;
                margin-top: 26px;
                margin-bottom: 16px;
            }

            .templateCount {
                grid-column: 1;
                justify-self: flex-start;

                @include max-width($width-medium){
                    margin-top:16px;
                }
            }

            .filters{
                display:none;

                @include max-width($width-medium){
                    display:block;
                    width:100%;
                    margin: 16px 0;
                    font-weight: 700;
                    font-size:1.20rem;
                }
            }

            .search {
                flex-grow:0.8;
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                border: 1px solid $color-black;
                box-sizing: border-box;
                border-radius: 6px;
                user-select: none;
                background:white;
                color: #c4c4c4;
                max-width:60%;
                
                @include max-width($width-large){
                    margin: 0 10px;
                }

                @include max-width($width-medium){
                    flex-grow:1;
                    max-width:unset;
                    margin-bottom:16px;
                }
                svg {
                    grid-column:1;
                    margin: 0 5px;
                    user-select: none;
                    fill: $color-black;
                }
                input {
                    grid-column:2;
                    grid-row:1;
                    border: unset;
                    border-radius: unset;
                    margin-right: 10px;
                    &:focus{
                        outline: unset;
                        
                    }
                    &:focus + svg{
                        fill: var(--link-color-active);;
                    }
                    &::placeholder {
                        color: #000000;
                    }
                }
            }

            .sort {
                flex-grow:0.1;
                display: flex;
                align-items: center;
                justify-content:flex-end;
                justify-self: flex-end;

                @include max-width($width-medium){
                    display:grid;
                    grid-template-columns: auto 1fr;
                    justify-self:flex-start;    
                    border-bottom:1px solid $color-gray-light;
                    margin-top:16px;
                    margin-bottom:10px;
                    padding-bottom:10px;
                    width:100%;
                    font-size:14px;
                }
                span {
                    margin-right: 10px;
                }
                button {
                    cursor: pointer;
                    width: 120px;
                    height: 40px;
                    grid-column: 2;
                    border: 1px solid #979797;
                    box-sizing: border-box;
                    border-radius: 6px;
                    background: #fff;
                    color:black;
                    text-align: center;

                    @include max-width($width-medium){
                        display:none;
                    }
                }
                .menu {
                    position: relative;

                    @include max-width($width-medium){
                        width:100%;
                    }

                    .options {
                        position: absolute;
                        top: 40px;
                        left: 0;
                        z-index: 2;
                        opacity:0;
                        pointer-events:none;

                        &.visible{
                            opacity:1;
                            pointer-events:unset;
                        }

                        @include max-width($width-medium){
                            display:flex;
                            width:100%;
                            flex-grow:1;
                            position:unset;
                            opacity:1;
                            pointer-events:unset;
                        }
                        
                        button {
                            border-radius: unset;
                            border: unset;
                            background: #f5f5f5;
                            &:hover {
                                color: var(--link-color-active);
                            }

                            @include max-width($width-medium){
                                display:flex;
                                justify-content:center;
                                width:unset;
                                margin: 0 5px;
                                height:unset;
                                font-size:14px;
                                background:white;
                                padding:0;
                                min-width:unset;
                                flex-grow:1;

                                &.active-sort{
                                    color:var(--link-color-active);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .mainContainer {
        grid-row: 2;
        display: grid;
        width: 100%;
        grid-template-columns: auto 1fr;
        padding-right: 5px;
        box-sizing: border-box;
        margin-top: 32px;

        @include max-width($width-medium){
            grid-template-columns: 1fr;
            padding:0;
        }

        aside{
            overflow: unset;
            padding-right: 20px;

            @include max-width($width-medium){
                display:none;
            }
        }
        
        .templates {
            margin-left: 15px;
            box-sizing: border-box;
            width: 100% !important;
            @include max-width($width-medium){
                margin:0;
            }
        }
    }
    .bottomContainer {
        display: grid;
        grid-row: 3;
        grid-template-columns: 1fr auto 1fr;
        margin-top: 50px;
        margin-bottom: 80px;

        @include max-width($width-medium){
            grid-template-columns:1fr;
            margin-top:32px;
            margin-bottom:32px;
        }

        .buttonContainer{
            grid-column:2;
        
            button {
                font-size:14px;
                font-weight:500;
                color:#fff;
                border: 1px solid $color-gray;
                width: 175px;
                &.previous {
                    margin-right: 10px;
                    justify-self: flex-end;
                }
                &:hover {
                    background: var(--link-color-active);
                    color: #fff;
                }
                &.disabled{
                    background:#fff;
                    color:$color-black;
                    pointer-events: none;
                }
                @include max-width($width-medium){
                    width:50%;
                }
            }

            @include max-width($width-medium){
                display:flex;
                grid-column:1;
            }
        }
        .pagination-container{
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content: center;
            font-weight:500;
            
            @include max-width($width-medium){
                grid-row:2;
                grid-column: 1 / span 2;
                margin-top:24px;
            }
            .pagination-go{
                font-size:16px;
                padding:0 5px;
                box-sizing: border-box;
                margin:0;
                width:35px;
                height:25px;
                margin:0 5px;

                &.pagination-go-disabled{
                    background:$color-gray;
                    color:black;
                    cursor:not-allowed;
                }
            }
            .pagination-input{
                text-align:center;
                position:relative;
                min-width:unset;
                height:25px;
                width:45px;
                min-width:unset;
                padding:5px;
                box-sizing: border-box;
                margin:0 5px;
                color:$color-black;
                border:$color-black 1px solid;
                background:$color-gray-light;
                font-weight:500;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                &[type=number] {
                -moz-appearance: textfield;
                }
                &.disabled, span{
                    pointer-events:none;
                }

                .pagination-modal{
                    display:none;
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    min-height:100%;
                    border:1px solid black;
                    box-sizing:border-box;

                    .pagination-number{
                        height:100%;
                        width:100%;
                        background:white;
                        border-top:1px solid black;
                        border-bottom:1px solid black;
                        box-sizing: border-box;
                        display:flex;
                        flex-direction: column;
                        align-items:center;
                        justify-content: center;
                        color:$color-black;
                        &.active{
                            pointer-events:none;
                            color:var(--link-color-active);
                        }
                    }

                    &.active{
                        display:block;
                    }
                }
            }
        }
        
        
    }
}
</style>
