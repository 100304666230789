<template>
    <div class="testimony-container">
        <h1>What our clients have to say</h1> 
        <div class="testimony-tile-container">
            <div v-for="(testimony, index) of testimonies"  :key="index" class="testimony-tile">
                <div>
                    <inline-svg :src="require('@/assets/img/quote.svg')" />
                    <p>{{ testimony.review }}</p>
                </div>
                <h2>{{ testimony.name }}</h2>
                <p>{{ testimony.title }}</p>
            </div>
        </div>
        <button>Get started for free!</button>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    components:{
        InlineSvg,
    },
    data(){
        return{
            testimonies:[
                {
                    name:'Layla Ward',
                    title:'Marketing Manager',
                    review:`We’ve just upgraded our account and the template range is AWESOME! ... 
                        is a brilliant marketing platform and has quickly become an essential tool in our business. 
                        There’s a bunch of stunning professional templates which we can quickly customize to our brand in a few minutes. 
                        Finally… professional marketing made easy!`,
                },
                {
                    name:'Mike Cranstoun',
                    title:'Realtor',
                    review:`I signed up to ... a couple of months ago and got busy building my database and getting my offer out to sellers in my area. \
                            So far I have distributed about 1000 ... flyers and received 5 calls from interested sellers. 
                            4 of these sellers have listed with me and so far 2 have sold making me about $20,000 in gross commission.`,
                },
                {
                    name:'Alan Preece',
                    title:'Real Estate Sales Manager',
                    review:`... now plays a central role in all my marketing. 
                        The templates are awesome and we can edit, download and print them faster than ever before. 
                        Defenitly the future of real estate marketing and defenitely the most essential marketing tool in my business.`,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';
@import '@/assets/css/utils';

.testimony-container{
    padding-top:40px;
    text-align:center;
    h1{
        text-transform:uppercase;
        text-align:Center;
        font-weight:600;
    }
    button{
        margin-bottom:40px;
    }
    .testimony-tile-container{
        display:Flex;
        justify-content:center;
        align-items:center;
        margin-top:40px;
        margin-bottom:40px;
        text-align:left;
        
        @include max-width ($width-medium) {
            flex-direction:column;
        }
        svg{
            display:inline;
            margin-right:10px;
        }
        .testimony-tile {
            display:flex;
            flex-direction:column;
            max-width:300px;
            min-height:250px;
            margin:0 10px; 
            background:$color-gray-light;
            border-left:5px solid var(--link-color-active);
            padding:10px;
            color:$color-gray-dark;

            @include max-width ($width-medium) {
                margin-top:40px;
                max-width:60%;
            }
            div{
                flex-grow:1;
            }
            h2{
                font-weight:500;
                color:var(--link-color-active);
                margin:5px 0;

                @include max-width ($width-medium) {
                    margin-top:20px;
                }
            }
            p{
                display:inline;
                margin:0;
            }
        }
    }
}
</style>
