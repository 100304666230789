import { debounce } from 'lodash';

export default class TextBoundary {

    constructor ($el, editable, $svg, $linkedEl) {
        this.$el = $el;
        this.editable = editable;
        this.$svg = $svg;
        this.$container = document.getElementsByClassName('template-create-page')[0];
        this.$linkedEl = $linkedEl;

        this.readDom();

        // Fall back to defaults if needed
        const bb = $el.getBBox();
        this.x = this.x || bb.x;
        this.y = this.y || bb.y;
        this.width = this.width || bb.width;
        this.height = this.height || bb.height;

        // Get the x/y offset, since it is not going to line up with boundary and will mess up writing back to DOM
        this.xOffset = this.determineXOffset();
        let currentY = 0;
        if(this.$el.getAttribute('y')){
            currentY = this.$el.getAttribute('y');
        }else{
            currentY = this.$el.parentNode.getAttribute('y');
        }
        this.yOffset = currentY - this.y;
        this.originalYOffset = this.yOffset;

        // Get font size from first child (multiline) or from element itself (singleline)
        this.fontSize = this.determineFontSize();
        this.originalFontSize = this.fontSize;

        // Determine line height if needed (may rely on font-size)
        this.lineHeight = this.lineHeight || this.determineLineHeight();
        this.originalLineHeight = this.lineHeight;
        
        this.updateDom();

        this.dragStart = this.dragStart.bind(this);
        this.dragMove = this.dragMove.bind(this);
        this.dragEnd = this.dragEnd.bind(this);

        this.updateDom = debounce(this.updateDom.bind(this), 100, { leading: true, trailing: true });
        this.dragMove = debounce(this.dragMove, 1000 / 60, { leading: true, trailing: true });
        this.setText = debounce(this.setText.bind(this), 1000 / 30, { leading: true, trailing: true });

        this.$svg.addEventListener('mousemove', this.dragMove);
        document.addEventListener('mouseup', this.dragEnd);

        this.handleViewChange = this.handleViewChange.bind(this);
    }

    determineXOffset () {
        const x = Number(this.$el.getAttribute('x') || 0);

        if (this.$el.getAttribute('text-anchor') == 'middle') {
            return x - (this.width / 2) - this.x;
        } else if (this.$el.getAttribute('text-anchor') == 'start') {
            return x - this.width - this.x;
        } else {
            return x - this.x;
        }
    }

    determineFontSize () {
        const fontSizeParent = this.$el.getAttribute('font-size');
        const fontSizeChild = this.$el.children[0] && this.$el.children[0].getAttribute('font-size');

        return Number((fontSizeParent || fontSizeChild || '').replace('px', ''));
    }

    readDom () {
        this.x = Number(this.$el.getAttribute('data-boundary-x')) || this.x;
        this.y = Number(this.$el.getAttribute('data-boundary-y')) || this.y;
        this.width = Number(this.$el.getAttribute('data-boundary-width')) || this.width;
        this.height = Number(this.$el.getAttribute('data-boundary-height')) || this.height;
        this.lineHeight = Number(this.$el.getAttribute('data-boundary-line-height')) || this.lineHeight;
        this.matrix = this.$el.transform && this.$el.transform.baseVal[0] && this.$el.transform.baseVal[0].matrix;
    }

    updateDom () {
        if (this.$el.getAttribute('text-anchor') == 'middle') {
            this.$el.setAttribute('x', this.x + (this.width / 2) + this.xOffset);
        } else if (this.$el.getAttribute('text-anchor') == 'end') {
            this.$el.setAttribute('x', this.x + this.width + this.xOffset);
        } else {
            this.$el.setAttribute('x', this.x + this.xOffset);
        }

        this.updateDomY();

        this.$el.setAttribute('data-boundary-x', this.x);
        this.$el.setAttribute('data-boundary-y', this.y);
        this.$el.setAttribute('data-boundary-width', this.width);
        this.$el.setAttribute('data-boundary-height', this.height);
        this.lineHeight && this.$el.setAttribute('data-boundary-line-height', this.lineHeight);
    }

    updateDomY (linked = false) {
        if(linked){
            this.$linkedEl.setAttribute('y', this.y + this.yOffset);
        }else{
            this.$el.setAttribute('y', this.y + this.yOffset);
        }
    }

    determineLineHeight () {
        if (this.editable.type == 'multiline') {
            let child1 = null;
            let child2 = null;

            for (let i = 0; i < this.$el.children.length; i++) {
                const child = this.$el.children[i];

                if (child.childNodes.length) {
                    if (!child1) {
                        child1 = child;
                    } else if (!child2 && child1.getAttribute('dy') !== child.getAttribute('dy')) {
                        child2 = child;
                        break;
                    }
                }
            }

            if (child2) {
                if (child2.hasAttribute('dy')) {
                    return Number(child2.getAttribute('dy'));
                } else {
                    const y1 = Number(child1.getAttribute('y'));
                    const y2 = Number(child2.getAttribute('y'));

                    return y2 - y1;
                }
            } else {
                return this.fontSize * 1.2;
            }
        }
    }

    handleViewChange(){
        this.updateBox();
        
        [ 'tl', 't', 'tr', 'r', 'br', 'b', 'bl', 'l' ].forEach(side => {
            this.updateHandle(side);
        });
    }

    addBox () {
        this.$box = document.createElement('div');

        this.$box.style.position = 'absolute';
        this.$box.style.outline = '4px solid gray';
        this.$box.style.pointerEvents = 'none';
        this.$box.style.overflow = 'hidden';

        this.updateBox();
        this.$container.append(this.$box);

        const editor = document.querySelector('.template-editor');

        window.addEventListener('resize', this.handleViewChange);
        editor.addEventListener('scroll', this.handleViewChange);

        this.$handles = {};

        [ 'tl', 't', 'tr', 'r', 'br', 'b', 'bl', 'l' ].forEach(side => {
            const $handle = this.$handles[side] = this.createHandle();
            $handle.setAttribute('data-handle', side);
            $handle.addEventListener('mousedown', this.dragStart);
            this.updateHandle(side);
            this.$box.after($handle);
        });
    }

    createHandle () {
        const $handle = document.createElement('div');

        $handle.style.position = 'absolute';
        $handle.style.background = 'white';
        $handle.style.border = '1px solid #888';
        $handle.style.width = '6px';
        $handle.style.height = '6px';
        $handle.style.transform = 'translate(-50%, -50%)';
        $handle.style.overflow = 'hidden';

        return $handle;
    }

    updateHandles (sides) {
        sides.forEach(side => this.updateHandle(side));
    }

    updateHandle (side) {
        const $handle = this.$handles[side];
        let x, y;

        const offset = 2;

        switch (side) {
            case 'tl':
            case 'l':
            case 'bl':
                x = this.screen.x - offset;
                break;
            case 't':
            case 'b':
                x = this.screen.x + this.screen.width / 2;
                break;
            case 'tr':
            case 'r':
            case 'br':
                x = this.screen.x + this.screen.width + offset;
                break;
        }

        switch (side) {
            case 'tl':
            case 't':
            case 'tr':
                y = this.screen.y - offset;
                break;
            case 'l':
            case 'r':
                y = this.screen.y + this.screen.height / 2;
                break;
            case 'bl':
            case 'b':
            case 'br':
                y = this.screen.y + this.screen.height + offset;
                break;
        }

        $handle.style.left = x + 'px';
        $handle.style.top = y + 'px';
    }

    dragStart (e) {
        e.preventDefault();
        const $handle = e.target;
        this.dragging = $handle.getAttribute('data-handle');
    }

    convertRect (x, y, width, height, to) {
        const p1 = this.convertPoint(x, y, to);
        const p2 = this.convertPoint(x + width, y + height, to);

        return {
            x: p1.x,
            y: p1.y,
            width: p2.x - p1.x,
            height: p2.y - p1.y,
        };
    }

    convertPoint (x, y, to = 'screen') {
        const pt = this.$svg.createSVGPoint();
        pt.x = x;
        pt.y = y;

        if (this.matrix && to == 'screen') {
            pt.x *= this.matrix.a;
            pt.y *= this.matrix.d;
        }

        const m = to == 'screen' ? this.$svg.getScreenCTM() : this.$svg.getScreenCTM().inverse();

        const tpt = pt.matrixTransform(m);

        if (this.matrix && to == 'svg') {
            tpt.x /= this.matrix.a;
            tpt.y /= this.matrix.d;
        }

        return tpt;
    }

    dragMove (e) {
        const side = this.dragging;
        if (!side) { return; }

        const { pageX, pageY } = e;
        const { x, y } = this.convertPoint(pageX, pageY, 'svg');
        const handlesToUpdate = [];

        switch (side) {
            case 'tl':
            case 'l':
            case 'bl':
                this.width = this.width + (this.x - x);
                this.x = x;
                handlesToUpdate.push('tl', 'l', 'bl', 't', 'b');
                break;
            case 'tr':
            case 'r':
            case 'br':
                this.width = x - this.x;
                handlesToUpdate.push('tr', 'r', 'br', 't', 'b');
                break;
        }

        switch (side) {
            case 'tl':
            case 't':
            case 'tr':
                this.height = this.height + (this.y - y);
                this.y = y;
                handlesToUpdate.push('tl', 't', 'tr', 'l', 'r');
                break;
            case 'bl':
            case 'b':
            case 'br':
                this.height = y - this.y;
                handlesToUpdate.push('bl', 'b', 'br', 'l', 'r');
                break;
        }

        this.updateBox();
        this.updateHandles(handlesToUpdate);

        this.updateDom();
    }

    dragEnd () {
        this.dragging = null;
    }

    updateBox () {
        this.screen = this.convertRect(this.x, this.y, this.width, this.height, 'screen');
        this.$box.style.left = this.screen.x + 'px';
        this.$box.style.top = this.screen.y + 'px';
        this.$box.style.width = this.screen.width + 'px';
        this.$box.style.height = this.screen.height + 'px';
    }

    removeBox () {
        this.$box.remove();
        const editor = document.querySelector('.template-editor');

        window.removeEventListener('resize', this.handleViewChange);
        editor.removeEventListener('scroll', this.handleViewChange);
        
        for (const side in this.$handles) {
            this.$handles[side].remove();
        }
    }

    /** This will force a setText using the existing text content, in order to force a re-align of the text. */
    forceAlign () {
        if (this.editable.type == 'multiline') {
            const lines = [];

            for (const $child of this.$el.children) {
                lines.push($child.textContent);
            }

            this.setText(lines.join('\n'));
        } else {
            this.setText(this.$el.textContent);
        }
    }

    setText (text) {
        const newAnchor = this.$el.getAttribute('data-text-anchor');
        if (newAnchor) {
            this.$el.setAttribute('text-anchor', newAnchor);
            this.$el.removeAttribute('data-text-anchor');
            this.updateDom();
        }

        this.setTextImpl(text);
    }

    setTextImpl (text, fontSize, lineHeight, yOffset) {
        this.fontSize = fontSize || this.originalFontSize;
        this.lineHeight = lineHeight || this.originalLineHeight;
        this.yOffset = yOffset || this.originalYOffset;
        if(this.$linkedEl && !fontSize && !lineHeight && !yOffset){
            this.$linkedEl.setAttribute('font-size', this.fontSize + 'px');
            this.updateDomY(true);
        }
        this.$el.setAttribute('font-size', this.fontSize + 'px');
        this.updateDomY();

        // The !fontSize means that this is the first iteration
        // Single line text only requires updating the content once since it doesn't need to calc lines
        if (!fontSize && this.editable.type == 'text') {
            this.$el.innerHTML = text;
            if(this.$linkedEl){
                this.$linkedEl.innerHTML = text;
            }
        } else if (this.editable.type == 'multiline') {
            this.setMultiline(text);
        }

        // Single line = check width, multiline = check height
        const { width, height } = this.$el.getBBox();
        const tooBig = this.editable.type == 'text' ? width > this.width : height > this.height;
        if (tooBig) {
            this.setTextImpl(text, this.fontSize * 0.9, this.lineHeight * 0.9, this.yOffset * 0.9);
            if(this.$linkedEl){
                this.$linkedEl.setAttribute('font-size', this.fontSize + 'px');
                this.updateDomY(true);
            }
        }
    }

    setMultiline (text) {
        const lines = text.split('\n');
        this.multilineDy = 0;

        // This helps us recycle $tspans, for faster performance
        let index = 0;

        for (const line of lines) {

            let $tspan = this.multilineAddTspan(index);
            index += 1;

            if (line.trim().length == 0) {
                // Empty tspans are completely ignored by browsers, so we add dummy content and hide it
                $tspan.textContent = '.';
                $tspan.setAttribute('visibility', 'hidden');
            } else {
                $tspan.textContent = '';

                const words = line.split(' ');
                for (const word of words) {
                    $tspan.textContent += ' ' + word;
    
                    const width = $tspan.getBBox().width;
                    
                    if (width > this.width) {
                        // Undo adding the word
                        $tspan.textContent = $tspan.textContent.slice(0, -word.length);

                        // Give that line a final trim
                        $tspan.textContent = $tspan.textContent.trim();
    
                        // Create a new $tspan
                        $tspan = this.multilineAddTspan(index);
                        index += 1;

                        $tspan.textContent = word;
                    }
                }

                // Make sure to trim extra space
                $tspan.textContent = $tspan.textContent.trim();
            }
        }

        // Nuke any old elements that were not recycled
        while (this.$el.children.length > index) {
            this.$el.removeChild(this.$el.lastChild);
        }
    }

    multilineAddTspan (index) {
        let $tspan = this.$el.children[index];

        if ($tspan) {
            // Reset things that might have come through and we don't want
            $tspan.removeAttribute('visibility');
            $tspan.removeAttribute('font-size');
        } else {
            $tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
            this.$el.appendChild($tspan);
        }

        $tspan.setAttribute('text-anchor', this.$el.getAttribute('text-anchor'));
        $tspan.setAttribute('x', this.$el.getAttribute('x'));

        $tspan.setAttribute('dy', this.multilineDy);

        // Subsequent lines will have dy set to line height, reset it here
        if (this.multilineDy !== this.lineHeight) {
            this.multilineDy = this.lineHeight;
        }

        return $tspan;
    }
}
