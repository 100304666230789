<template>
    <div class="flag-selector">
        <button v-for="(value, flag) in flags" :key="flag" :class="{ disabled: !value }" @click="$emit('select', flag)">
            {{ name(flag) }}
        </button>
    </div>
</template>

<script>
import { toNameCase } from '@/helpers/utils';

export default {
    name: 'FlagSelector',
    props: [ 'flags', 'editor' ],
    methods: {
        name (flag) {
            if(this.editor){
                switch(flag){
                    case 'user-hideable': return 'Hide';
                    default: return toNameCase(flag);
                }
            }
            switch (flag) {
                case 'user-hideable': return 'User Hideable';
                case 'fix-centering': return 'Fix Centering';
                case 'fit-text': return 'Fit Text';
                default: return toNameCase(flag);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors';

.flag-selector {
    display: flex;
    flex-wrap:Wrap;
    border: 1px solid var(--link-color-active);
    border-radius: 6px;
    padding: 6px;
    background: white;
    box-sizing: border-box;
}

button {
    min-width: 48px;
    height: 24px;
    margin: 0 4px 0 0;
    padding: 5px 8px;
    margin-top:4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    border:1px solid $color-gray-dark;
    cursor:pointer;
    transition:0.10s ease-in-out;
    &.disabled{
        cursor:pointer;
    }
    &:hover{
        border-color:$color-gray-dark;
        background:var(--link-color-active);
        color:$color-gray-light;
    }
}
</style>
