<template>
    <popup class="create-category-popup" title="Create New Category" @close="$emit('close')">
        <!-- <p>Choose a parent category (optional)</p>
        <checklist-view>
            <li v-for="category in categories" :key="category.id">
                <checkbox :value="category == activeParent" @input="onSelect(category)" />
                <span>{{ category.name }}</span>
            </li>
        </checklist-view> -->
        <editor-text :title="null" :text="categoryName" @text-input="categoryName = arguments[0]" />

        <label>Icon:</label>
        <dropdown :current="categoryIcon">
            <li v-for="icon in availableIcons" :key="icon" @click="categoryIcon = icon">
                <category-icon :icon="icon" :size="36" /> {{ icon }}
            </li>
        </dropdown>
        
        <button @click="save">Save</button>
        <button @click="$emit('close')">Cancel</button>
    </popup>
</template>

<script>
import CategoryIcon from '@/components/CategoryIcon.vue';
import Dropdown from '@/components/Dropdown.vue';
import EditorText from '@/components/EditorText.vue';
import Popup from '@/components/Popup.vue';
import api from '@/helpers/api';

export default {
    components: { CategoryIcon, Dropdown, EditorText, Popup },

    data () { return {
        categoryName: 'Name of New Category',
        categoryIcon: 'postcard',

        categories: [],
        activeParent: null,

        availableIcons: CategoryIcon.available,
    }; },

    mounted () {
        this.refresh();
    },

    methods: {

        async refresh () {
            const response = await api.categories.get({ tenant: this.$tenant.id });
            this.categories = response.data;
        },

        async save () {
            const category = (await api.categories.create({
                name: this.categoryName,
                tenant: this.$tenant?.id,
                icon: this.categoryIcon,
                global: !this.activeParent,
            })).data;

            if (this.activeParent) {
                await api.categories.addSubcategory(category._id, this.activeParent._id);
            }

            this.$emit('close');
        },

        onSelect (item) {
            if (this.activeParent == item) {
                this.activeParent = null;
            } else {
                this.activeParent = item;
            }
        },

    },
};
</script>

<style lang="scss">
.create-category-popup {
    input {
        margin: 0 0 16px;
    }

    button {
        margin: 16px 4px 0 0;
    }

    li img {
        margin-right: 12px;
    }
}
</style>
