
module.exports = {
    alairhomes: {
        id: 'alairhomes',
        stylesheet: () => import('./assets/css/alairhomes.scss'),
        logo: require('@/assets/img/ALogo.png'),
        home: () => import('@/views/auth/AuthPage.vue'),
        homeName: 'Login',
        showPricing: false,
    },
};
