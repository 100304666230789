import api from '@/helpers/api';

export default {

    methods: {
        async refreshUser () {
            this.$global.userPromise = api.users.get('me');
            this.$global.user = (await this.$global.userPromise).data;
        },
        async ensureUser () {
            try {
                await (this.userPromise || this.refreshUser());
            } catch (e) {}
        },
    },

    computed: {

        user () {
            return this.$global.user;
        },

        userPromise () {
            return this.$global.userPromise;
        },

        authenticated () {
            return this.user != null;
        },

        userRole () {
            return this.user && this.user.role;
        },
    },
};
