<template>
    <div class="authContainer" :class="$route.name == 'Home' && $tenant && $tenant.homeName == 'Login' ? 'homeLogin' : ''">
        <div @click="$emit('login-closed')" class="overlay"></div>
        <div class="formContainer">
            <img
                class="close"
                @click="$emit('login-closed')"
                src="@/assets/img/Close.svg"
            />
            <LoginPage
                class="auth-page"
                :class="this.current === 'login' ? 'active' : ''"
                @open-register="this.openRegister"
                @open-forgot="this.openForgot"
            />
            <RegisterPage
                class="auth-page"
                :class="this.current === 'register' ? 'active' : ''"
                @open-login="this.openLogin"
                @login-closed="$emit('login-closed')"
            />
            <ForgotPage
                class="auth-page"
                :class="this.current === 'forgot' ? 'active' : ''"
                @open-login="this.openLogin"
            />
        </div>
    </div>
</template>

<script>
import LoginPage from './login/LoginPage.vue';
import ForgotPage from './password-reset/ForgotPage.vue';
import RegisterPage from './register/RegisterPage.vue';
import userMixin from '@/mixins/user';

export default {
    name: 'AuthContainer',
    data() {
        return {
            current: 'login',
        };
    },
    mixins: [ userMixin ],
    components: {
        LoginPage,
        RegisterPage,
        ForgotPage,
    },
    async mounted() {
        await this.ensureUser();

        if(this.user){
            if (this.$tenant?.homeName == 'Login'){
                this.$router.push('/template/catalog');
            }
        }
    },
    methods: {
        openForgot() {
            this.current = 'forgot';
        },
        openRegister() {
            this.current = 'register';
        },
        openLogin() {
            this.current = 'login';
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/colors';

.auth-page {

    p {
        margin: 0 0 36px;
        font-size: 16px;
    }

    label {
        display: flex;
        align-items: center;
        margin: 20px 0 0;
        font-size: 16px;

        img {
            width: 20px;
            height: 20px;
            margin: 0 8px 2px 0;
        }

        .error {
            font-size: 12px;
            color: $color-red-light;
        }
    }

    .input-row {
        display: flex;
        align-items: center;

        .reveal {
            margin-left: -32px;
        }
    }

    input {
        display: block;
        width: 100%;
        margin: 8px 0 12px;
    }

    button {
        margin: 24px 0 0;
        width: 100%;
    }
}
</style>

<style lang="scss" scoped>
.authContainer {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: 'hidden';
    top: 0;
    left: 0;
    z-index: 20;
    min-height:650px;

    .formContainer {
        position: relative;
        z-index: 10;
        width: 100%;
        max-width: 600px;
        background: #fff;

        .close {
            position: absolute;
            top: 25px;
            right: 25px;
            cursor: pointer;
            z-index: 11;
        }
        .auth-page {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            width: 80%;
            margin: 20% 10%;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
        }
        .active {
            opacity: 1;
            pointer-events: unset;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.45);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    &.homeLogin{
        position:unset;
        justify-content: center;

        .formContainer{
            background:unset;

            .close{
                display:none;
            }

            .auth-page{
                position:absolute;
                margin:unset;
                margin-top:40px;
            }
        }

        .overlay{
            display:none;
        }
    }
}
</style>
