<template>
    <button @click="logout">
        <inline-svg v-if="!disableSVG" :src="require('@/assets/img/Logout.svg')" class="mobile-icon"/>
        <span>Log Out</span>
    </button>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:[ 'disableSVG' ],
    components:{
        InlineSvg,
    },
    name: 'LogoutButton',
    methods:{
        logout(){
            this.$router.push('/logout');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/colors';
@import '@/assets/css/utils';
svg{
    display:none;

    @include max-width($width-medium){
        display:block;
    }
}
</style>
