import { render, staticRenderFns } from "./CatalogTile.vue?vue&type=template&id=128259a6&scoped=true&"
import script from "./CatalogTile.vue?vue&type=script&lang=js&"
export * from "./CatalogTile.vue?vue&type=script&lang=js&"
import style0 from "./CatalogTile.vue?vue&type=style&index=0&id=128259a6&lang=scss&scoped=true&"
import style1 from "./CatalogTile.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128259a6",
  null
  
)

export default component.exports